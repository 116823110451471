// Icons imports
import AtractiveIcon from '../assets/imgs/pins/pin_atrativos.png';
import FoodIcon from '../assets/imgs/pins/pin_restaurantes.png';
import LodgingIcon from '../assets/imgs/pins/pin_hospedagens.png';
import ShopIcon from '../assets/imgs/pins/pin_compras.png';
import ServiceIcon from '../assets/imgs/pins/pin_servicos.png';
import InfraIcon from '../assets/imgs/pins/pin_infra.png';

// Config imports
import API from '../config/API';
import firebase from '../config/Firebase';
import { getStatsAttractions } from '../apis/attractions.endpoint';

const agency = ['Agência de turismo receptivo', 'Agência de viagens e turismo', 'Guia de bike', 'Guia de turismo'];

const guia = ['Guia de turismo'];

const art = ['Tricô e crochê', 'Feira de artesanato', 'Centro de artesanato', 'Ateliê', 'Artesãos', 'Loja de artesanato'];

const initialStats = {
  Attraction: {
    value: [],
    name: 'Atrativos',
  }, 
  Food: {
    value: [],
    name: 'Meios de Alimentação',
  },
  Lodging: {
    value: [],
    name: 'Meios de Hospedagens',
  },
  Services: {
    value: [],
    name: 'Serviços',
  },
  Commerce: {
    value: [],
    name: 'Comércios',
  },
  Infrastructure: {
    value: [],
    name: 'Infraestrutura',
  },
};

function orderArray(array) {
  let newArray = {};
  const invalid = [];

  for (let cont = 0; cont < Object.keys(array).length; cont++) {
    let max = { name: '', n: 0 };
    Object.keys(array).map((field) => {
      max = array[field] > max.n && !invalid.includes(field) ? { name: field, n: array[field] } : max;
    });
    newArray = { ...newArray, [max.name]: max.n };
    invalid.push(max.name);
  }

  return newArray;
}

// AgencyInfo
export async function handleStatisticsAttractions(neighborhood, city) {
  let docs = [];

  if (neighborhood.length > 0) {
    await Promise.all(neighborhood.map(async (cityName) => {  
      const docCity = await firebase.firestore().collection('Attractions')
        .where('City', '==', cityName)
        .get();
      
      if (docCity.docs.length > 0)
        docs = docs.concat(docCity.docs);
    }));
  } else {
    const docCity = await firebase.firestore().collection('Attractions')
      .where('City', '==', city)
      .get();

    docs = docCity.docs;
  }

  let vet = initialStats;

  let totalArt = 0;
  let totalAgency = 0;
  let totalGuia = 0;
  let qtdFix = 0;
  let qtdTemp = 0;
  let qtdAtr = 0;

  let array = {};

  docs.map((doc) => {
    const data = doc.data();

    if (!data.Active) return;
    
    // AgencyInfo
    const especialidade = data.Especialidade;
    if (guia.includes(especialidade)) 
      totalGuia++;
    else if (art.includes(especialidade))
      totalArt++;
    else if (agency.includes(especialidade))
      totalAgency++;

    // EmployeesInfo
    const funcFix = data['Número de Funcionários Fixos'];
    const funcTemp = data['Número de Funcionários Temporários'];

    if (funcFix)
      qtdFix += Number(funcFix);
    if (funcTemp) {
      qtdTemp += Number(funcTemp);
    }

    // CardDashboard
    const type = data.Type;
    if (vet[type]) {
      vet = { 
        ...vet, 
        [type]: { 
          ...vet[type],
          value: [...vet[type].value, data],
        },
      };
    }

    // CardTableDashboard
    const segs = data['Segmento Turístico'];
    
    if (segs) {
      segs.map((field) => {
        if (array[field])
          array = { ...array, [field]: array[field] + 1 };
        else
          array = { ...array, [field]: 1 };
      });
    }

    array = orderArray(array);
    
    if (type === 'Attraction') {
      qtdAtr++;
    }
  });

  return { totalAgency: totalGuia, totalArt: totalArt, stats: vet, qtdFix: qtdFix, qtdTemp: qtdTemp, segments: array, qtdAgency: totalAgency, qtdAtr: qtdAtr };
}

export async function handleStatisticsEvents(neighborhood, city) {
  const currDate = new Date();
  let eventsDoc = [];

  if (neighborhood.length > 0) {
    await Promise.all(neighborhood.map(async (cityName) => {  
      const docCity = await firebase.firestore().collection('Events').where('City', '==', cityName)
        .get();

      if (docCity.docs.length > 0)
        eventsDoc = eventsDoc.concat(docCity.docs);
    }));
  } else {
    const doc = await firebase.firestore().collection('Events')
      .where('City', '==', city)
      .get();

    eventsDoc = doc.docs;
  }

  let eventsLastYear = 0;
  let eventsThisYear = 0;
  let nextEvents = 0;
  let totalEvents = 0;

  eventsDoc.map((doc) => {
    const textDate = doc.data().StartDate;
    if (textDate) {
      const docDate = new Date(textDate.seconds * 1000);
      eventsLastYear += docDate.getFullYear() === (currDate.getFullYear() - 1) ? 1 : 0;
      eventsThisYear += docDate.getFullYear() === currDate.getFullYear() && docDate < currDate ? 1 : 0;
      nextEvents += docDate.getFullYear() === currDate.getFullYear() && docDate > currDate ? 1 : 0;
      totalEvents += docDate.getFullYear() === currDate.getFullYear() ? 1 : 0;
    }
  });

  return { eventsLastYear: eventsLastYear, eventsThisYear: eventsThisYear, nextEvents: nextEvents, totalEvents: totalEvents };
}

export async function handleGetLocationCity(city) {
  const doc = await firebase.firestore().collection('Cities').where('name', '==', city).get();

  const locationDoc = doc.docs[0].data().location;

  if (locationDoc)
    return { location: [locationDoc.latitude, locationDoc.longitude] };
  
  return { location: [0, 0] };
}

export async function handleStatisticsInventory(city, date) { 
  const cont = { totalLastMonth: 0, totalCurrMonth: 0 };

  const infos = {
    Attraction: { icon: AtractiveIcon, desc: 'Atrativo' },
    Food: { icon: FoodIcon, desc: 'Meios de Alimentação' },
    Lodging: { icon: LodgingIcon, desc: 'Meios de Hospedagens' },
    Commerce: { icon: ShopIcon, desc: 'Comércio' },
    Services: { icon: ServiceIcon, desc: 'Serviços' },
    Infrastructure: { icon: InfraIcon, desc: 'Infraestrutura' },
  };

  try {

    const res = await getStatsAttractions(city, date.initialDate, date.finalDate);
    const data = res.data.inventoryStats;

    Object.keys(infos).map((field) => {
      infos[field] = { ...infos[field], ...data[field] };
      cont.totalLastMonth += infos[field].lastMonth;
      cont.totalCurrMonth += infos[field].currentMonth;
    });

    return { total: cont, placesInfo: infos };
  } catch (error) {
    return { total: 0, placesInfo: 0, date: date };
  }
}

export async function handleStatisticsWords(neighborhood, city) {
  if (!neighborhood) return;

  let docs = [];

  if (neighborhood.length > 0) {
    await Promise.all(neighborhood.map(async (cityName) => {  
      const docCity = await firebase.firestore().collection('LogBuscaPalavras').where('cidade', '==', cityName)
        .get();
      
      if (docCity.docs.length > 0)
        docs = docs.concat(docCity.docs);
    }));
  } else {
    const docCity = await firebase.firestore().collection('LogBuscaPalavras').where('cidade', '==', city)
      .get();

    docs = docCity.docs;
  }
  
  let array = {};

  if (docs) {
    docs.map((doc) => {
      const word = doc.data().palavras;

      if (word) {
        if (array[word])
          array = { ...array, [word]: array[word] + 1 };
        else
          array = { ...array, [word]: 1 };
      }
    });

    array = orderArray(array);
  }

  return { segments: array };
}

export async function handleStatisticsUsers(neighborhood, city, date) {
  const array = [];

  let userGeolocation = [];

  if (neighborhood.length > 0) {
    await Promise.all(neighborhood.map(async (cityName) => {  
      const docCity = await firebase.firestore().collection('UserGeolocation').where('Cidade', '==', cityName)
        .orderBy('data')
        .get();
      
      if (docCity.docs.length > 0)
        userGeolocation = userGeolocation.concat(docCity.docs);
    }));
  } else {
    const docCity = await firebase.firestore().collection('UserGeolocation').where('Cidade', '==', city)
      .orderBy('data')
      .get();

    userGeolocation = docCity.docs;
  }

  const init = new Date(date.initialDate);
  const final = new Date(date.finalDate);

  const users = [];

  for (let index = userGeolocation.length - 1; index >= 0; index--) {
    const data = userGeolocation[index].data();
    const local = data.localizacao;

    if (local && local.latitude !== 0 && local.longitude !== 0) {
      const aux = new Date(data.data.seconds * 1000);
      
      if (aux < init)
        break;

      if (aux <= final) {
        users.push(data.deviceId);
        array.push({
          lat: Number(local.latitude.toFixed(4)),
          lng: Number(local.longitude.toFixed(4)),
        });
      }
    }
  }

  const jsonObjectArray = array.map(JSON.stringify);
  const arraySet = new Set(jsonObjectArray);
  const filteredArray = Array.from(arraySet).map(JSON.parse);
 
  const setUsers = new Set(users);
  const qtdInit = [...setUsers];

  return { attractionsCord: filteredArray, qtdUsers: qtdInit.length, qtdInit: array.length };
}