import React, { useEffect, useState } from 'react';

// CSS import
import '../../assets/css/atractionList.css';

// Components imports
import { limitStr } from '../../utils/limitStr';

// Firebase import
import firebase from '../../config/Firebase';

export default function EventsList({ doc, handleEdit, handleDelete }) {
  const [local, setLocal] = useState();

  const handleLocal = async () => {
    const attractionInfo = await firebase.firestore().collection('Attractions').doc(doc.data().AttractionId).get();
    setLocal(attractionInfo.data() ? attractionInfo.data().Title : 'Não há');
  };

  const getDate = (currentDate) => {
    const date = new Date(currentDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  useEffect(() => {
    handleLocal();
  }, []);

  if (!local) {
    return <></>;
  }

  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().Title}</td>
      <td className="desc_table_inventory">{limitStr(doc.data().Description)}</td>
      <td className="column_min_table_inventory">{local}</td>
      <td className="column_min_table_inventory">{doc.data().City}</td>
      <td className="column_min_table_inventory">{getDate(doc.data().StartDate.seconds * 1000)}</td>
      <td className="column_min_table_inventory">{getDate(doc.data().EndDate.seconds * 1000)}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Exluir</button>
          <a href={'/map/Events/' + doc.id} target="_blank"><button className='btn_mod_adm'>Ver evento</button></a>
        </div>
      </td>
    </tr>
  );
}