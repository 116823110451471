import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/inputFilters.css';
import { Filter4 } from '@material-ui/icons';
export default function SelectMultiplePrefilter ({ title, field, value, setValue, explanation, filterProp, filters }) {
  const [attractions, setAttractions] = useState([]);
  const [selectedAttractions, setSelectedAttractions] = useState([]);
  // const [tipos, setTipos] = useState([]);
  const [filtersCity, setFiltersCity] = useState();
  const [filtersProp, setFiltersProp] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [filtersSpecialty, setFiltersSpecialty] = useState('');
  const [cities, setCities] = useState([]);
  const [prop, setProp] = useState([]);
  const [attractionsLoaded, setAttractionsLoaded] = useState(false); 

  // console.log(selectedAttractions);
  // console.log(value);

  
  useEffect(() => {
    const db = firebase.firestore();
    
    db.collection('Roteiros')
      .doc(value.idRota)
      .get()
      .then((roteiroDoc) => {
        if (roteiroDoc.exists) {
          // Get the "Cities" array from the "Roteiros" document
          const citiesArray = roteiroDoc.data().Cities || [];
          
          // Create an array to store promises for fetching attractions
          const attractionPromises = [];
  
          // Fetch attractions for each city separately
          citiesArray.forEach((city) => {
            const attractionPromise = db.collection('Attractions')
              .where('Title', '>', '')
              .where('Subtype', 'in', ['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo'])
              .where('City', '==', city)
              .get()
              .then((snapshot) => {
                return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
              });
  
            attractionPromises.push(attractionPromise);
          });
  
          // Wait for all attraction promises to resolve
          Promise.all(attractionPromises)
            .then((results) => {
              // Merge the results into a single array
              const attractionsData = [].concat(...results);
              setAttractions(attractionsData);
              setAttractionsLoaded(true);
            })
            .catch((error) => console.log(error));
  
          setCities([...new Set(citiesArray)]);
          setProp(['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo']);
        } else {
          console.log("Roteiros document not found.");
        }
      })
      .catch((error) => console.log(error));

      db.collection('Attractions')
      .get()
      .then((snapshot) => {
        const specialtiesSet = new Set();
    
        snapshot.docs.forEach((doc) => {
          const specialty = doc.data().Especialidade;
    
          if (specialty) {
            specialtiesSet.add(specialty);
          }
        });
    
        const data = Array.from(specialtiesSet).map((specialty, index) => ({ id: index, name: specialty }));
        setSpecialties(data);
      })
      .catch((error) => console.log(error));
      //setSelectedAttractions(value[field])
  }, []);
  

  useEffect(() =>{
    if(attractionsLoaded){    setCities([...new Set(attractions.map((attraction) => attraction.City))]);
        setProp(['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo']);
        if (attractionsLoaded && attractions.length>0 && value[field] && value[field].length > 0) {
          setSelectedAttractions([... new Set(attractions.filter((item) => value[field].includes(item.id)))])
        }}
  },[attractions, attractionsLoaded])

  const handleAttractionChange = (attraction) => {
    const isSelected = selectedAttractions.map((item) => item.id).includes(attraction.id);
    if (isSelected) {
      setSelectedAttractions(selectedAttractions.filter((id) => id.id !== attraction.id));
    } else {
      setSelectedAttractions([...selectedAttractions, attraction]);
    }
    //setValue({ ...value, [field]: [...new Set(selectedAttractions)] });
  };

  useEffect(() =>{

    setValue({ ...value, [field]: [...new Set(selectedAttractions.map((attraction) => attraction.id))] });
  },[selectedAttractions])

  const filteredPontosApoio = attractions.filter((ponto) => {
    if (selectedAttractions.map((item) => item.id).includes(ponto.id)){
        return true;
    }
    if (filtersCity && ponto.City !== filtersCity) {
      return false;
    }
    if (filtersProp && ponto.Subtype !== filtersProp) {
      return false;
    }
    if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty) {
      return false;
    }
    return true;
  });

  if (!attractions) {
    return <Loading />;
  }


  return (
    <div className='inputFilters'>
      <div className='div_label_input_textarea'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input_do_nelli'>
        <div className="filter-dropdown">
            <select
            value={filtersCity}
            onChange={(e) => {
                const city = e.target.value;
                setFiltersCity(city);
            }}
            >
            <option value="">Todas cidades</option>
            {cities.map((city) => (
                <option key={city} value={city}>
                {city}
                </option>
            ))}
            </select>
            <select
            value={filtersProp}
            onChange={(e) => {
                const prop = e.target.value;
                setFiltersProp(prop);
            }}
            >
            <option value="">Todos subtipos</option>
            {prop.map((prop) => (
                <option key={prop} value={prop}>
                {prop}
                </option>
            ))}
            </select>
            <select
              value={filtersSpecialty}
              onChange={(e) => {
                const specialty = e.target.value;
                setFiltersSpecialty(specialty);
              }}
            >
              <option value="">Todas especialidades</option>
              {specialties.map((specialty) => (
                <option key={specialty.id} value={specialty.name}>
                  {specialty.name}
                </option>
              ))}
            </select>
        </div>
        <div className='div_align_multiple_select_do_nelli'>
          <div className='select_inventory_do_nelli'>
              {filteredPontosApoio.map((attraction) => (
              <div key={attraction.id}>
                  <label>
                  <input
                      type="checkbox"
                      checked={selectedAttractions.map((item) => item.id).includes(attraction.id)}
                      onChange={() => handleAttractionChange(attraction)}
                  />
                  {attraction.Title}
                  </label>
              </div>
              ))}
          </div>
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );}
