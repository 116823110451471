import React, { useEffect, useState } from 'react';

// CSS import
import '../../assets/css/InputComponents/selectMultipleRender.css';

// Components imports
import firebase from '../../config/Firebase';
import Loading from '../Loading';

// Assets imports
import ArrowFoward from '../../assets/imgs/left-arrow-white.png';
import ArrowBack from '../../assets/imgs/right-arrow-white.png';

export default function SelectMultipleRender({ title, field, value, setValue, explanation }) {
  const [valuesSelect, setValuesSelect] = useState();
  const [citySelected, setCitySelected] = useState();

  async function handleCities() {
    const docs = await firebase.firestore().collection('Cities').orderBy('name').get();

    setValuesSelect(docs.docs);
  }
  
  async function handleAttractions() {
    if (value['Cities'].length > 0) {
      const docs = await firebase.firestore()
        .collection('Attractions')
        .orderBy('Title')
        .where('City', 'in', value['Cities'])
        .get();
      setValuesSelect(docs.docs);
    } else {
      setValuesSelect([]);
    }
  }
  
  function handleAddCities(type) {
    if (!citySelected) return;
    if (type === 'add' && !value[field].includes(citySelected)) {
      setValue({ ...value, [field]: [...value[field], citySelected] });
      setCitySelected();
    } else if (type === 'remove' && value[field].includes(citySelected)) {
      const neigh = value[field].filter((value) => value !== citySelected);
      setValue({ ...value, [field]: neigh });
      setCitySelected();
    }
  }

  useEffect(() => {
    if (field === 'Cities') 
      handleCities();
  }, []);

  useEffect(() => {
    if (field === 'pontosRota')
      handleAttractions();
  }, [value['Cities']]);

  if (!valuesSelect) {
    return <Loading />;
  }

  return (
    <div className='SelectMultipleRender'>
      <div className='div_label_input_textarea'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input'>
        <div className='div_align_multiple_select'>
          <div className='div_selects_neigh_align'>
            <select className='select_inventory' id='Cities' onChange={(e) => setCitySelected(e.target.value)} multiple>
              {
                valuesSelect.map((city, index) => {
                  if (!value[field].includes(city.data().name) && field === 'Cities')
                    return <option key={'SelectMultiple' + field + index}>{city.data().name}</option>;
                  if (!value[field].includes(city.data().Title) && field === 'pontosRota') 
                    return <option key={'SelectMultiple' + field + index}>{city.data().Title}</option>;
                })
              }
            </select>
            <div className='div_btns_set_neighborhood'>
              <button type='button' className='btn_remove_city btn_handle_neighborhood' onClick={() => handleAddCities('add')}>
                <img src={ArrowFoward} alt='logo de uma seta para a direita' className='img_arrow_region'/>
              </button>
              <button type='button' className='btn_add_city btn_handle_neighborhood' onClick={() => handleAddCities('remove')} >
                <img src={ArrowBack} alt='logo de uma seta para a esquerda' className='img_arrow_region'/>
              </button>
            </div>
            <select className='select_inventory' onChange={(e) => setCitySelected(e.target.value)} multiple>
              {
                value[field].map((city, index) => {
                  return <option key={'CityOption' + field + index}>{city}</option>;
                })
              }
            </select>
          </div>
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );
}