export const valuesSegments = [
  {
    id: 'Aventura',
    title: 'Aventura',
  },
  {
    id: 'Compras',
    title: 'Compras',
  },
  {
    id: 'Cultural',
    title: 'Cultural',
  },
  {
    id: 'Ecoturismo',
    title: 'Ecoturismo',
  },
  {
    id: 'Entretenimento e lazer',
    title: 'Entretenimento e lazer',
  },
  {
    id: 'Esportes',
    title: 'Esportes',
  },
  {
    id: 'Estudos e intercâmbio',
    title: 'Estudos e intercâmbio',
  },
  {
    id: 'Gastronômico',
    title: 'Gastronômico',
  },
  {
    id: 'Náutico',
    title: 'Náutico',
  },
  {
    id: 'Negócios e eventos',
    title: 'Negócios e eventos',
  },
  {
    id: 'Pesca',
    title: 'Pesca',
  },
  {
    id: 'Religioso',
    title: 'Religioso',
  },
  {
    id: 'Rural',
    title: 'Rural',
  },
  {
    id: 'Saúde',
    title: 'Saúde',
  },
  {
    id: 'Social',
    title: 'Social',
  },
  {
    id: 'Sol e praia',
    title: 'Sol e praia',
  },
];

export const valuesPeopleType = [
  {
    id: 'todosOsPublicos',
    title: 'Todos os públicos',
  },
  {
    id: 'bomParaCrianca',
    title: 'Bom pra Criança',
  },
  {
    id: 'terceiraIdade',
    title: 'Terceira Idade',
  },
  {
    id: 'romantico',
    title: 'Romântico',
  },
  {
    id: 'lgbt',
    title: 'LGBT+',
  },
  {
    id: 'soCasal',
    title: 'Só Casal',
  },
  {
    id: 'familiar',
    title: 'Familiar',
  },
  {
    id: 'publicoJovem',
    title: 'Público Jovem',
  },
  {
    id: 'motociclista',
    title: 'Motociclista',
  },
  {
    id: 'ciclista',
    title: 'Ciclista',
  },
  {
    id: 'peregrino',
    title: 'Peregrino',
  },
  {
    id: 'mochileiro',
    title: 'Mochileiro',
  },
  {
    id: 'esportista',
    title: 'Esportista',
  },
];

export const valuesTypeLocal = [
  {
    id: 'lgbt',
    title: 'LGBT+',
  },
  {
    id: 'tombadoCondephaat',
    title: 'Tombado Condephaat',
  },
  {
    id: 'tombadoIPHAN',
    title: 'Tombado IPHAN',
  },
  {
    id: 'tombamentoMunicipal',
    title: 'Tombamento Municipal',
  },  
  {
    id: 'centroHistoricoTombado',
    title: 'Centro Histórico Tombado',
  },
];

export const valuesTypeLocalEvent = [
  {
    id: 'lgbt',
    title: 'LGBT+',
  },
  {
    id: 'Imperdivel',
    title: 'Imperdível',
  },
  /* {
    id: 'impulsionar',
    title: 'Impulsionar',
  }, */
];

export const valuesClassEvent = [
  {
    id: 'aventura',
    title: 'Aventura',
  },
  {
    id: 'gastronomico',
    title: 'Gastronômico',
  },
  {
    id: 'religioso',
    title: 'Religioso',
  },
  {
    id: 'cultural',
    title: 'Cultural',
  },
  {
    id: 'esportivo',
    title: 'Esportivo',
  },
  {
    id: 'cicloturismo',
    title: 'Cicloturismo',
  },
  {
    id: 'autoesporte',
    title: 'Autoesporte',
  },
  {
    id: 'motociclistico',
    title: 'Motociclístico',
  },
  {
    id: 'vinho',
    title: 'Vinho',
  },
  {
    id: 'cervejeiro',
    title: 'Cervejeiro',
  },
  {
    id: 'rodeio',
    title: 'Rodeio',
  },
  {
    id: 'lgbt',
    title: 'LGBT+',
  },
  {
    id: 'musical',
    title: 'Musical',
  },
  {
    id: 'tecnico',
    title: 'Técnico',
  },
];

export const valuesTypeEvent = [
  {
    id: 'assistencial',
    title: 'Assistêncial',
  },
  {
    id: 'civico',
    title: 'Cívico',
  },
  {
    id: 'cultural',
    title: 'Cultural',
  },
  {
    id: 'comercial',
    title: 'Comercial',
  },
  {
    id: 'empresarial',
    title: 'Empresarial',
  },
  {
    id: 'esportivo',
    title: 'Esportivo',
  },
  {
    id: 'folclorico',
    title: 'Folclórico',
  },
  {
    id: 'gastronomico',
    title: 'Gastronômico',
  },
  {
    id: 'religioso',
    title: 'Religioso',
  },
  {
    id: 'social',
    title: 'Social',
  },
  {
    id: 'rodeio',
    title: 'Rodeio',
  },
  {
    id: 'lgbt',
    title: 'LGBT+',
  },
  {
    id: 'musical',
    title: 'Musical',
  },
  {
    id: 'tecnico',
    title: 'Técnico',
  },
];

export const valuesCityType = [
  {
    id: 'possuiMonumentosGeologico',
    title: 'Possui Monumentos Geologico',
  },
  {
    id: 'temCircuitoQuilombola',
    title: 'Tem Circuíto Quilombola',
  },
  {
    id: 'possuiTurismologo',
    title: 'Possui Turismólogo',
  },
];

export const valueContractCity = [
  {
    id: 'Contratada',
    title: 'Contratada',
  },
];

export const valuesStampCity = [
  {
    id: 'Qualificado Verde Azul',
    title: 'Qualificado',
  },
  {
    id: 'Certificado Verde Azul',
    title: 'Certificado',
  },
];

export const valuesCityRules = [
  {
    id: 'composiçãoDefinida',
    title: 'COMTUR -  Lei COMTUR com composição definida na Lei 1261/15',
  },
  {
    id: 'sociedadeCivil',
    title: 'COMTUR - Lei com um 2/3 da sociedade civil',
  },
  {
    id: 'listaPresenca',
    title: 'COMTUR - Lista de presença',
  },
  {
    id: 'registroCartorio',
    title: 'COMTUR - Atas com Registro Cartório',
  },
];

export const imperdivelValue = [
  {
    id: 'Imperdivel',
    title: 'Imperdível',
  },
];

export const attractValue = [
  {
    id: 'classificadoAtrativo',
    title: 'Atrativo',
  },
];

export const languageValues = [
  {
    id: 'inglês',
    title: 'Inglês',
  },
  {
    id: 'coreano',
    title: 'Coreano',
  },
  {
    id: 'mandarim',
    title: 'Mandarim',
  },
  {
    id: 'espanhol',
    title: 'Espanhol',
  },
  {
    id: 'italiano',
    title: 'Italiano',
  },
  {
    id: 'alemão',
    title: 'Alemão',
  },
  {
    id: 'francês',
    title: 'Francês',
  },
  {
    id: 'japonês',
    title: 'Japonês',
  },
  {
    id: 'outro',
    title: 'Outro',
  },
];

export const paymentMethodsValues = [
  {
    id: 'pix',
    title: 'Pix',
  },
  {
    id: 'dinheiro',
    title: 'Dinheiro',
  },
  {
    id: 'crédito',
    title: 'Crédito',
  },
  {
    id: 'débito',
    title: 'Débito',
  },
  {
    id: 'vale refeição',
    title: 'Vale refeição',
  },
  {
    id: 'boleto',
    title: 'Boleto',
  },
  {
    id: 'outro',
    title: 'Outro',
  },
];

export const impulseAdmin = [
  {
    id: 'Impulsionar',
    title: 'Impulsionar',
  },
];

const date = new Date().getFullYear();

export const demandYearApply = [
  {
    id: (date).toString(),
    title: date,
  },
  {
    id: (date - 1).toString(),
    title: date - 1,
  },
  {
    id: (date - 2).toString(),
    title: date - 2,
  },
];