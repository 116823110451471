import React, { useState, useEffect } from 'react';

// CSS Import
import '../../assets/css/InputComponents/radioInput.css';

import CalculatorIcon from '../../assets/imgs/pins/ranking_icon.png';

// Components improts
import Loading from '../Loading';

export default function RadioInput({ title, field, value, setValue, rankingPoints }) {
  const [valueRadio, setValueRadio] = useState();

  function handleSimpleRadio() {
    setValueRadio({
      Sim: 'sim',
      Não: 'não',
    });
  }

  function handleTypesLocal() {
    setValueRadio({
      Qualificado: 'Qualificado',
      Certificado: 'Certificado',
    });
  }

  function handleTimeProject() {
    setValueRadio({
      'Curto - até 3 anos': 'Curto',
      'Médio - até 6 anos': 'Médio',
      'Longo - até 10 anos': 'Longo',
    });
  }

  function handleEventType() {
    setValueRadio({
      'Público (Gratuito)': 'Público',
      'Particular (Pago)': 'Particular',
    });
  }

  function handleBoolType() {
    setValueRadio({
      Sim: true,
      Não: false,
    });
  }

  useEffect(() => {
    switch (field) {
      case 'possuiSeloVerdeAzul':
        handleTypesLocal();
        break;
      case 'prazos':
        handleTimeProject();
        break;
      case 'tipoEvento':
        handleEventType();
        break;
      case 'formDemandValid':
      case 'appliedHundredFormDemand':
        handleBoolType();
        break;
      default:
        handleSimpleRadio();
    }
  }, []);

  if (!valueRadio)
    return <Loading />;

  return (
    <div className="RadioInput">    
      <div className='div_label_input_radio'>
        {
          rankingPoints ? (
            <div className="div_align_img_ranking_points">
              <span className="radio_ranking_type">Este campo contribui para a Calculadora de Ranqueamento.</span>
              <img src={CalculatorIcon} alt="Ícone com um C, que representa que o campo contabiliza para a contagem na calculadora" />
            </div>
          ) : null
        }
        <label className="label_radio_input">
          <strong>{title}</strong>
        </label>  
      </div>
      <div className="div_out_input_radio">
        {
          Object.keys(valueRadio).map((key, index) => {
            return (
              <div className='div_align_radio_input' key={'Radio' + field + index}>
                <label className='label_radiomark_input'>
                  <input type='radio' name={field} id={field + key} checked={value[field] === valueRadio[key]} onChange={() => setValue({ ...value, [field]: valueRadio[key] })} className='checkbox_pendency' />
                  <span className='radiomark_radio'></span>
                </label>
                <label htmlFor={field + key} className='label_text_radiomark'>{key}</label>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}