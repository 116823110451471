import React, { useState, useEffect } from 'react';
import firebase from '../config/Firebase';
import '../assets/css/reviewPendency.css';

import { handleSetDoc } from '../utils/pendencyManager';
import EditEvents from './EditComponents/EditEvents';
import EditInventory from './EditComponents/EditInventory';
import EditCities from './EditComponents/EditCities';
import EditRegions from './EditComponents/EditRegions';
import FieldReview from './FieldReview';
import EditRoutes from './EditRoutes';
import EditItinerary from './EditItinerary';
import ButtonForm from './FormComponents/ButtonForm';
import SelectReview from './FormComponents/SelectReview';
import TextareaReview from './FormComponents/TextareaReview';
import TitleReviewPendency from './FormComponents/TitleReviewPendency';

export default function ReviewPendecy({ pendency, setEditPopup, setNumPendency, city, role }) {
  const [avaliation, setAvaliation] = useState(true);
  const [pendencySelected, setPendencySelected] = useState();
  const [editPendencyPopup, setEditPendencyPopup] = useState(false);
  const [message, setMessage] = useState('');
  const [objectPend, setObjectPend] = useState(pendency.data());
  const [lastAttractionText, setLastAttractionText] = useState('');
  const [currAttractionText, setCurrAttractionText] = useState('');
  const [keysCurr, setKeysCurr] = useState(handleOrderKeys('curr'));
  const [keysLast, setKeysLast] = useState(handleOrderKeys('last'));
  const handleInfoText = {
    AttractionId: handleAttractionIdText,
    Active: handleBooleanText, 
    Visible: handleBooleanText, 
    createTime: handleDateText,
    createAt: handleDateText,
    StartDate: handleDateText,
    EndDate: handleDateText,
    DataExpiracaoContrato: handleDateText,
    location: handleGeolocationText,
    access: handleGeolocationText, 
    'Activities List': handeMapString,
    'Segmento Turístico': handeMapString,
    imagefile: handeMapString,
    KMLFile: handleImageText,
    defaultEventImage: handleImageText,
    defaultImage: handleImageText,
    ImagemPinCidade: handleImageText,
    ImagemPinRegiao: handleImageText,
    Tripadvisor_TipoPublico: handleObjectText,
    Tripadvisor_NotaPorTipo: handleObjectText,
    Tripadvisor_AvaliacoesPorNota: handleObjectText,
    Tripadvisor_Award: handleObjectText,
  };
  const editPendencies = {
    Events: <EditEvents setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role={role} />,
    Attractions: <EditInventory setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role={role} />,
    Cities: objectPend.currDoc.regiao ? <EditRegions setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role={role} /> : <EditCities setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role={role} />,
    Roteiros: <EditRoutes setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role ={role}/>,
    RoteirosTrechos: <EditItinerary setEditPopup={setEditPendencyPopup} action={objectPend.action} userCity={city} pendency={pendency} objectPend={objectPend} setObjectPend={setObjectPend} role ={role}/>,
  };

  function handleOrderKeys(type) {
    if (objIsEmpty(objectPend.currDoc))
      return Object.keys(objectPend.lastDoc).sort();
    
    const otherKeys = type === 'curr' ? Object.keys(objectPend.currDoc).sort() : keysCurr;
    const keys = Object.keys(objectPend.lastDoc).sort();
    const newKeys = type === 'curr' ? Object.keys(objectPend.currDoc).sort() : Object.keys(objectPend.lastDoc).sort();

    let index = 0;

    if (type === 'curr') {
      while (index < keys.length) {
        if (!otherKeys.includes(keys[index]))
          newKeys.splice(index, 0, keys[index]);
        index++;
      }
    } else {
      while (index < otherKeys.length) {
        if (!keys.includes(otherKeys[index]))
          newKeys.splice(index, 0, 'new');
        index++;
      }
    }

    return newKeys;
  }

  async function searchAttractionDoc(id) {
    const doc = await firebase.firestore().collection('Attractions').doc(id).get();

    return doc;
  }

  function handleAttractionIdText(id, type) {
    const attracDoc = searchAttractionDoc(id);

    attracDoc.then((val) => {
      if (val.data()) {
        if (type)
          setLastAttractionText(val.data().Title);
        else
          setCurrAttractionText(val.data().Title);
      } else if (type) {
        setLastAttractionText('Não há local');
      } else {
        setCurrAttractionText('Não há local');
      }
    });

    return type ? lastAttractionText : currAttractionText;
  }

  function handleBooleanText(bool) {
    return bool ? 'Sim' : 'Não';
  }

  function handleDateText(date) {
    if (!date) return;

    const value = new Date(date.seconds * 1000);
    return `${value.getDate()}/ ${value.getMonth() + 1}/${value.getFullYear()}`;
  } 

  function handleGeolocationText(geolocation) {
    if (!geolocation) return;

    return `${geolocation.latitude},${geolocation.longitude}`;
  }

  function handeMapString(mapString) {
    if (!mapString) return;

    let text = '';
    mapString.map((str) => {
      text += str + ', ';
    });
    return text;
  }

  function handleImageText(text) {
    if (text.length > 60) {
      return text.slice(0, 60) + '...';
    }

    return text;
  }

  function objIsEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function editTextStyle(field) {
    const currDoc = objectPend.currDoc;
    const lastDoc = objectPend.lastDoc;
    const keysLastDoc = Object.keys(objectPend.lastDoc);

    if (objectPend.action !== 'Editar') return {};

    // New content
    if (!keysLastDoc.includes(field))
      return { backgroundColor: '#2ECC71' };
    
    // Delete content
    if (!currDoc[field] && lastDoc[field])
      return { backgroundColor: '#EC7063' };

    // Edit content
    if (currDoc[field] instanceof Object) {
      if (currDoc[field].length !== lastDoc[field].length)
        return { backgroundColor: '#F1C40F' };

      for (let i = 0; i < lastDoc[field].length; i++) {
        if (!currDoc[field].includes(lastDoc[field][i]))
          return { backgroundColor: '#F1C40F' };
      }

      if (field === 'location') {
        if (lastDoc[field].latitude !== currDoc[field].latitude || lastDoc[field].longitude !== currDoc[field].longitude)
          return { backgroundColor: '#F1C40F' };
      }
    } else if (currDoc[field] !== lastDoc[field])
      return { backgroundColor: '#F1C40F' };

    return {};
  }

  function handleEditPendency() {
    if (objectPend.action === 'Excluir') return;

    setEditPendencyPopup(true);
  }

  async function handleGetPendency() {
    const docs = await firebase.firestore().collection('Pendencies').doc(pendency.id).get();

    setPendencySelected(docs);
    setObjectPend(docs.data());
  }

  function handleSetPendency() {
    handleSetDoc(pendencySelected, message, avaliation, setNumPendency, setEditPopup, '', false);
  }

  function handleObjectText(obj) {
    if (obj === null) return '';

    let text = '';

    Object.keys(obj).map((key) => {
      text += `${key}: ${obj[key]}; `;
    });

    return text;
  }

  useEffect(() => {
    if (!editPendencyPopup) {
      handleGetPendency();
      setKeysCurr(handleOrderKeys('curr'));
      setKeysLast(handleOrderKeys('last'));
    }

    setObjectPend({ ...objectPend, deleteFiles: [] });

    window.scrollTo(0, 0);
  }, [editPendencyPopup]);

  return (
    <div>
      {
        editPendencyPopup ? editPendencies[objectPend.type] : (
          <>
            <TitleReviewPendency title={'Pendência'} action={objectPend.action} type={objectPend.type} requestor={objectPend.requestor} />
            <div className='div_docs_container'> 
              {
                !objIsEmpty(objectPend.lastDoc) ? (
                  <div className='div_info_doc'>
                    <div className='div_title_doc'>
                      Documento Antigo
                    </div>
                    { keysLast.map((field, index) => {
                      const lastDoc = objectPend.lastDoc;
                      if (field !== 'traduzido' && field !== 'original')
                        if (handleInfoText[field])
                          return <FieldReview key={'FieldReviewLast' + index} field={field} value={handleInfoText[field](lastDoc[field], true)} styleTxt={{}} />;
                        else
                          return <FieldReview key={'FieldReviewLast' + index} field={field} value={objectPend.lastDoc[field]} styleTxt={{}} />;
                    })}
                  </div>
                ) : null
              }
              {
                !objIsEmpty(objectPend.currDoc) ? (
                  <div className='div_info_doc'>
                    <div className='div_title_doc'>
                      Documento Novo
                    </div>
                    { keysCurr.map((field, index) => {
                      const currDoc = objectPend.currDoc;
                      if (field !== 'traduzido' && field !== 'original' && field !== 'new')
                        if (handleInfoText[field]) {
                          return <FieldReview key={'FieldReviewCurr' + index} field={field} value={handleInfoText[field](currDoc[field], false)} styleTxt={editTextStyle(field)} />;
                        } else {
                          return <FieldReview key={'FieldReviewCurr' + index} field={field} value={objectPend.currDoc[field]} styleTxt={editTextStyle(field)} />;
                        }  
                    })}
                    {
                      ['Editar', 'Criar'].includes(objectPend.action) ? (
                        <div className='div_title_doc'>
                          <button type='button' className='btn_edit_inventory btn_acepy_changes btn_edit_pendency' onClick={() => handleEditPendency()}>Editar Pendência</button>
                        </div>
                      ) : null
                    }
                  </div>
                ) : null
              }
            </div>
            <div className='div_align_review_pendency'>
              {
                role !== 'contentEditor' ? (
                    <>
                      <SelectReview title={'Avaliar Solicitação'} setAvaliation={setAvaliation} field={'avaliation'} />
                      {
                        !avaliation ? (
                          <TextareaReview title={'Motivo da Recusa* (Campo Obrigatório):'} setMessage={setMessage} placeholder={'Digite aqui...'} field={'input_message'} explanation={'Justifique o motivo para recusar a ação pendente. Esse texto será enviado ao Solicitante da ação.'} styleType={true}/>) : null
                      }
                    </>
                ) : null
              }
              <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleSetPendency} btnAc={'btn_send_pendency'} btnRe={'btn_back_pendency'} role={role} />
            </div>
          </>
        )
      }
    </div>
  );
}
