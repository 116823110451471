import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Rating from './Rating';
import Favorite from './Favorite';
import DivHexagons from './DivHexagons';
import DistanceToAttractive from './DistanceToAttractive';
import Slide from '@material-ui/core/Slide';

import { connect } from 'react-redux';

class MobileAttractivesPreview extends React.Component {
  render() {
    const { attractive, isMobile, isPreviewOpen, lastPreviewOpened, showRating, showFavorites } = this.props;
    if (!isMobile || !isPreviewOpen || lastPreviewOpened !== 'attractive') return null;

    return (
      <Slide direction="up" in={this.props.isPreviewOpen} mountOnEnter unmountOnExit>
        <div onClick={() => this.props.handleClick(this.props.attractive)} className={css(styles.mobilePreview)} >
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '0.7rem', alignItems: 'center' }}>
            { showRating && <Rating style={{ marginRight: 20 }}/> }
            { showFavorites && <Favorite/> }
          </div>

          <div className={css(styles.previewTitle)}>
            {attractive.Title}
          </div> 

          <div className={css(styles.pinkLine)}/>

          <div className={css(styles.shortDescriptionDiv)}>
            <p className={css(styles.shortDescription)}>{ attractive['Short Description'] }</p>
          </div>

          <div className={css(styles.hexagonAndDistanceDiv)}>
            <div style={{
              flex: 0.5, marginLeft: '0.7rem', width: '100px', alignSelf: 'center',
            }}>
              <DivHexagons
                activities={attractive['Activities List']}
                isMobile={this.props.isMobile}
              />
            </div>
            <div style={{ display: 'flex', flex: 0.5, justifyContent: 'center' }}>
              <DistanceToAttractive
                attractiveLat={attractive.lat}
                attractiveLng={attractive.long}
                isMobile={this.props.isMobile}
              />
            </div>
          </div>
        </div>
      </Slide>
    );
  }
}

const styles = StyleSheet.create({
  hexagonAndDistanceDiv: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',
    height: 'auto',
  },
  mobilePreview: {
    position: 'absolute',
    bottom: 0,
    height: 'fit-content',
    width: '100%',
    backgroundColor: '#b03cdb',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    marginRight: '0.8rem',    
    padding: '0.3rem',
  },
  pinkLine: {
    marginLeft: '0.2rem',
    borderBottomColor: '#db3d78',
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    width: '3rem',
    marginBottom: '0.3rem',
    marginTop: '0.3rem',
  },
  previewTitle: {
    textAlign: 'left',
    color: 'white',
    fontSize: '1rem',
    paddingTop: '0.5rem',
    paddingLeft: '0.5rem',
    fontFamily: 'ubuntu',
    fontWeight: 'bold',
  },
  shortDescription: {
    textOverflow: 'ellipsis',
    overFlow: 'auto',
    fontFamily: 'ubuntu',
    fontWeight: 'regular',
    color: 'white',
    marginLeft: '0.5rem',
    fontSize: '0.8rem',
    marginBottom: '0.5rem',
    textAlign: 'justify',
  },
  shortDescriptionDiv: {
    fontSize: '0.8rem',
    marginRight: '0.7rem',
  },
});

function mapStateToProps(state) {
  return {
    isPreviewOpen: state.isPreviewOpen,
    attractive: state.previewInfo,
    lastPreviewOpened: state.lastPreviewOpened,
    showRating: state.flags.showRating,
    showFavorites: state.flags.showFavorites,
  };
}
export default connect(mapStateToProps)(MobileAttractivesPreview);