import React from 'react';

// Assets imports
import WarningIcon from '../assets/imgs/warning_icon.svg';
import ErrorIcon from '../assets/imgs/error_icon.svg';
import SucessIcon from '../assets/imgs/sucess_icon.svg';

// CSS import
import '../assets/css/warningPopup.css';

export default function WarningPopup({ type, message, close }) {
  const popup = {
    warning: {
      icon: WarningIcon,
      color: '#fff9e9',
      title: 'Aviso',
    },
    sucess: {
      icon: SucessIcon,
      color: '#eafbf6',
      title: 'Sucesso',
    },
    error: {
      icon: ErrorIcon,
      color: '#eafbf6',
      title: 'Sucesso',
    }
  }

  return (
    <div className='WarningPopup' style={{ backgroundColor: popup[type].color }}>
      <div className='div_icon_warning_popup'>
        <img src={popup[type].icon} alt={'Ícone de aviso'} className='icon_warning_popup' />
      </div>
      <div className='div_message_warning_popup'>
        <p><strong>{ popup[type].title }: </strong>{ message }</p>
      </div>
      {
        close ? (
          <div className='div_close_warning_popup'>
            x
          </div>
        ) : null
      }
    </div>
  );
}