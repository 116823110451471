import React, { useEffect, useState } from 'react';

// Component Imports
import ExportExcel from '../ExportExcel';

// Firebase Import
import firebase from '../../config/Firebase';

export default function DemandList({ doc, handleEdit, handleChange, handleOpenReportDemand, role }) {
  const [initial, setInitial] = useState();
  const [final, setFinal] = useState();
  const [publish, setPublish] = useState();
  const [data, setData] = useState();
  const labels = [
    { label: 'tituloEstudoDemanda', value: 'tituloEstudoDemanda' },
    { label: 'data', value: 'data' },
    { label: 'cidade', value: 'cidade' },
    { label: 'numeroPergunta', value: 'numeroPergunta' },
    { label: 'pergunta', value: 'pergunta' },
    { label: 'resposta', value: 'resposta' },
    { label: 'tipoPergunta', value: 'tipoPergunta' },
  ];

  async function handleSetCSVFile() {
    const docs = await firebase.firestore().collection('EstudoDemandaRespostas')
      .where('idEstudoDemanda', '==', doc.id)
      .orderBy('numeroPergunta')
      .get();

    const data = [];

    docs.docs.map((docRes) => {
      const dataRes = docRes.data();
      data.push({ ...dataRes, data: handleFormatDate(dataRes.data) });
    });

    setData(data);
  }

  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  function handleFormatDate(date) {
    const dateType = new Date(date.seconds * 1000);
    let textDate = '';

    textDate = dateType.getUTCFullYear() +
        '-' + pad(dateType.getUTCMonth() + 1) +
        '-' + pad(dateType.getDate());

    return textDate;
  }

  async function handleSetPublish() {
    const docInfo = await firebase.firestore().collection('EstudoDemanda').doc(doc.id).get();

    setPublish(!!docInfo.data().publicado);
  }

  async function handlePublish() {
    await firebase.firestore().collection('EstudoDemanda').doc(doc.id).update({
      publicado: !publish,
    });

    setPublish(!publish);
    handleChange();
  }

  useEffect(() => {
    const data = doc.data();

    const dateIn = new Date(data.dataIni.seconds * 1000);
    const dateFi = new Date(data.dataFim.seconds * 1000);

    setInitial(`${dateIn.getDate()}/${dateIn.getMonth() + 1}/${dateIn.getFullYear()}`);
    setFinal(`${dateFi.getDate()}/${dateFi.getMonth() + 1}/${dateFi.getFullYear()}`);

    handleSetPublish();
    handleSetCSVFile();
  }, []);

  if (!initial || !final || publish === undefined || !data)
    return <></>;

  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().titulo}</td>
      <td className="title_table_inventory">{doc.data().cidade}</td>
      <td className="title_table_inventory">{initial}</td>
      <td className="title_table_inventory">{final}</td>
      <td className="title_table_inventory">{doc.data().respondentes.length || 0}</td>
      <td className="title_table_inventory">{doc.data().publicado ? 'Publicado' : 'Não Publicado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handlePublish()}>{ publish ? 'Despublicar' : 'Publicar' }</button>
          <ExportExcel name={'Estudo de demanda respostas'} labels={labels} data={data} title={doc.data().titulo} />
          { ['contentManager', 'admin', 'cityAdmin', 'masterAdmin'].includes(role) ? <button className='btn_mod_adm' onClick={() => handleOpenReportDemand(data, doc.data())}>Emitir relatório</button> : null }
        </div>
      </td>
    </tr>
  );
}