import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { MAP } from 'react-google-maps/lib/constants';

class MapCustomControl extends React.Component {
  static contextTypes = { [MAP]: PropTypes.object };

  componentWillMount() {
    this.map = this.context[MAP];
    this.controlDiv = document.createElement('div');
    this.divIndex = this.map.controls[this.props.position].length;
    this.map.controls[this.props.position].push(this.controlDiv);
  }

  componentWillUnmount() {
    this.map.controls[this.props.position].removeAt(this.divIndex);
  }

  render() {
    return createPortal(this.props.children, this.controlDiv);
  }
}

export default MapCustomControl;
