import { valuesCityRules, valuesCityType, valueContractCity, demandYearApply } from '../InputComponents/ValuesCheckBox';

export const fieldsEditCities = [
  {    
    title: 'Nome da Cidade*',
    field: 'name',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {
    title: 'Região Turística / IGR*',
    field: 'nomeRegiao',
    boolType: false,
    component: 'select',
    disable: false,
  },
  {    
    title: 'Geolocalização',
    field: 'location',
    boolType: false,
    component: 'location',
    placeholder: '[ Latitude, Longitude ]',
    explanation: 'Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444',
  },
  {    
    title: 'Zoom Desktop',
    field: 'desktopDefaultZoom',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Zoom Mobile',
    field: 'mobileDefaultZoom',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Imagem Padrão dos Eventos',
    type: 'file',
    field: 'defaultEventImage',
    boolType: false,
    component: 'singleImage',
    explanation: 'Imagem padrão dos eventos realizados na cidade',
    typeImage: 'eventImage',
  },
  {    
    title: 'Imagem Padrão da Cidade',
    type: 'file',
    field: 'defaultImage',
    boolType: false,
    component: 'singleImage',
    explanation: 'Imagem padrão da cidade, ou foto da entrada da cidade',
    typeImage: 'cityImage',
  },
  {    
    title: 'KML',
    field: 'KMLFile',
    type: 'file',
    boolType: false,
    component: 'file',
    explanation: 'Arquivo .kml sobre a cidade',
  },
  {    
    title: 'Vídeo (Link)',
    field: 'Video',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Site Oficial',
    field: 'siteOficial',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Página Oficial Instagram',
    field: 'oficialInstagram',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Página Oficial Facebook',
    field: 'oficialFacebook',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'KitMedia',
    field: 'KitMedia',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Imagem PIN',
    type: 'file',
    field: 'ImagemPinCidade',
    boolType: false,
    component: 'singleImage',
    explanation: '',
    typeImage: 'pinImage',
  },
  {    
    title: 'Características da Cidade',
    field: 'typeLocal',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesCityType,
    typeCheck: false,
    styleType: true,
    rankingPoints: true,
  },
  {    
    title: 'Possui selo Verde Azul',
    field: 'possuiSeloVerdeAzul',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {    
    title: 'PIT com Atendimento 7 dias na semana',
    field: 'pitAtendimento',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {
    title: 'Data Expiracao Contrato',
    field: 'DataExpiracaoContrato',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {
    title: 'Cidade contratada?',
    field: 'Contratada',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valueContractCity,
    typeCheck: false,
  },
  {    
    title: 'Área da Cidade',
    field: 'Area',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Densidade Demográfica',
    field: 'DensidadeDemografica',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Taxa de urbanização',
    field: 'TaxaUrbanizacao',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'IDHM',
    field: 'IDHM',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Leis do COMTUR',
    field: 'LeisCOMTUR',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesCityRules,
    typeCheck: true,
    styleType: true,
    rankingPoints: true,
  },
  {    
    title: 'Nível de Atendimento em Abastecimento de Água',
    field: 'NivelAbastecimentoAgua',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Nível de Atendimento em Coleta de Lixo',
    field: 'NivelColetaLixo',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Nível de Atendimento em Esgoto Sanitário',
    field: 'NivelSaneamento',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'População',
    field: 'Populacao',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Municípios Limítrofes',
    field: 'MunicipiosLimitrofes',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Clima',
    field: 'Clima',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'PIB',
    field: 'PIB',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Ano Base',
    field: 'AnoBase',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Representação econômica turística',
    field: 'RepresentacaoTuristica',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Quantidade Produtos em Roteiro Turístico Formatado por agência receptiva',
    field: 'qtdRoteiroTuristico',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Arrecadação',
    field: 'Arrecadacao',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Principais fontes de receita',
    field: 'FontesReceita',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Empregos',
    field: 'Empregos',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Plano Diretor de Turismo',
    field: 'PlanoDiretorTurismo',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {    
    title: 'Lei regulamentadora ou decreto',
    field: 'Regulamentacao',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Plano de Marketing',
    field: 'PlanoMarketing',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Fluxo turístico internacional',
    field: 'internationalFlux',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Fluxo turístico doméstico',
    field: 'domesticFlux',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
    rankingPoints: true,
  },
  {    
    title: 'Estudo de Demanda Validado por Turismólogo',
    field: 'formDemandValid',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {    
    title: 'Aplicou mais de 100 formulários (Estudo de Demanda)',
    field: 'appliedHundredFormDemand',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  {    
    title: 'Ano em que aplicou Estudo de Demanda',
    field: 'yearsApplyDemand',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: demandYearApply,
    typeCheck: true,
    rankingPoints: true,
  },
  {
    title: 'Última versão do Plano Diretor de Turismo',
    field: 'UltimaVersaoPlanoDiretor',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {    
    title: 'Última versão do Plano de Marketing',
    field: 'PlanoMarketingVersaoFinal',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Período de maior fluxo turístico',
    field: 'PeriodoMaiorFluxo',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Total de Turistas (Estimativa)',
    field: 'TotalTuristas',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Possui sinalização turística',
    field: 'SinalizacaoTuristica',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Outros idiomas',
    field: 'OutrosIdiomas',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Padrão internacional',
    field: 'PadraoInternacional',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Descrição Curta*',
    field: 'shortDescr',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma breve descrição sobre a cidade',
  },
  {    
    title: 'Descrição Longa*',
    field: 'longDescr',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma descrição mais detalhada sobre a cidade',
  },
  {    
    title: 'Ativo*',
    field: 'Active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];