// Firebase Import
import firebase from '../config/Firebase';

export async function handleAttractionScores(city) {
  const attractiveDocs = await firebase.firestore().collection('Attractions')
    .where('City', '==', city)
    .where('Active', '==', true)
    .get();

  const attractives = attractiveDocs.docs;

  const score = [{
    title: 'Atrativos Naturais',
    field: 'Subtype',
    like: ['Natural'],
    verifyAction: handleScoreType,
    type: 'score',
    score: 0,
    scoreType: [0, 1, 3, 4, 6],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    maxScore: 3,
  },
  {
    title: 'Parques Unidades de Conservação',
    field: 'Especialidade',
    like: ['Unidade de conservação'],
    verifyAction: handleScoreType,
    type: 'score',
    score: 0,
    scoreType: [0, 1],
    pointType: [0, 1, 2], 
    currScore: 0,
    maxScore: 2,
  },
  {
    title: 'Atrativo cultural tombado pelo CONDEPHAAT?',
    field: 'tombadoCondephaat',
    verifyAction: handleScoreType,
    like: [true],
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Centro histórico tombado?',
    field: 'centroHistoricoTombado',
    verifyAction: handleScoreType,
    like: [true],
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Tem Bens tombados pelo IPHAN?',
    field: 'tombadoIPHAN',
    verifyAction: handleScoreType,
    like: [true],
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Quantidade de Museus',
    field: 'Especialidade',
    like: ['Museu'],
    verifyAction: handleScoreType,
    type: 'score',
    score: 0,
    scoreType: [0, 1, 2, 5],
    pointType: [0, 1, 2, 2, 3], 
    currScore: 0,
    maxScore: 3,
  },
  {
    title: 'Atrativos Culturais',
    field: 'Subtype',
    like: ['Cultural'],
    type: 'score',
    verifyAction: handleScoreType,
    scoreType: [0, 1],
    pointType: [0, 1, 2], 
    currScore: 0,
    score: 0,
    maxScore: 2,
  },
  /* {
    title: 'Artesanato',
    field: 'Subtype',
    like: ['Artesanato'], MOVER PARA CIDADES
    score: 'Não',
    currScore: 0,
    maxScore: 1,
    verifyAction: handleScoreType,
  } */
  {
    title: 'Possui Loja de Artesanato',
    field: 'Especialidade',
    like: ['Loja de artesanato'],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 2,
  },
  {
    title: 'Turismo Religioso - Quantidade de locais de Cunho Religioso',
    field: 'Especialidade',
    like: ['Arquitetura religiosa'],
    verifyAction: handleScoreType,
    score: 0,
    scoreType: [0, 1, 2, 5],
    pointType: [0, 1, 2, 2, 3], 
    currScore: 0,
    type: 'seg',
    maxScore: 3,
  },
  {
    title: 'Turismo náutico - Quantidade de locais (marinas, píer, atracadouro)',
    field: 'Segmento Turístico',
    like: ['Náutico'],
    verifyAction: handleSegmentType,
    score: 0,
    scoreType: [0, 1, 2, 5],
    pointType: [0, 2, 3, 3, 4], 
    currScore: 0,
    type: 'seg',
    maxScore: 4,
  },
  {
    title: 'Turismo de Aventura - Quantidade de empresas de Turismo de Aventura que operam',
    field: ['Especialidade', 'Segmento Turístico'],
    like: ['Operadora turística', 'Agencia de turismo receptivo', 'Guia de Turismo', 'Aventura'],
    verifyAction: handleSegmentAdventureType,
    score: 0,
    scoreType: [0, 1, 2, 5],
    pointType: [0, 2, 3, 3, 4],
    currScore: 0,
    type: 'seg',
    maxScore: 4,
  },
  {
    title: 'Turismo de Sol e Praia - Possui Praias Marítimas?',
    field: 'Especialidade',
    like: ['Praia marítima'],
    verifyAction: handleSegmentType,
    score: 'Não',
    currScore: 0,
    maxScore: 5,
  },
  {
    title: 'Turismo de Sol e Praia - Quantidade de Praias (lacustres/ fluviais)',
    field: 'Especialidade',
    like: ['Praia fluvial e lacustre'],
    verifyAction: handleScoreType,
    score: 0,
    scoreType: [0, 1],
    pointType: [0, 1, 2],
    currScore: 0,
    type: 'score',
    maxScore: 2,
  },
  {
    title: 'Turismo Industrial - Indústrias para visitação',
    field: 'Subtype',
    like: ['Indústria'],
    verifyAction: handleScoreType,
    score: 0,
    scoreType: [0, 1, 2, 3, 10],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    type: 'score',
    maxScore: 2,
  },
  {
    title: 'Turismo Rural - Quantidade de locais com Turismo Rural',
    field: 'Subtype',
    like: ['Rural'],
    verifyAction: handleSegmentType,
    scoreType: [0, 1, 2, 5, 6, 10, 11, 20],
    pointType: [0, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    score: 0,
    type: 'seg',
    maxScore: 5,
  },
  {
    title: 'Parques Temáticos - Quantidade de Locais',
    field: 'Especialidade',
    like: ['Parque temático e de lazer', 'Parque aquático'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 2, 5],
    pointType: [0, 1, 2, 2, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Turismo LGBT+ Locais Gay Friendly',
    field: 'lgbt',
    like: [true],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Meios de Hospedagens - Quantidade de Meios de Hospedagem',
    field: 'Type',
    like: ['Lodging'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 2, 3, 9],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Meios de Hospedagens com CADASTUR',
    field: ['Type', 'Cadastur'],
    like: ['Lodging'],
    verifyAction: handleFieldType,
    type: 'score',
    scoreType: [0, 1, 2, 5, 6, 10, 11, 19],
    pointType: [0, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    score: 0,
    maxScore: 5,
  },
  {
    title: 'Empregados em A&B',
    field: ['Type', 'Número de Funcionários Fixos', 'Número de Funcionários Temporários'],
    like: ['Food'],
    type: 'sum',
    verifyAction: handleSumType,
    scoreType: [0, 1, 49, 50, 99, 100, 299, 300, 999],
    pointType: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    score: 0,
    maxScore: 5,
  },
  {
    title: 'Empregados em Meios de Hospedagem',
    field: ['Type', 'Número de Funcionários Fixos', 'Número de Funcionários Temporários'],
    like: ['Lodging'],
    type: 'sum',
    verifyAction: handleSumType,
    scoreType: [0, 1, 19, 20, 49, 50, 99, 100, 199],
    pointType: [0, 0.75, 0.75, 1.5, 1.5, 2, 2, 2.5, 2.5, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Quantidade de Ranchos/ Casas de Campo para locação eventual',
    field: 'Subtype',
    like: ['Temporada'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 49, 50, 99],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Quantidade de Meios de Alimentação',
    field: 'Type',
    like: ['Food'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 5, 6, 10, 11, 20, 21, 50],
    pointType: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    score: 0,
    maxScore: 5,
  },
  {
    title: 'Capacidade dos Meios de Alimentação',
    field: ['Type', 'Capacidade Individual'],
    like: ['Food'],
    type: 'sum',
    verifyAction: handleSumType,
    score: 0,
    scoreType: [0, 50, 51, 100, 101, 200, 201, 500],
    pointType: [0, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    maxScore: 5,
  },
  {
    title: 'Agência de Receptivo Turístico',
    field: 'Especialidade',
    like: ['Agência de turismo receptivo'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 10, 11, 20],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Guia de Turismo',
    field: 'Especialidade',
    like: ['Guia de turismo', 'Guia de bike'],
    verifyAction: handleScoreType,
    type: 'score',
    scoreType: [0, 1, 5, 6, 10],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Possui Serviço de Informação Turística',
    field: 'Especialidade',
    like: ['Informações turísticas'],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  }];
    
  attractives.map((attr) => {
    const attrData = attr.data();
    score.map((scr) => {
      if (scr.type === 'score')
        scr.score += scr['verifyAction'](scr.like, scr.field, attrData) ? 1 : 0;
      else if (scr.type === 'sum')
        scr.score += scr['verifyAction'](scr.like, scr.field, attrData, scr.score);
      else if (scr.score === 'Não')
        scr.score = scr['verifyAction'](scr.like, scr.field, attrData) ? 'Sim' : 'Não';
      else if (scr.type === 'seg')
        scr.score += scr['verifyAction'](scr.like, scr.field, attrData) ? 1 : 0;
      
      scr.currScore = handleScore(scr.score, scr.scoreType, scr.pointType, scr.maxScore);
    });
  });
    
  return score;
}

export async function handleCityScore(city) {
  const cityDoc = await firebase.firestore().collection('Cities').where('name', '==', city).get();
  const eventDocs = await firebase.firestore().collection('Events').where('City', '==', city).get();

  const cityData = cityDoc.docs[0].data();
  const score = [{
    title: 'Quantidade de Eventos',
    verifyAction: handleSumEvents,
    score: 0,
    scoreType: [0, 1, 10],
    pointType: [0, 1, 1, 2],
    currScore: 0,
    type: 'sumEvent',
    maxScore: 2,
  },
  {
    title: 'Eventos Fluxo (Fluxo Evento/População)',
    field: ['estimativaPublico', 'Populacao'],
    verifyAction: handleFluxScore,
    scoreType: [0, 1, 1.01, 10],
    pointType: [0, 1, 2, 2, 3],
    currScore: 0,
    type: 'flux',
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Site com informações dos atrativos ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações dos Meios Hospedagem ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações dos Restaurantes ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações do Receptivo/Passeios ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Tem monumento geológico?',
    field: 'possuiMonumentosGeologico',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  },
  {
    title: 'Tem circuito Quilombola?',
    field: 'temCircuitoQuilombola',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  },
  {
    title: 'Possui turismólogo na equipe ?',
    field: 'possuiTurismologo',
    like: [true],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Possui Plano Diretor de Turismo ?',
    field: 'PlanoDiretorTurismo',
    like: ['sim'],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Sustentabilidade - Possui selo Verde Azul ?',
    field: 'possuiSeloVerdeAzul',
    like: ['Certificado'],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'População',
    field: ['Populacao'],
    verifyAction: handleSumScoreType,
    score: 0,
    scoreType: [0, 1, 10999, 11000, 29999, 30000, 60999, 61000, 199999],
    pointType: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5],
    currScore: 0,
    type: 'sumScore',
    maxScore: 5,
  },
  {
    title: 'Roteiro Turístico Formatado por agência receptiva - Qtde de produtos',
    field: ['qtdRoteiroTuristico'],
    verifyAction: handleSumScoreType,
    score: 0,
    type: 'sumScore',
    scoreType: [0, 1],
    pointType: [0, 1, 2],
    currScore: 0,
    maxScore: 2,
  },
  {
    title: 'Faz parte de Circuito / Região Turística reconhecido pela SETUR',
    field: 'nomeRegiao',
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'PIT Atendimento 7 dias na semana',
    field: 'pitAtendimento',
    like: ['sim'],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  }];
  
  score.map(async (scr) => {
    if (scr.type === 'score')
      scr.score += scr['verifyAction'](scr.like, scr.field, cityData) ? 1 : 0;
    else if (scr.type === 'sum')
      scr.score += scr['verifyAction'](scr.like, scr.field, cityData, scr.score);
    else if (scr.type === 'sumScore')
      scr.score += scr['verifyAction'](scr.field, cityData);
    else if (scr.type === 'other')
      scr.score += scr['verifyAction'](scr.like, scr.field, cityData, scr.collection) ? 1 : 0;
    else if (scr.type === 'flux')
      scr.score = scr['verifyAction'](scr.field, cityData, eventDocs);
    else if (scr.type === 'sumEvent')
      scr.score = scr['verifyAction'](eventDocs);
    else if (scr.score === 'Não')
      scr.score = scr['verifyAction'](scr.like, scr.field, cityData) ? 'Sim' : 'Não';

    scr.currScore = handleScore(scr.score, scr.scoreType, scr.pointType, scr.maxScore);
  });

  return score;
}

export async function handleDemandScore(city) {
  const cityDocs = await firebase.firestore().collection('Cities').where('name', '==', city).get();

  const demandData = [cityDocs.docs[0]];

  const score = [{
    title: 'Fluxo Internacional',
    field: ['internationalFlux'],
    verifyAction: handleSumScoreType,
    type: 'sumScore',
    score: 0,
    scoreType: [0, 1, 19999, 20000, 49999],
    pointType: [0, 3, 3, 4, 4, 5], 
    currScore: 0,
    maxScore: 5,
  },
  {
    title: 'Fluxo Doméstico',
    field: ['domesticFlux'],
    verifyAction: handleSumScoreType,
    type: 'sumScore',
    score: 0,
    scoreType: [0, 1, 9999, 10000, 19999, 20000, 49999, 50000, 99999],
    pointType: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5], 
    currScore: 0,
    maxScore: 5,
  },
  {
    title: '100 ou mais formulários aplicados ?',
    field: 'appliedHundredFormDemand',
    verifyAction: handleScoreType,
    like: [true],
    score: 'Não',
    currScore: 0,
    maxScore: 1,
    forms: 0,
  }];

  
  const currYear = new Date().getFullYear();
  const yearsStats = [currYear, currYear - 1, currYear - 2];

  yearsStats.forEach((year) => {
    score.push({
      title: `Aplicou Estudo de Demanda em ${year} ?`,
      field: 'yearsApplyDemand',
      like: [year.toString()],
      verifyAction: handleSegmentType,
      score: 'Não',
      currScore: 0,
      maxScore: 1,
    })
  })


  if (demandData) {
    demandData.map((dem) => {
      const demand = dem.data();
      score.map((scr) => {
        if (scr.type === 'score')
          scr.score += scr['verifyAction'](scr.like, scr.field, demand) ? 1 : 0;
        else if (scr.type === 'sumScore')
          scr.score += scr['verifyAction'](scr.field, demand);
        else if (scr.score === 'Não')
          scr.score = scr['verifyAction'](scr.like, scr.field, demand) ? 'Sim' : 'Não';

        scr.currScore = handleScore(scr.score, scr.scoreType, scr.pointType, scr.maxScore);
      });
    });
  }

  return score;
}

export async function handleComturScore(city) {
  const docs = await firebase.firestore().collection('Cities').where('name', '==', city).get();

  const cityData = docs.docs[0].data();

  const score = [{
    title: 'Lei COMTUR com composição definida na Lei 1261/15',
    field: 'LeisCOMTUR',
    like: ['composiçãoDefinida'],
    verifyAction: handleSegmentType,
    type: 'seg',
    score: 'Não',
    currScore: 0,
    maxScore: 2,
  },
  {
    title: 'Atas com Registro Cartório',
    field: 'LeisCOMTUR',
    like: ['registroCartorio'],
    verifyAction: handleSegmentType,
    type: 'seg',
    score: 'Não',
    currScore: 0,
    maxScore: 2,
  },
  {
    title: 'Lista de presença ?',
    field: 'LeisCOMTUR',
    like: ['listaPresenca'],
    verifyAction: handleSegmentType,
    type: 'seg',
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Lei com um 2/3 da sociedade civil ?',
    field: 'LeisCOMTUR',
    like: ['sociedadeCivil'],
    verifyAction: handleSegmentType,
    type: 'seg',
    score: 'Não',
    currScore: 0,
    maxScore: 5,
  }];

  score.map((scr) => {
    scr.score = scr['verifyAction'](scr.like, scr.field, cityData) ? 'Sim' : 'Não';
    scr.currScore = handleScore(scr.score, scr.scoreType, scr.pointType, scr.maxScore);
  });

  return score;
}

function handleScoreType(like, field, item) {
  if (!like) return !!item[field];

  return like.includes(item[field]);
}

function handleSegmentType(like, field, item) {
  if (!item[field]) return false;

  for (let index = 0; index < like.length; index++) {
    if (item[field].includes(like[index]))
      return true;
  }

  return false;
}

function handleSegmentAdventureType(like, field, item) {
  if (!item[field[0]]) return false;

  const types = [like[0], like[1], like[2]];

  if (types.includes(item[field[0]]) && item[field[1]] && item[field[1]].includes(like[3]))
    return true;

  return false;
}

function handleFieldType(like, field, item) {
  if (like.includes(item[field[0]]))
    return !!item[field[1]];

  return false;
}

function handleSumScoreType(field, item) {
  if (!item[field[0]]) return 0;
  
  let sum = 0;

  for (let i = 0; i < field.length; i++)
    sum += item[field[i]] ? Number(item[field[i]]) : 0;

  return sum;
}

function handleSumType(like, field, item) {
  let sum = 0;

  if (like) {
    if (like.includes(item[field[0]])) {
      for (let i = 1; i < field.length; i++)
        sum += item[field[i]] ? item[field[i]] : 0; 
    }
  } else {
    for (let i = 1; i < field.length; i++)
      sum += item[field[i]] ? Number(item[field[i]]) : 0;
  }

  return sum;
}

async function handleCollecType(like, field, item, collection) {
  const docs = await firebase.firestore().collection(collection).where(field, 'array-contains', item.name).get();

  if (docs.docs && docs.docs.length > 0)
    return true;

  return false;
}

function handleDateType(like, field, item) {
  const dateFormat = new Date(item[field]);

  return Number(dateFormat.getFullYear()) === like;
}

function handleScore(score, scoreType, pointType, maxScore) {
  if (scoreType) {
    for (let index = 0; index < scoreType.length; index++) {
      if (score < scoreType[index])
        return pointType[index - 1];
  
      if (score === scoreType[index])
        return pointType[index];
    }
  } else if (score === 'Não')
    return 0;

  return maxScore;
}

function handleFormType(field, item) {
  const currDate = new Date();

  if (item.dataFim && item.dataFim.getFullYear() === currDate.getFullYear())
    return item[field] ? item[field].length : 0;

  return 0;
}

function handleFluxScore(field, item, eventDocs) {
  let totalFlux = 0;
  eventDocs.docs.map((event) => {
    const flux = event.data()[field[0]] || 0;
    totalFlux += flux >= 0 ? flux : 0;
  });

  const div = item[field[1]] ? item[field[1]].toFixed() : 1;

  if (div === 1)
    return 0;

  return Number((totalFlux / div).toFixed());
}

function handleSumEvents(eventDocs) {
  let currEvents = 0;

  eventDocs.docs.map((event) => {
    const dateData = event.data().EndDate;
    if (dateData) {
      const currYear = new Date().getFullYear();
      const eventYear = new Date(dateData.seconds * 1000).getFullYear();

      if (currYear === eventYear)
        currEvents++;
    }
  });

  return currEvents;
}