import React, { useEffect, useState } from 'react';

// Firebase import
import firebase from '../../config/Firebase';

// Css import
import '../../assets/css/EditComponents/EditInventory.css';

// Components Imports
import Loading from '../Loading';
import VerifyPopup from '../VerifyPopup';
import TitleEditor from '../FormComponents/TitleEditor';
import HeaderForm from '../FormComponents/HeaderForm';
import ButtonForm from '../FormComponents/ButtonForm';
import { fieldsEditInventory } from '../FieldsForm/attraction.fields';
import { inputsComponents } from '../../utils/formManager';
import PlaceID from '../InputComponents/PlaceID';

export default function EditInventory({ setEditPopup, doc, action, userCity, pendency, objectPend, setObjectPend, setNumPendency, role }) {
  const [popup, setPopup] = useState(false);
  const [mapPopup, setMapPopup] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [item, setItem] = useState(handleItem());
  const fieldsRemove = ['Type', 'Subtype', 'Especialidade', 'Segmento Turístico', 'Impulsionar', 'classificadoAtrativo', 'justifiqueAtrativo'];

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;

    return doc ? doc.data() : {
      Title: '',
      'Short Description': '',
      'Long Description': '',
      City: userCity,
      Type: '',
      Subtype: '',
      Especialidade: '',
      Active: true,
      Visible: true,
      location: null,
      original: {
        'Short Description': '',
        'Long Description': '',
      },
    };
  }

  const handleEditInventory = async () => {
    try {
      await handleSaveImages('edit');

      const pendency = {
        action: 'Editar',
        city: item.City,
        createDate: new Date(),
        currDoc: item,
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Attractions',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };

      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  const handleAddInventory = async () => {
    try {
      await handleSaveImages('create');

      const pendency = {
        action: 'Criar',
        city: item.City,
        createDate: new Date(),
        currDoc: item,
        lastDoc: {},
        requestor: (await getUserInfo()).name,
        type: 'Attractions',
        emailRequester: (await getUserInfo()).email,
        docId: '',
      };

      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function handleSaveImages(type) {
    allImages.map(async (img) => {
      if (type !== 'edit' || img.image !== '') {
        const ref = firebase.storage().ref(`cities/${item.City}/${img.name}`);
        await ref.put(img.image);
      }
    });
  }

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  async function handleSetActiveCity(city) {
    const docs = await firebase.firestore().collection('Cities').where('name', '==', city).get();
    const cityData = docs.docs[0].data();

    let newItem = item;
    if (action === 'Criar' && !objectPend)
      newItem = { ...newItem, City: userCity };

    if (!objectPend)
      newItem = { ...newItem, cidadeAtiva: cityData.Active, nomeRegiao: cityData.nomeRegiao || '' };

    setItem(newItem);
  }

  useEffect(() => {
    handleSetActiveCity(objectPend ? objectPend.currDoc.City : userCity);
  }, [userCity]);

  async function handleEditPendency() {
    let newPendency;
    newPendency = { ...objectPend, currDoc: item };

    if (item['Atendimento Idioma Estrangeiro'])
      newPendency = { ...newPendency, currDoc: { ...newPendency.currDoc, 'Atendimento Idioma Estrangeiro': item['Atendimento Idioma Estrangeiro'].toString() } };
    
    if (item['Meios de Pagamento'])
      newPendency = { ...newPendency, currDoc: { ...newPendency.currDoc, 'Meios de Pagamento': item['Meios de Pagamento'].toString() } };
    
    await deleteImagesPendency(objectPend.deleteFiles, objectPend.city);
    await handleSaveImages('edit');
    setObjectPend(newPendency);

    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  async function deleteImagesPendency(fileNames, city) {
    if (!fileNames) return;

    fileNames.map(async (nameFile) => {
      const ref = firebase.storage().ref(`cities/${city}/${nameFile}`);
      await ref.delete();
    });
  }

  async function handleCheckFields() {
    const keys = ['Title', 'Short Description', 'Long Description'];
    
    if (role !== 'tradeAdmin')
      keys.push('Type', 'Subtype', 'Especialidade');

    if (item.Visible) {
      if (!item.location && !item.access)
        keys.push('location');
    } else {
      keys.filter((key) => key !== 'location' && key !== 'access');
    }

    if (item.classificadoAtrativo) {
      keys.push('justifiqueAtrativo');
    } else {
      keys.filter((key) => key !== 'justifiqueAtrativo');
    }

    if (item.Imperdivel) {
      keys.push('destaqueLocal');
    } else {
      keys.filter((key) => key !== 'destaqueLocal');
    }
  
    for (let i = 0; i < keys.length; i++) {
      if (!item[keys[i]] || (item[keys[i]] === '' || item[keys[i]] === 0 || item[keys[i]] === null)) {
        const comp = document.getElementById(keys[i] + 'Form');
        if (comp) {
          comp.focus();
          return;
        }
      }
    }

    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  if (!userCity) {
    return <Loading />;
  }

  return (
    <div className='EditInventory'>
        <div className='bodyEditInventory'>
          <TitleEditor action={action} type={'Inventário'} />
          <div>
            <form className='form_edit_admin'>
              <HeaderForm setMapPopup={setMapPopup} getPlaceId={true} />
              { mapPopup ? <PlaceID setMapPopup={setMapPopup} setItem={setItem} item={item} /> : null }
              {
                fieldsEditInventory.map((field, index) => {
                  if ((!['classificadoAtrativo', 'justifiqueAtrativo'].includes(field.field) || item.Type !== 'Attraction') && (!['Imperdivel', 'destaqueLocal'].includes(field.field) || item.Type === 'Attraction') && (role !== 'tradeAdmin' || !fieldsRemove.includes(field.field)))
                    return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: action === 'Criar' && !objectPend ? userCity : item.City, allImages: allImages, setAllImages: setAllImages, objectPend: objectPend, action: action === 'Criar', editType: action === 'Criar', role: role, userRole: role, actionType: action, startDate: 'StartDate', endDate: 'EndDate', setObjectPend: setObjectPend, keyName: 'InventoryField' + index, rankingPoints: !!field.rankingPoints });
                })
              }
              { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditInventory : handleAddInventory} title={'Deseja gravar as alterações ?'} message={'Esse registro passará por uma revisão e moderação pela equipe de conteúdo e deverá ser publicado no prazo até 5 dias úteis. Em caso de urgência entre em contato com a equipe de conteúdo. Deseja continuar ?'} /> : null }
              <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
            </form>
          </div>
        </div>
    </div>
  );
}