import React from "react";

export function TextField({ title, fieldForm, placeholder, onChange, explanation, value, disabled, ...props }) {
  return (
    <div className="field-text-edit" {...props}>
      <label htmlFor={fieldForm}>{title}</label>
      { explanation }
      <input className="field-edit" type="text" id={fieldForm} placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} />
    </div>
  );
}
