import React, { Component } from 'react';
import ReactStars from 'react-stars';
import firebase from '../config/Firebase';

export default class Rating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: 0,
    };
    this.retrieveData = this.retrieveData.bind(this);
    this.ratingChange = this.ratingChange.bind(this);
    this.retrieveData();
  }

  retrieveData() {
    const docRef = firebase.firestore().collection('TestRating').doc('1HRMTLYopcpJe2PfXBW8');
    docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        this.setState({ rate: data.nota });
      }
    });
  }

  updateData() {
    const docRef = firebase.firestore().collection('TestRating').doc('1HRMTLYopcpJe2PfXBW8');

    docRef.update({
      nota: this.state.rate,
    });
  }

  ratingChange(newValue) {
    this.setState({ rate: newValue }, () => {
      this.updateData();
    });
  }

  render() {
    const settings = {
      count: 5,
      value: this.state.rate,
      color1: '#92558e', /* É a cor das estrelas nao selecionadas */
      color2: '#f9407d', /* É a cor das estrelas selecionadas */
      size: this.props.size,
      edit: true,
      half: true,
      onChange: (newValue) => {
        this.ratingChange(newValue);
      },
    };

    return (
      <ReactStars {...settings}/>
    );
  }
}

Rating.defaultProps = {
  size: 25,
};
