import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/inputFilters.css';

export default function SelectMultipleFilter({ title, field, value, setValue, explanation }) {
  const [pontosApoio, setPontosApoio] = useState([]);
  const [selectedPontosApoio, setSelectedPontosApoio] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [filtersCity, setFiltersCity] = useState();
  const [filtersType, setFiltersType] = useState();
  const [filtersSubtype, setFiltersSubtype] = useState();
  const [cities, setCities] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [filtersSpecialty, setFiltersSpecialty] = useState('');

  // console.log(value[field]);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('Roteiros')
    .doc(value.idRota)
    .get()
    .then((roteiroDoc) => {
      if (roteiroDoc.exists) {
        // Get the "Cities" array from the "Roteiros" document
        const citiesArray = roteiroDoc.data().Cities || [];

        // Now, query the "AttractionsApp" collection based on the "City" field
        db.collection('Attractions')
          .where('Title', '>', '')
          .where('City', 'in', citiesArray) // Use the "citiesArray" here
          .get()
          .then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setPontosApoio(data);
          })
          .catch((error) => console.log(error));
      } else {
        console.log("Roteiros document not found.");
      }
    })
    .catch((error) => console.log(error));
    // if (value[field] && value[field].length > 0) {
    //   db.collection('Attractions')
    //   .where('Title', 'in', value[field])
    //   .get()
    //   .then((snapshot) => {
    //     const selected = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    //     setSelectedPontosApoio(selected);
    //   })
    //   .catch((error) => console.log(error)); 
    // }
    // db.collection('Cities')
    //   .get()
    //   .then((snapshot) => {
    //     const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //     setFiltersCity(data);
    //   })
    //   .catch((error) => console.log(error));
    db.collection('Type')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTipos(data);
      })
      .catch((error) => console.log(error));
    setCities([...new Set(pontosApoio.map((attraction) => attraction.City))]);
    setSubtypes([...new Set(filtersType ? [] : filtersType == "" ? filtersType.Subtype : [])]);

    db.collection('Attractions')
    .get()
    .then((snapshot) => {
      const specialtiesSet = new Set();
  
      snapshot.docs.forEach((doc) => {
        const specialty = doc.data().Especialidade;
  
        if (specialty) {
          specialtiesSet.add(specialty);
        }
      });
  
      const data = Array.from(specialtiesSet).map((specialty, index) => ({ id: index, name: specialty }));
      setSpecialties(data);
    })
    .catch((error) => console.log(error));
  }, []);

  useEffect(() =>{

    setCities([...new Set(pontosApoio.map((attraction) => attraction.City))]);
    setSubtypes([...new Set(filtersType ? [] : filtersType == "" ? filtersType.Subtype : [])]);
    if (pontosApoio && pontosApoio.length>0 && value[field] && value[field].length > 0) {
      setSelectedPontosApoio([... new Set(pontosApoio.filter((item) => value[field].includes(item.id)))])
    }

  },[pontosApoio])

  const handleAttractionChange = (attraction) => {
    const isSelected = selectedPontosApoio.map((item) => item.id).includes(attraction.id);
    if (isSelected) {
      setSelectedPontosApoio(selectedPontosApoio.filter((id) => id.id !== attraction.id));
    } else {
      setSelectedPontosApoio([...selectedPontosApoio, attraction]);
    }
  };

  useEffect(() =>{

    setValue({ ...value, [field]: [...new Set(selectedPontosApoio.map((attraction) => attraction.id))] });

  },[selectedPontosApoio])

  

  useEffect(() =>{
    if (filtersType){
        setSubtypes(tipos.find(doc => doc.name === filtersType).Subtype);
    }
    else if(filtersType === ""){
        setSubtypes([...new Set("")]);

    }
    
  },[filtersType])

  // console.log(value);
  // console.log(selectedPontosApoio);
  const filteredPontosApoio = pontosApoio.filter((ponto) => {
    if (selectedPontosApoio.map((item) => item.id).includes(ponto.id)){
        return true;
    }
    if (filtersCity && ponto.City !== filtersCity) {
      return false;
    }
    if (filtersType && ponto.Type !== filtersType) {
      return false;
    }
    if (filtersSubtype && ponto.Subtype !== filtersSubtype) {
      return false;
    }
    if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty) {
      return false;
    }
    return true;
  });

  if (!pontosApoio) {
    return <Loading />;
  }


  return (
    <div className='inputFilters'>
      <div className='div_label_input_textarea'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input_do_nelli'>
        <div className="filter-dropdown">
            <select
            value={filtersCity}
            onChange={(e) => {
                const city = e.target.value;
                setFiltersCity(city);
            }}
            >
            <option value="">Todas cidades</option>
            {cities.map((city) => (
                <option key={city} value={city}>
                {city}
                </option>
            ))}
            </select>
            <select
            value={filtersType}
            onChange={(e) => {
                const type = e.target.value;
                setFiltersType(type);
            }}
            >
            <option value="">Todos tipos</option>
            {tipos.map((val) => (
                <option key={val} value={val.name}>
                {val.titulo}
                </option>
            ))}
            </select>
            <select
            value={filtersSubtype}
            onChange={(e) => {
                const subtype = e.target.value;
                setFiltersSubtype(subtype);
            }}
            >
            <option value="">Todos subtipos</option>
            {subtypes.map((subtype) => (
                <option key={subtype} value={subtype}>
                {subtype}
                </option>
            ))}
            </select>
            <select
              value={filtersSpecialty}
              onChange={(e) => {
                const specialty = e.target.value;
                setFiltersSpecialty(specialty);
              }}
            >
              <option value="">Todas especialidades</option>
              {specialties.map((specialty) => (
                <option key={specialty.id} value={specialty.name}>
                  {specialty.name}
                </option>
              ))}
            </select>
        </div>
        <div className='div_align_multiple_select_do_nelli'>
          <div className='select_inventory_do_nelli'>
            {filteredPontosApoio.map((attraction) => (
            <div key={attraction.id}>
                <label>
                <input
                    type="checkbox"
                    checked={selectedPontosApoio.map((item) => item.id).includes(attraction.id)}
                    onChange={() => handleAttractionChange(attraction)}
                />
                {attraction.Title}
                </label>
            </div>
            ))}
          </div>
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );}
