import React from "react";

export function SelectField({ title, fieldForm, values, setValue, value, defaultValue, disabled, ...props }) {
  return (
    <div className="field-select-edit" {...props}>
      <label htmlFor={fieldForm}>{title}</label>
      <select className="field-edit" id={fieldForm} onChange={(e) => setValue({ ...value, [fieldForm]: e.target.value })} defaultValue={defaultValue} disabled={disabled}>
        <option value="">Selecione uma opção</option>
        {
          values.map((option, index) => {
            return <option key={fieldForm + index}>{option}</option>;
          })
        }
      </select>
    </div>
  );
}
