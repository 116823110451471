import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Papa from 'papaparse';

// Firebase import
// eslint-disable-next-line import/no-extraneous-dependencies
import Firebase from 'firebase';
import firebase from '../../config/Firebase';

// Assets imports
import SetLocalIcon from '../../assets/imgs/set_local_icon.svg';
// Components imports
import Loading from '../Loading';

// CSS import
import '../../assets/css/InputComponents/geolocationItineraryInput.css';

export function GeolocationItineraryInput({ field, title, value, setValue, doc, objectPend, placeholder, userCity, explanation }) {

  const [csvFile, setCsvFile] = useState(null);
  const [coordinates, setCoordinates] = useState(value.waypoint || []);
  const [names, setNames] = useState(value.waypointTitulo || []);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (value.waypoint.length > 0){
      setCoordinates(value.waypoint);
      setNames(value.waypointTitulo)
    }

  }, []);

  function handleUpload(){

    // Upload GeoPoints to Firebase
    const geoPointsArray = coordinates.map((item) => new Firebase.firestore.GeoPoint(Number(item.latitude), Number(item.longitude)));

    setValue({ ...value, 
              ['waypoint']: geoPointsArray,
              ['waypointTitulo']: names});
    if (!csvFile || csvFile == null){
      alert('Nenhum arquivo foi escolhido!');
    }
    // else{
    //   alert('Waypoints carregados com sucesso!');
    // }
    
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    setCoordinates([]);
    setNames([]);
  

    // Parse the CSV file when the user selects one
    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      dynamicTyping: true,
      quoteChar: false,
      complete: (result) => {
        if (result.data.length > 0) {
          const parsedCoordinates = result.data.map((row) => ({
            latitude: row[0],
            longitude: row[1],
          }));
          const parsedNames = result.data.map((row) => {
            let name = row[2] || ''; // Initialize with the third column, or an empty string if it's not present
            // Append all columns after the third one
            for (let i = 3; i < row.length; i++) {
              name += ','+row[i];
            }
            return name
          });

          setCoordinates(parsedCoordinates);
          setNames(parsedNames);
        }
      },
      error: (error) => {
        console.error('CSV Parsing Error:', error.message);
      },
    });


  };

  useEffect(() => {
    if (csvFile && coordinates.length > 0 && names.length > 0) {
      handleUpload();
    }
  }, [coordinates, names]);



  // useEffect(() => {
  //   // Upload GeoPoints to Firebase
  //   if (names.length > 0){    
  //     const geoPointsArray = coordinates.map((item) => new Firebase.firestore.GeoPoint(Number(item.latitude), Number(item.longitude)));
  //     setValue({ ...value, 
  //               ['waypoint']: geoPointsArray,
  //               ['waypointTitulo']: names});
  //     if (!csvFile || csvFile == null){
  //       alert('Nenhum arquivo foi escolhido!');
  //     }
  // }
  // }, [names, coordinates]);

  return (
    <div className='GeolocationInput'>
      <div className='div_align_input_geolocation'>
        <div className='div_label_input_geolocation'>
          <label htmlFor={field + 'Form'} className="label_text_input">
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_geolocation'>
            <input className='btn_input_arquive' type="file" accept=".csv, .txt" onChange={handleFileChange} />
            {/* <button type='button' className='btn_open_waypoint' onClick={handleUpload} >Carregar Arquivo</button> */}
          </div>
          <div className="coordinates-box">
            <h6>Coordenadas carregadas:</h6>
            <div className="coordinates-list">
              {value.waypointTitulo.length > 0 ? (
                <ul>
                  {value.waypointTitulo.map((nome, index) => (
                    <li key={index}>
                      <strong>Latitude:</strong> {value.waypoint[index].Ic}, <strong>Longitude:</strong> {value.waypoint[index].wc}, <strong>Nome:</strong> {nome}
                    </li>
                  ))}
                </ul>
              ) : (
                <h7>Nenhuma coordenada foi carregada.</h7>
              )}
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}