import React, { useEffect, useState } from 'react';

// CSS Import
import '../../assets/css/InputComponents/selectInput.css';

// Firebase Import
import firebase from '../../config/Firebase';

// Components Import
import Loading from '../Loading';

export default function SelectInput({ title, field, value, setValue, boolType, disable, userCity, doc, objectPend, role, action, indexVal, publish, typeDocument }) {
  const [valuesSelect, setValuesSelect] = useState();
  const [optionLoading, setOptionLoading] = useState(false);
  const [espControl, setEspControl] = useState(false);
  const [subControl, setSubControl] = useState(false);
  const rolesControl = ['admin', 'masterAdmin', 'contentManager', 'contentEditor'];
  const fieldsControl = ['Type', 'Subtype'];
  async function handleTypes() {
    if (typeDocument == 'routes'){
      setValuesSelect([
        { value: 'Adventure', title: 'Aventura' },
        { value: 'Scenic', title: 'Cênica' },
        { value: 'Beer', title: 'Cerveja' },
        { value: 'Bicycle Tourism', title: 'Cilcoturismo' },
        { value: 'Shopping', title: 'Compras' },
        { value: 'Ecotourism', title: 'Ecoturismo' },
        { value: 'Gastronomy', title: 'Gastronomia' },
        { value: 'Motorcycle Tourism', title: 'Mototurismo' },
        { value: 'Religious', title: 'Religioso' },
        { value: 'Rural', title: 'Rural' },
        { value: 'Wine', title: 'Vinho' },
      ]);
    }
    else{
      setValuesSelect([
        { value: 'Attraction', title: 'Atrativo' },
        { value: 'Commerce', title: 'Compras' },
        { value: 'Food', title: 'Alimentação' },
        { value: 'Infrastructure', title: 'Infraestrutura' },
        { value: 'Lodging', title: 'Hospedagem' },
        { value: 'Services', title: 'Serviços' },
      ]);
    }
  }

  async function handleSubtypes() {
    if (value.Type !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', value.Type)
        .orderBy('Subtype')
        .get();

      if (subControl)
        setValue({ ...value, Subtype: '' });
      
      const subtypes = [];
 
      docs.docs.map((sub) => {
        subtypes.push({ title: sub.data().Subtype, value: sub.data().Subtype });
      });

      setValuesSelect(subtypes);
      setSubControl(true);
    } else {
      setValue({ ...value, Subtype: '' });
      setValuesSelect([]);
    }
  }

  async function handleEspecialidade() {
    if (value.Subtype !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', value.Type)
        .where('Subtype', '==', value.Subtype)
        .get();
      
      if (docs.docs[0]) {
        if (espControl || value.Especialidade === '')
          setValue({ ...value, Especialidade: '' });

        const docsFil = docs.docs[0].data().Especialidade.sort();
        const especialidades = [];

        docsFil.map((espec) => {
          especialidades.push({ title: espec, value: espec });
        });

        setValuesSelect(especialidades);
        setEspControl(true);
      } else {
        setValue({ ...value, Especialidade: '', Subtype: '' });
        setValuesSelect([]);
      }
    } else {
      setValue({ ...value, Especialidade: '' });
      setValuesSelect([]);
    }
  }
  function handleActive() {
    setValuesSelect([
      { title: 'Ativo', value: true },
      { title: 'Desativado', value: false },
    ]);
  }

  function handleVisible() {
    setValuesSelect([
      { title: 'Sim', value: true },
      { title: 'Não', value: false },
    ]);
  }

  async function handleAttractions() {
    const docs = await firebase.firestore().collection('Attractions')
      .where('City', '==', userCity)
      .where('Active', '==', true)
      .orderBy('Title')
      .get();

    const attractions = [];

    docs.docs.map((attr) => {
      attractions.push({ title: attr.data().Title, value: attr.id });
    });

    setValuesSelect(attractions);
  }

  async function handleRegions() {
    const docs = await firebase.firestore().collection('Cities')
      .where('regiao', '==', true)
      .orderBy('name')
      .get();

    const regions = [];

    docs.docs.map((reg) => {
      regions.push({ title: reg.data().name, value: reg.data().name });
    });

    setValuesSelect(regions);
  }

  async function handleGuidelines(isEdit) {
    const docs = await firebase.firestore().collection('PlanoDiretor_Diretrizes')
      .orderBy('titulo')
      .where('cidade', '==', userCity)
      .get();
    
    if (isEdit)
      setValue({ ...value, [field]: '' });

    const guidelines = [];
    docs.docs.map((guide) => {
      guidelines.push({ title: guide.data().titulo, value: guide.id });
    });

    setValuesSelect(guidelines);
  }

  function handleStatus() {
    setValuesSelect([
      { title: 'Não iniciado', value: 'Não iniciado' },
      { title: 'Em andamento', value: 'Em andamento' },
      { title: 'Finalizado', value: 'Finalizado' }]);
  }

  async function handleRole() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    const roles = [
      { title: 'Administrador Geral (masterAdmin)', value: 'masterAdmin' },
      { title: 'Administrador (admin)', value: 'admin' },
      { title: 'Administrador de Cidade (cityAdmin)', value: 'cityAdmin' },
      { title: 'Gerenciador de Conteúdo (contentManager)', value: 'contentManager' },
      { title: 'Administrador de Inventários (tradeAdmin)', value: 'tradeAdmin' },
      { title: 'Administrador de Rotas (rotaAdmin)', value: 'rotaAdmin' },
      { title: 'Administrador de Regiões (regionAdmin)', value: 'regionAdmin' },
      { title: 'Vizualizador da Dashboard (dashboardViewer)', value: 'dashboardViewer' },
      { title: 'Editor de Conteúdo (contentEditor)', value: 'contentEditor' },
      { title: 'Entrevistador (entrevistador)', value: 'entrevistador' },
    ];

    // If the user's role is contentManager it cannot change other users roles to masterAdmin
    if (userData.data().role === 'contentManager') {
      roles.shift();
    }

    setValuesSelect(roles);
  }

  async function handlePrograms(isEdit) {
    const docs = await firebase.firestore().collection('PlanoDiretor_Programas')
      .where('idDiretrizes', '==', value.diretriz)
      .get();

    if (isEdit)
      setValue({ ...value, [field]: '' });

    const programs = [];
    docs.docs.map((prog) => {
      programs.push({ title: prog.data().titulo, value: prog.id });
    });

    setValuesSelect(programs);
  }

  function handleTypeQuestion() {
    setValuesSelect([
      { title: 'Atrativos', value: 'atrativos' },
      { title: 'CEP', value: 'cep' },
      { title: 'Escolha Múltipla', value: 'escolhaMultipla' },
      { title: 'Escolha Múltipla com Outro', value: 'escolhaMultiplaOutro' },
      { title: 'Escolha Única', value: 'escolhaUnica' },
      { title: 'Escolha Única com Outro', value: 'escolhaUnicaOutro' },
      { title: 'Genêro', value: 'genero' },
      { title: 'Nota de Item', value: 'notaItem' },
      { title: 'Texto', value: 'texto' },
      { title: 'Texto Dinâmico', value: 'textoDinamico' },
      { title: 'Valor', value: 'valor' },
    ]);
  }

  function handleCity() {
    setValuesSelect([{ title: userCity, value: userCity }]);
  }
 
  useEffect(() => {
    if (field === 'Tipo')
      handleTypes();
    else if (field === 'Subtype')
      handleSubtypes();
    else if (field === 'Especialidade')
      handleEspecialidade();
    else if (field === 'Active' || field === 'active')
      handleActive();
    else if (field === 'Visible')
      handleVisible();
    else if (field === 'nomeRegiao')
      handleRegions();
    else if (field === 'idDiretrizes' || field === 'diretriz')
      handleGuidelines(0);
    else if (field === 'status')
      handleStatus();
    else if (field === 'programa')
      handlePrograms(0);
    else if (field === 'role')
      handleRole();
    else if (field === 'tipoPergunta')
      handleTypeQuestion();
    else if (field === 'City' || field === 'cidade' || 'field' === 'city')
      handleCity();
    else
      handleAttractions();
  }, []);

  useEffect(() => {
    if (field === 'Subtype')
      handleSubtypes();
  }, [value.Type]);

  useEffect(() => {
    if (field === 'Especialidade')
      handleEspecialidade();
  }, [value.Subtype]);

  useEffect(() => {
    if (field === 'AttractionId')
      handleAttractions();
    else if (field === 'idDiretrizes' || field === 'diretriz')
      handleGuidelines(1);
  }, [userCity]);

  useEffect(() => {
    if (value.diretriz && field === 'programa')
      handlePrograms(1);
  }, [value.diretriz]);

  useEffect(() => {
    if (value[indexVal] && value[indexVal]['tipoPergunta'] && optionLoading) {
      const typesQuestionOther = ['escolhaUnicaOutro', 'escolhaMultiplaOutro'];
      const typesQuestion = ['escolhaUnica', 'escolhaMultipla', 'notaItem'];
      if (typesQuestionOther.includes(value[indexVal]['tipoPergunta'])) {
        setValue(Object.values({ ...value, [indexVal]: { ...value[indexVal], opcoes: ['Outro'] } }));
      } else if (typesQuestion.includes(value[indexVal]['tipoPergunta'])) {
        setValue(Object.values({ ...value, [indexVal]: { ...value[indexVal], opcoes: [''] } }));
      } else {
        setValue(Object.values({ ...value,
          [indexVal]: {
            tipoPergunta: value[indexVal].tipoPergunta,
            pergunta: value[indexVal].pergunta,
            tema: value[indexVal].tema,
            idEstudoDemanda: value[indexVal].idEstudoDemanda,
          } }));
      }
    }

    setOptionLoading(true);
  }, [(value[indexVal] ? value[indexVal]['tipoPergunta'] : null)]);

  if (!valuesSelect) {
    return <Loading />;
  }

  return (
    <div className='SelectInput'>
      <div className='div_label_input_text'>
        <label htmlFor={field + 'Form' + (indexVal || '')} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_select_input_select'>
        <select onChange={(event) => (field !== 'tipoPergunta' ? setValue({ ...value, [field]: (boolType ? (event.target.value === 'true') : event.target.value) }) : setValue(Object.values({ ...value, [indexVal]: { ...value[indexVal], [field]: event.target.value } })))} id={field + 'Form' + (indexVal || '')} disabled={disable || (!action && !rolesControl.includes(role) && fieldsControl.includes(field)) || publish} style={publish ? { backgroundColor: '#f3f3f4' } : {} } defaultValue={field !== 'tipoPergunta' ? value[field] : value[indexVal][field]} >
          <option value=''>Selecione uma opção</option>
          { 
            valuesSelect.length > 0 ? valuesSelect.map((e, index) => {
              if (field === 'cidade' || field === 'City' || field === 'city' || field === 'Cidade') 
                return <option key={'Select' + field + index}>{userCity}</option>

              if (field === 'tipoPergunta')
                return <option key={'Select' + field + index + indexVal} value={e.value}>{e.title}</option>

              return <option key={'Select' + field + index} value={e.value}>{e.title}</option>
            }) : <option disabled>Sem opções</option>
          }
        </select>
      </div>
    </div>
  );
}