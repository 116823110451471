import React, { useEffect } from "react";

export function RadioField({ title, fieldForm, values, setValue, explanation, value, disabled, typeBoolean, ...props }) {
  function checkIsOtherOption(field) {
    if (field !== 'Outro') return false;

    if (value[fieldForm] === undefined) return false;

    if (typeBoolean) return false;

    if (values.includes(value[fieldForm])) return false;

    return true;
  }

  function handleRadioValue(valueRadio) {
    if (!typeBoolean) return valueRadio;

    if (valueRadio)
      return 'Sim';

    return 'Não';
  }

  function handleSetRadioValue(field) {
    if (!typeBoolean) return field;

    if (field === 'Sim')
      return true;

    return false; 
  }

  return (
    <div className="field-radio-edit" {...props}>
      <label className="title-radio-edit">{title}</label>
      { explanation }
      <div className="div-align-radio-field">
        { values.map((field, index) => {
          return (
            <div className="div-radio-edit" key={title + field + index}>
              <label className='radio-edit'>
                <input type='radio' name={fieldForm} id={fieldForm + field + index} onChange={() => setValue({ ...value, [fieldForm]: field !== 'Outro' ? handleSetRadioValue(field) : '' })} checked={value[fieldForm] !== undefined ? handleRadioValue(value[fieldForm]) === field || (field === 'Outro' && !values.includes(value[fieldForm])) : false} disabled={disabled} />
                <div className='radio-input'>
                  <div className='check-radio-input'/>
                </div>
              </label>
              { checkIsOtherOption(field) ? <input className="other-option-input-edit" type="text" onChange={(e) => setValue({ ...value, [fieldForm]: e.target.value })} value={value[fieldForm]} disabled={disabled} /> : <label className="label-radio-edit" htmlFor={fieldForm + field + index}>{field}</label>}
            </div>
          );
        }) }
      </div>
    </div>
  );
}