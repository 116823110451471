import React, { useEffect, useState, useRef } from 'react';
import '../css/Itinerary.css';
import { limitStr } from '../utils/limitStr';

export default function Itinerary({ doc, handleEditItinerary, handleDeleteItinerary }) {

  return (
    <tr className="row_table_itinerary">
      <td className="title_table_itinerary">{doc.data().titulo}</td>
      <td className="desc_table_itinerary">{limitStr(doc.data()['Short Descr'])}</td>
      <td className="column_min_table_itinerary">{doc.data().Active ? 'Ativo' : 'Inativo'}</td>
      <td className="column_edit_table_itinerary">
        <div className='div_mod_itinerary'>
          <button className='btn_mod_adm' onClick={() => handleEditItinerary(doc.data())}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDeleteItinerary(doc)}>Excluir</button>
        </div>
      </td>
    </tr>

  );
}
