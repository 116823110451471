import React, { useEffect, useState } from 'react';
import firebase from '../config/Firebase';
import Loading from './Loading';
import VerifyPopup from './VerifyPopup';
import '../css/EditItinerary.css';

// Components Imports
import TitleEditor from './FormComponents/TitleEditor';
import HeaderForm from './FormComponents/HeaderForm';
import ButtonForm from './FormComponents/ButtonForm';
import { fieldsEditItinerary } from './FieldsForm/itinerary.fields';

import { inputsComponents } from '../utils/formManager';

export default function EditItinerary({ setEditPopup, doc, action, userCity, pendency, objectPend, setObjectPend, setNumPendency, role, routeID }) {
  const [popup, setPopup] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [item, setItem] = useState(handleItem());

  useEffect(() => {
    setItem(handleItem(doc));
  }, [doc]);

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;
  
    return doc ? doc : {
      docId: '',
      titulo: '',
      idRota: routeID,
      'Short Descr': '',
      descrHistorico:'',
      descrPercurso:'',
      descrConteudo:'',
      descrDestaques:'',
      Active: true,
      pontosRota: [],
      pontosRotaDuracao: [],
      pontosApoio: [],
      guiasAgencias: [],
      waypoint: [],
      waypointTitulo: [],
      original: {
        'Short Descr': '',
        descrHistorico:'',
        descrPercurso:'',
        descrConteudo:'',
        descrDestaques:'',
      },
    };
  }

  const handleEditItinerary = async () => {
    try {
      const pendency = {
        action: 'Editar',
        createDate: new Date(),
        city: item.City,
        currDoc: item,
        lastDoc: doc,
        requestor: (await getUserInfo()).name,
        type: 'RoteirosTrechos',
        emailRequester: (await getUserInfo()).email,
        docId: doc.docId,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      allImages.map(async (img) => {
        if (img.image !== '')
          await firebase.storage().ref(`cities/${item.City}/itinerarys/${img.name}`).put(img.image);
      });
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  const handleAddItinerary = async () => {
    try {
      const pendency = {
        action: 'Criar',
        city: userCity,
        createDate: new Date(),
        currDoc: item,
        lastDoc: {},
        requestor: (await getUserInfo()).name,
        type: 'RoteirosTrechos',
        emailRequester: (await getUserInfo()).email,
        docId: '',
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  useEffect(() => {
    if (action === 'Criar' && !objectPend)
      setItem({ ...item, City: userCity });
  }, [userCity]);

  function handleCheckFields() {
    const keys = ['titulo', 'Short Descr'];
    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0) || item[keys[i]] === null || !item[keys[i]]) {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }

    if (!item.pontosRota || item.pontosRota.length < 2) {
      document.getElementById('pontosRotaForm').focus();
      return;
    }

    if (!item.pontosRotaDuracao.every((duration) => duration !== '')) {
      document.getElementById('pontosRotaDuracaoForm').focus();
      return;
    }


    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  async function handleEditPendency() {
    const newPendency = { ...objectPend, currDoc: item };
    setObjectPend(newPendency);
    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  if (!userCity) {
    return <Loading />;
  }

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'RoteirosTrechos'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditItinerary.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: action === 'Criar' && !objectPend ? userCity : item.City, allImages: allImages, setAllImages: setAllImages, objectPend: objectPend, typeDocument: 'itinerarys', action: action === 'Criar', role: role, actionType: action, userRole: role });
              })
            }
            { popup ? <VerifyPopup title={'Salvar Alterações'} message={'Tem certeza que gostaria de salvar as alterações dessa rota?'} setVerifyPopup={setPopup} action={action} handleAction={doc ? handleEditItinerary : handleAddItinerary} type={'Trecho'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}