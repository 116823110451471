import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import '../../css/InventoryAdmin.css';
import RoutesList from '../../component/RoutesList';
import Loading from '../../component/Loading';
import EditRoutes from '../../component/EditRoutes';
import EditItinerary from '../../component/EditItinerary';
import VerifyPopup from '../../component/VerifyPopup';
import NoResultsComponent from '../../component/NoResultsComponent';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';
import { RodapeAdmin } from '../../component/RodapeAdmin';
import ListItinerary from '../../component/ListItinerary';

export default function RoutesAdmin() {
  const [places, setPlaces] = useState();
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [editPopup, setEditPopup] = useState('None');
  const [selectedItem, setSelectedItem] = useState();
  const [item, setItem] = useState();
  const [filter, setFilter] = useState('Title');
  const [search, setSearch] = useState('');
  const [action, setAction] = useState('Editar');
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [filteredPlaces, setFilteredPlaces] = useState();
  const [routeID, setRouteID] = useState();
  const [mode, setMode] = useState();
  const { city, cityAdmin, role, setNumPendency } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'Title' : filter;
    setIsLoadingCities(true);

    const docs = firebase.firestore()
      .collection('Roteiros')
      .where('City', '==', city);
    //.orderBy(currFilter);
    
    setIsLoadingCities(false);
    return docs;
  };

  const getFirstPageRoutes = async () => {
    if (city) {
      const newDocs = await buildQuery().get();
      setFilteredPlaces(newDocs);
      setPlaces(newDocs);
    }
  };

  const handleChange = () => {
    getFirstPageRoutes();
  };

  const searchRouteText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().Title.toLowerCase().includes(search.toLowerCase());
    const isInDescription = doc.data().Description?.toLowerCase().includes(search.toLowerCase());

    return isInTitle || isInDescription;
  };
  
  useEffect(() => {
    getFirstPageRoutes();
  }, [city, editPopup]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPageRoutes();
    }

    window.scrollTo(0, 0);
  }, [editPopup]);

  if (!places || isLoadingCities || !filteredPlaces) {
    return <p><Loading /></p>; 
  }

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchRouteText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  const handleDeleteRoute = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Roteiros',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      getFirstPageRoutes();
    } catch (error) {
      setVerifyPopup(false);
    }
  };

  const handleDeleteIt = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'RoteirosTrechos',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      //getFirstPageRoutes();
    } catch (error) {
      setVerifyPopup(false);
      console.log(error);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup('Routes');
  };

  const handleEditItinerary = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup('Itinerary');
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
    setMode('Route');
  };

  const handleDeleteItinerary = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
    setMode('Itinerary');
  };

  const handleListItinerary = (doc) => {
    setItem(doc);
    setAction('List');
    setMode('Itinerary');
    setEditPopup('ListItinerary');
  };

  return (
<div className='InventarioAdmin'>
  {
    editPopup === 'ListItinerary' &&
    <ListItinerary
      setEditPopup={setEditPopup}
      doc={item}
      action={action}
      setAction={setAction}
      role={role}
      cityAdmin={cityAdmin}
      userCity={city}
      setNumPendency={setNumPendency}
      handleEditItinerary={handleEditItinerary}
      handleDeleteItinerary={handleDeleteItinerary}
      verifyPopup = {verifyPopup}
      setVerifyPopup = {setVerifyPopup}
      mode = {mode}
      handleDeleteIt = {handleDeleteIt}
      selectedItem = {selectedItem}
      setRouteID={setRouteID}
      search={search}
    />
  }
  { 
    editPopup === 'Routes' && 
      <EditRoutes 
        setEditPopup={setEditPopup} 
        doc={selectedItem} 
        action={action} 
        role={role} 
        cityAdmin={cityAdmin} 
        userCity={city} 
        setNumPendency={setNumPendency}
      />
  }
  { 
    editPopup === 'Itinerary' && 
      <EditItinerary 
        setEditPopup={setEditPopup} 
        doc={selectedItem} 
        action={action} 
        role={role} 
        cityAdmin={cityAdmin} 
        userCity={city} 
        setNumPendency={setNumPendency}
        routeID={routeID}
      />
  }
  { 
    !(editPopup === 'Routes' || editPopup === 'Itinerary' || editPopup === 'ListItinerary') && 
      <div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Rotas</h1>
        </div>
        <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup('Routes'); }}>Criar Nova Rota</p>
        </div>
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
          <div className='div_table_inventory_adm'>
            <table className='table_inventory_adm'>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Title')}>Titulo</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Description')}>Descrição Curta</td>
                <td className='column_table_inventory th_table_admin'>Tipo</td>
                <td className='column_table_inventory th_table_admin'>Qtde Trechos/Ramal</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('City')}>Cidade</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Active')}>Status</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
              {
                filteredPlaces.docs.map((doc) => {
                  return <RoutesList doc={doc} handleEdit={handleEdit} handleEditItinerary={handleEditItinerary} handleDelete={handleDelete} setAction={setAction} setEditPopup={setEditPopup} setRouteID={setRouteID} handleDeleteItinerary={handleDeleteItinerary}
                    handleListItinerary={handleListItinerary} handleChange={handleChange}/>;
                })
              }
            </table>
          </div>
        ) : 
          <NoResultsComponent title={'Roteiros'} search={search} />
        }
        { (verifyPopup && mode === 'Route') && 
          <VerifyPopup title={'Excluir Rota'} message={'Tem certeza que gostaria de excluir essa rota?'} setVerifyPopup={setVerifyPopup} handleAction={handleDeleteRoute} type={'Roteiro'} doc={selectedItem} />
        }  
      </div>
  }
</div>
  );
}
