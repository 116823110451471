import React, { useEffect, useState } from 'react';
import Map from './Map';

// Components Imports
import CalendarPopup from '../CalendarPopup';

// CSS import
import '../../assets/css/Dashboard/mapCardHeatmap.css';
import SpinButton from '../SpinButton';

export function CardHeatmap({ location, date, setDate, attractionsCord, qtdInit, city, qtdUsers }) {
  const [datePopup, setDatePopup] = useState(false);
  const [dateType, setDateType] = useState();
  const [loading, setLoading] = useState(true);

  function handleCalendar(type) {
    setDateType(type);
    setDatePopup(true);
  }

  function handleTextDate(date) {
    const convert = new Date(date);

    let text = '';

    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    text = text + (convert.getDate()) + ' de ';
    text = text + months[convert.getMonth()] + ' de ';
    text = text + convert.getFullYear() + '';

    return text;
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [6000]);
  }, [city]);

  return (
    <div className='CardHeatmap card_container_dash'>
      <div className='div_headear_carddashboard'>
        <h1>Mapa de Calor</h1>
      </div>
      <div className='div_map_cardmap'>
        { loading ? <SpinButton /> : <Map data={attractionsCord} center={{ lat: location[0], lng: location[1] }} /> }
      </div>
      {
        datePopup ? <CalendarPopup type={dateType} date={date} setDate={setDate} setPopup={setDatePopup}/> : null
      }
      <div className='div_align_stats_map'>
        <div className='div_map_buttons'>
          <div className='div_btn_align_map'>
            <button type='button' onClick={() => handleCalendar('initialDate')} className='btn_setdate_map'>Data Inicial</button>
            <p>{ handleTextDate(date.initialDate) }</p>
          </div>
          <div className='div_btn_align_map'>
            <button type='button' onClick={() => handleCalendar('finalDate')} className='btn_setdate_map'>Data Final</button>
            <p>{ handleTextDate(date.finalDate) }</p>
          </div>
        </div>
        <div className='div_align_info_map'>
          <div className='div_span_align_map'>
            <span>Nº de Usuários: { qtdUsers }</span>
          </div>
          <div className='div_span_align_map'>
            <span>Nº de Aberturas: { qtdInit }</span>
          </div>
        </div>
      </div>
    </div>
  );
}