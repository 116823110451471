import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import Switch from '@material-ui/core/Switch';
import { StyleSheet, css } from 'aphrodite';
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import Hospedagens from '../assets/imgs/pins/pin_hospedagens.png';
import Compras from '../assets/imgs/pins/pin_compras.png';
import Atrativos from '../assets/imgs/pins/pin_atrativos.png';
import Alimentacao from '../assets/imgs/pins/pin_restaurantes.png';
import infraEstrutura from '../assets/imgs/pins/pin_infra.png';
import servicos from '../assets/imgs/pins/pin_servicos.png'; /* Outros pq nao tem pin para Infra-estrutura nem Eventos */
import { store } from '../redux/store';
import { connect } from 'react-redux';
import { FILTER_PINS, FILTER_CHECKBOX, TOGGLE_FILTER } from '../redux/actionTypes';
import Header from './Header';
import UserMenu from './UserMenu';
import Slide from '@material-ui/core/Slide';
import { Event } from '../config/TrackingGA';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { centerMap, setMapZoom, setLoading, filterPins, openEventDrawer } from '../redux/actions';
import { convertDate } from '../utils/parse';
import queryString from 'query-string';

const switchStyle = (theme) => ({
  colorSwitchBase: {
    color: 'white',
    '&$colorChecked': {
      color: cyan['A400'],
      '& + $colorBar': {
        backgroundColor: cyan['A200'],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

const backgroundColor = (index) => {
  if (index % 6 === 0 || index % 6 === 2) return '#C700DF';
  if (index % 6 === 1 || index % 6 === 4) return '#666666';
  if (index % 6 === 3 || index % 6 === 5) return '#4B69C3';
};

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyFavorites: false,
      width: window.innerWidth,
      activeTab: '1',
      opacity1: '',
      opacity2: '.5',
      city: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFavoriteImageClick = this.onFavoriteImageClick.bind(this);
    this.applyButtonEvent = this.applyButtonEvent.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.renderEventsPins = this.renderEventsPins.bind(this);
  }

  // Holds the filter state
  _filters = this.props.filters

  done = false;

  componentDidMount() {
    const { search } = this.props.location;
    const parsedUrl = queryString.parse(search);
    if (parsedUrl.events === 'true')
      this.setState({ activeTab: '2' });

    // Filters markers acording to initial filter state in redux store (important when passing filter parameters through the url)
    this.filterMarkers();
  }

  componentDidUpdate() {
    const { search } = this.props.location;
    const parsedUrl = queryString.parse(search);
    if (this.props.events.length > 0 && !this.done && parsedUrl.events === 'true') {
      this.toggleTab('2');
      this.done = true;
    }
  }

  handleChange = (name) => (event) => {
    this._filters[name] = event.target.checked;
    this.forceUpdate(); // Forces component to re-render with new this._filters values
  };

  onFavoriteImageClick() {
    this.setState({ onlyFavorites: !this.state.onlyFavorites });
  }

  async applyButtonEvent() {
    await this.filterMarkers();
    // If is on a mobile device, drawer goes down when clicking on the apply button
    if (this.props.isMobile)
      store.dispatch({ type: TOGGLE_FILTER });

    Event('User', 'FilterMarkers', 'FiltroDeAtrativos');
  }

  cancelButtonEvent = () => {
    store.dispatch({ type: TOGGLE_FILTER });
  }

  async filterMarkers() {
    await this.props.setLoading(true);
    // Vetor que guarda quais tipos de atrativos devem passar pelo filtro
    let mask = [];
    const { filterMask } = this.props;

    if (this._filters.alimentacao)
      mask = mask.concat(filterMask.alimentacao);
    if (this._filters.hospedagem)
      mask = mask.concat(filterMask.hospedagem);
    if (this._filters.atrativos)
      mask = mask.concat(filterMask.atrativos);
    if (this._filters.compras)
      mask = mask.concat(filterMask.compras);
    if (this._filters.infraEstrutura)
      mask = mask.concat(filterMask.infraEstrutura);
    if (this._filters.servicos)
      mask = mask.concat(filterMask.servicos);

    // Filtra os pontos de acordo com a mascara
    const filtered = this.props.points.filter((e) => mask.includes(e.Type));

    const aux = {
      alimentacao: this._filters.alimentacao,
      hospedagem: this._filters.hospedagem,
      atrativos: this._filters.atrativos,
      compras: this._filters.compras,
      infraEstrutura: this._filters.infraEstrutura,
      servicos: this._filters.servicos,
    };
    store.dispatch({ type: FILTER_CHECKBOX, options: aux });

    store.dispatch({ type: FILTER_PINS, pins: filtered });
    this.props.setLoading(false);
  }

  // Função que altera o estado do menu do usuário
  toggleUserMenu = () => {
    this.setState({
      userMenuStatus: !this.state.userMenuStatus,
    });
  }

  renderFilter = () => {
    const { classes, showFavorites } = this.props;
    const switchSettings = {
      switchBase: classes.colorSwitchBase,
      checked: classes.colorChecked,
      bar: classes.colorBar,
    };
    const heartImageSettings = {
      style: {
        verticalAlign: 'middle',
        color: '#C6C6C6',
        cursor: 'pointer',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '0.5rem',
      },
      size: 20,
    };

    return (
      <div id='container' className={css(styles.container)}>
        <div className={css(styles.infos)}>
          {
            this.props.isMobile &&
            <Row style={{ width: this.props.width, marginBottom: 10 }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Header isMobile={this.state.width <= 500} toggleUserMenu={this.toggleUserMenu} />
                {
                  this.state.userMenuStatus &&
                  <Slide element={document.getElementById('user-menu')} in={this.state.userMenuStatus} direction='down' timeout={200}>
                    <UserMenu id='user-menu' />
                  </Slide>
                }
              </Col>
            </Row>
          }
          <div>
            <p className={css(styles.title)}>Você pode filtrar sua pesquisa por <br /><b>tipo de atrativo</b></p>
            {/* Textos iniciais */}
            { showFavorites && // Favorites feature flag
              <Row className={css(styles.textosIniciais)}>
                <Col>
                  <span style={{ fontSize: '1.1rem' }} >Listar apenas favoritos </span>
                  <IconContext.Provider value={heartImageSettings}>
                    {
                      this.state.onlyFavorites === true ? <FaHeart onClick={this.onFavoriteImageClick} /> : <FaRegHeart onClick={this.onFavoriteImageClick} />
                    }
                  </IconContext.Provider>
                </Col>
              </Row>
            }
            {/* Alimentacao */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={Alimentacao} />
              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Alimentação</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.alimentacao}
                  onChange={this.handleChange('alimentacao')}
                  value={'alimentacao'}
                  classes={switchSettings}
                />
              </Col>
              <div className={css(styles.border)}></div>
            </Row>

            {/* Atrativos */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={Atrativos} />

              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Atrativos</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.atrativos}
                  onChange={this.handleChange('atrativos')}
                  value={'atrativos'}
                  classes={switchSettings}
                />
              </Col>
              <div className={css(styles.border)}></div>
            </Row>

            {/* Compras */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={Compras} />

              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Compras</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.compras}
                  onChange={this.handleChange('compras')}
                  value={'compras'}
                  classes={switchSettings}
                />
              </Col>
              <div className={css(styles.border)}></div>
            </Row>

            {/* Hospedagem */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={Hospedagens} />

              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Hospedagem</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.hospedagem}
                  onChange={this.handleChange('hospedagem')}
                  value={'hospedagem'}
                  classes={switchSettings}
                />
              </Col>
              <div className={css(styles.border)}></div>
            </Row>

            {/* Infra-estrutura */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={infraEstrutura} />

              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Infraestrutura</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.infraEstrutura}
                  onChange={this.handleChange('infraEstrutura')}
                  value={'infraEstrutura'}
                  classes={switchSettings}
                />
              </Col>
              <div className={css(styles.border)}></div>
            </Row>
            {/* Servicos */}
            <Row className={css(styles.item)}>
              <Col style={{ paddingLeft: 0 }} lg='3' xs='3' sm='3'>
                <img alt='' className={css(styles.pin)} src={servicos} />
              </Col>
              <Col lg='6' xs='6' sm='6' className={css(styles.textCol)}>
                <p className={css(styles.text)} >Serviços</p>
              </Col>
              <Col lg='3' xs='3' sm='3'>
                <Switch
                  checked={this._filters.servicos}
                  onChange={this.handleChange('servicos')}
                  value={'servicos'}
                  classes={switchSettings}
                />
              </Col>
            </Row>
            {/* Botões */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '30px' }}>
              <button className={css(styles.button, styles.aplicarButton)} onClick={this.applyButtonEvent} >Aplicar</button>
              <button className={css(styles.button, styles.cancelarButton)} onClick={this.cancelButtonEvent} >Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (tab === '1') {
      this.setState({
        opacity1: '',
        opacity2: '.5',
      });
      this.applyButtonEvent();
    } else {
      this.renderEventsPins();
      this.setState({
        opacity1: '.5',
        opacity2: '',
      });
    }
  }

  async handleCardClick(card) {
    await this.props.openEventDrawer(card);
    await this.props.centerMap({
      lat: card.attractive.lat,
      long: card.attractive.long,
    });
    this.props.setMapZoom(18);
  }

  renderCards = (card, index) => {
    return (
      <div key={index} className={css(styles.card)} style={{ backgroundColor: backgroundColor(index) }} onClick={() => this.handleCardClick(card)}>
        <div><span className={css(styles.cardTitle)}>{card.title}</span></div>
        <div className={css(styles.cardPinkLine)} />
        <div className={css(styles.cardInfo)}>
          <p>{card.description}</p>
          <p>Data: <b>{convertDate(card.startDate)}</b></p>
        </div>
      </div>
    );
  }

  renderEventsPins() {
    const pins = [...new Set(this.props.events.map((event) => event.attractive))];
    this.props.filterPins(pins);
    this.props.setLoading(false);
  }

  render() {
    const pageCss = {
      width: this.props.isMobile ? this.props.width : '22rem',
      height: this.props.isMobile && window.innerHeight,
    };

    if (!this.props.isMobile) {
      return (
        <div style={{ ...pageCss }} className={css(styles.page)}>
          <div className={css(styles.tabs)}>
            <div>
              <p className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: '1rem', opacity: this.state.opacity1, fontSize: 30 }}>
                Filtros
            </p>
            </div>
            <p style={{ fontSize: 30 }}>|</p>
            <div>
              <p
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={async () => {
                  await this.props.setLoading(true);
                  this.toggleTab('2');
                }}
                style={{ cursor: 'pointer', marginLeft: '1rem', opacity: this.state.opacity2, fontSize: 30 }}
              >
                Eventos
            </p>
            </div>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {this.renderFilter()}
            </TabPane>
            <TabPane tabId="2" >
              <Grid className={css(styles.eventCards)}>
                { this.props.events.length === 0 ?
                  <p style={{ marginTop: '60%' }}>Infelizmente não há eventos programados para os próximos dias</p>
                  :
                  <div>
                    <p className={css(styles.title)}>Veja abaixo todos os eventos da cidade</p>
                    {
                      this.props.events.map((card, i) => this.renderCards(card, i))
                    }
                  </div>
                }
              </Grid>
            </TabPane>
          </TabContent>
        </div>
      );
    }
    return (
      <div style={{ ...pageCss }} className={css(styles.page)}>
        {this.renderFilter()}
      </div>
    );
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  eventCards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    height: '100%',
  },
  card: {
    cursor: 'pointer',
    color: 'white',
    borderRadius: '20px',
    height: 'auto',
    marginLeft: '1.7rem',
    marginRight: '1.7rem',
    marginBottom: '1.5rem',
    padding: '1rem',
  },
  cardInfo: {
    height: 'auto',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  cardPinkLine: {
    borderBottomColor: '#db3d78',
    borderBottomStyle: 'solid',
    borderBottomWidth: '5px',
    marginTop: '0.4rem',
    marginBottom: '0.4rem',
    width: '2rem',
    marginLeft: '-0.4rem',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',

  },
  aplicarButton: {
    borderRadius: '25px',
    backgroundColor: '#db3d78',
    marginRight: '20px',
  },
  border: {
    borderBottomColor: '#8e8d8d',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    width: '100%',
    paddingTop: '1.0vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: '0.6',
  },
  bottomLine: {
    marginLeft: '5rem',
    width: '9.5rem',
  },
  button: {
    color: 'white',
    padding: '0.5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    border: 'none',
    cursor: 'pointer',
    width: '50%',
  },
  cancelarButton: {
    borderRadius: '25px',
    backgroundColor: '#8e8d8d',
  },
  container: {
    color: '#8e8d8d',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    overflowy: 'scroll',
  },
  infos: {
    maxWidth: '95%',
    height: 'auto',
    margin: 'auto',
    paddingTop: '0',
  },
  item: {
    width: '90%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '1.0vh',
    alignItems: 'center',
  },
  page: {
    '@media (max-width: 500px)': {
      marginTop: '0px',
    },
    marginTop: '16%',
    marginBottom: '16%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 'none',
  },
  pin: {
    width: '3rem',
    height: '3rem',
    padding: 0,
  },
  text: {
    margin: 0,
  },
  textCol: {
    padding: 0,
    textAlign: 'center',
    // marginLeft: '1rem',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  textAndSwitchRow: {
    marginLeft: '0.1rem',
    borderBottomColor: '#8e8d8d',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    width: '12.7rem !important',
  },
  textosIniciais: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  title: {
    fontSize: '0.9rem',
    textAlign: 'center',
    color: 'rgb(142, 141, 141)',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginTop: '0%',
    color: '#8e8d8d',
    fontSize: '2.2rem',
    textAlign: 'center',
  },
});

function mapStateToProps(state) {
  return {
    filters: state.filterInfo.filters,
    events: state.events,
    showFavorites: state.flags.showFavorites,
    showRating: state.flags.showRating,
    filterMask: state.filterInfo.filterMask,
  };
}
const mapDispatchToProps = (dispatch) => ({
  centerMap: (location) => { dispatch(centerMap(location)); },
  setMapZoom: (zoom) => { dispatch(setMapZoom(zoom)); },
  setLoading: (loading) => { dispatch(setLoading(loading)); },
  filterPins: (pins) => { dispatch(filterPins(pins)); },
  openEventDrawer: (event) => { dispatch(openEventDrawer(event)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(switchStyle)(Filters));
