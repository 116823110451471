import React from 'react';

// CSS import
import '../../assets/css/atractionList.css';

// Components imports
import { limitStr } from '../../utils/limitStr';

export default function AtractionList({ doc, handleEdit, handleDelete }) {
  return (
    <tr className="row_table_inventory body_table"> 
      <td className="title_table_inventory">{doc.data().Title}</td>
      <td className="desc_table_inventory">{limitStr(doc.data()['Short Description'])}</td>
      <td className="column_min_table_inventory">{doc.data().City}</td>
      <td className="column_min_table_inventory">{doc.data().Type}</td>
      <td className="column_min_table_inventory">{doc.data().Subtype}</td>
      <td className="column_min_table_inventory">{doc.data().Visible ? 'Sim' : 'Não'}</td>
      <td className="column_min_table_inventory">{doc.data().Active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Excluir</button>
          <a href={'/map/Attractions/' + doc.id} target="_blank"><button className='btn_mod_adm'>Ver registro</button></a>
        </div>
      </td>
    </tr>
  );
}