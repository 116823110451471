import React, { useEffect, useState, useReducer } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/selectFiltered.css';
import { duration } from '@material-ui/core';

export default function SelectFiltered({ title, field, value, setValue, explanation }) {
  const [pontosApoio, setPontosApoio] = useState([]);
  const [selectedPontosApoio, setSelectedPontosApoio] = useState([]);
  const [selectedRota, setSelectedRota] = useState();
  const [tipos, setTipos] = useState([]);
  const [filtersCity, setFiltersCity] = useState('');
  const [filtersType, setFiltersType] = useState('');
  const [filtersSubtype, setFiltersSubtype] = useState('');
  const [filtersSpecialty, setFiltersSpecialty] = useState('');
  const [cities, setCities] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [filteredPontosApoio, setFilteredPontosApoio] = useState([]);

  // console.log(value)
  // console.log(selectedPontosApoio)
  // console.log(durationList)
  useEffect(() => {
    // Check if value[field] is not empty
    if (value[field].length > 0) {
      // Create a reference to the 'AttractionsApp' collection
      const attractionsRef = firebase.firestore().collection('Attractions');

      // Fetch documents that match the IDs in value[field]
      const fetchSelectedAttractions = async () => {
        const attractionPromises = value[field].map(async (attractionId) => {
          const attractionDoc = await attractionsRef.doc(attractionId).get();
          const attractionData = attractionDoc.data();
          return { id: attractionId, ...attractionData };
        });

        // Wait for all promises to resolve
        const selectedAttractions = await Promise.all(attractionPromises);
        
        // Set selectedPontosApoio to the fetched data
        setSelectedPontosApoio(selectedAttractions);
      };

      fetchSelectedAttractions();
    } else {
      // If value[field] is empty, initialize selectedPontosApoio as an empty array
      setSelectedPontosApoio([]);
    }
  }, []);

  useEffect(() => {
    if (value['pontosRotaDuracao'].length > 0) {
      setDurationList(value['pontosRotaDuracao']);
    }
  }, []); 
  
  useEffect(() => {
    const db = firebase.firestore();
    db.collection('Roteiros')
    .doc(value.idRota)
    .get()
    .then((roteiroDoc) => {
      if (roteiroDoc.exists) {
        // Get the "Cities" array from the "Roteiros" document
        const citiesArray = roteiroDoc.data().Cities || [];

        // Now, query the "AttractionsApp" collection based on the "City" field
        db.collection('Attractions')
          .where('Title', '>', '')
          .where('City', 'in', citiesArray) // Use the "citiesArray" here
          .get()
          .then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setPontosApoio(data);
          })
          .catch((error) => console.log(error));
      } else {
        console.log("Roteiros document not found.");
      }
    })
    .catch((error) => console.log(error));


    db.collection('Type')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTipos(data);
      })
      .catch((error) => console.log(error));

    // db.collection('Attractions')
    //   .get()
    //   .then((snapshot) => {
    //     const specialtiesSet = new Set();
    
    //     snapshot.docs.forEach((doc) => {
    //       const specialty = doc.data().Especialidade;
    
    //       if (specialty) {
    //         specialtiesSet.add(specialty);
    //       }
    //     });
    
    //     const data = Array.from(specialtiesSet).map((specialty, index) => ({ id: index, name: specialty }));
    //     setSpecialties(data);
    //   })
    //   .catch((error) => console.log(error));

      db.collection('Roteiros')
      .doc(value.idRota)
      .get()
      .then((roteiroDoc) => {
        if (roteiroDoc.exists) {
          // Get the "Cities" array from the "Roteiros" document
          const citiesArray = roteiroDoc.data().Cities || [];
  
          // Now, query the "AttractionsApp" collection based on the "City" field
          db.collection('Attractions')
            .where('Title', '>', '')
            .where('City', 'in', citiesArray) // Use the "citiesArray" here
            .get()
            .then((snapshot) => {
              const specialtiesSet = new Set();
    
              snapshot.docs.forEach((doc) => {
                const specialty = doc.data().Especialidade;
          
                if (specialty) {
                  specialtiesSet.add(specialty);
                }
              });
          
              const data = Array.from(specialtiesSet).map((specialty, index) => ({ id: index, name: specialty }));
              setSpecialties(data);
            })
            .catch((error) => console.log(error));
        } else {
          console.log("Roteiros document not found.");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setCities([...new Set(pontosApoio.map((attraction) => attraction.City))]);
    setSubtypes([...new Set(filtersType ? [] : filtersType === '' ? filtersType.Subtype : [])]);
  }, [pontosApoio]);

  useEffect(() => {
    if (filtersType) {
      setSubtypes(tipos.find((doc) => doc.name === filtersType).Subtype);
    } else if (filtersType === '') {
      setSubtypes([...new Set('')]);
    }
  }, [filtersType]);

  useEffect(() => {
    setFilteredPontosApoio(pontosApoio.filter((ponto) => {
      if (selectedPontosApoio.some((item) => item.id === ponto.id)) {
        return false; // Filter out items present in selectedPontosApoio
      }
      if (filtersCity && ponto.City !== filtersCity) {
        return false;
      }
      if (filtersType && ponto.Type !== filtersType) {
        return false;
      }
      if (filtersSubtype && ponto.Subtype !== filtersSubtype) {
        return false;
      }
      if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty) {
        return false;
      }
      return true;
    }));
  }, [selectedPontosApoio, pontosApoio, filtersCity, filtersSpecialty, filtersSubtype, filtersType]);
  
  useEffect(() => {
    setValue({
              ...value, 
              pontosRota: [...new Set(selectedPontosApoio.map((attraction) => attraction.id))], 
              pontosRotaDuracao: durationList}); 
  }, [selectedPontosApoio, durationList]);

  const handleAttractionChange = (e) => {
    const selectedAttractionTitle = e.target.value;
    const selectedAttraction = filteredPontosApoio.find((attraction) => attraction.Title === selectedAttractionTitle);
    setSelectedRota(selectedAttraction);
  };

  function handleAttractionAdd() {
    if (!selectedRota) return;
    setSelectedPontosApoio((prevAttractions) => [...prevAttractions, selectedRota]);
    setSelectedRota();
    setDurationList((prev) => [...prev, '']);
  }

  function handleAttractionRemove(index) {
    const list = [...selectedPontosApoio];
    list.splice(index, 1);
    setSelectedPontosApoio(list);
    const dList = [...durationList];
    dList.splice(index, 1);
    setDurationList(dList);
  }

  const handleDurationChange = (e, index) => {
    const list = [...durationList];
    list[index] = e.target.value;
    setDurationList(list);
  };

  if (!pontosApoio) {
    return <Loading />;
  }

  return (
    <div className="inputFilters">
      <div className="div_label_input_textarea">
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className="div_align_explanation_input_do_nelli">
        <div className="filter-dropdown">
          <select
            value={filtersCity}
            onChange={(e) => {
              const city = e.target.value;
              setFiltersCity(city);
            }}
          >
            <option value="">Todas cidades</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <select
            value={filtersType}
            onChange={(e) => {
              const type = e.target.value;
              setFiltersType(type);
            }}
          >
            <option value="">Todos tipos</option>
            {tipos.map((val) => (
              <option key={val.id} value={val.name}>
                {val.titulo}
              </option>
            ))}
          </select>
          <select
            value={filtersSubtype}
            onChange={(e) => {
              const subtype = e.target.value;
              setFiltersSubtype(subtype);
            }}
          >
            <option value="">Todos subtipos</option>
            {subtypes.map((subtype) => (
              <option key={subtype} value={subtype}>
                {subtype}
              </option>
            ))}
          </select>
          <select
            value={filtersSpecialty}
            onChange={(e) => {
              const specialty = e.target.value;
              setFiltersSpecialty(specialty);
            }}
          >
            <option value="">Todas especialidades</option>
            {specialties.map((specialty) => (
              <option key={specialty.id} value={specialty.name}>
                {specialty.name}
              </option>
            ))}
          </select>
        </div>
        <div className="div_align_multiple_select_do_nelli">
          <select className="select_inventory_do_nelli" onClick={(e) => handleAttractionChange(e)} multiple>
            {filteredPontosApoio.map((attraction, index) => {
              if (!selectedPontosApoio.includes(attraction.id))
                return <option key={'SelectMultiple' + field + index}>{attraction.Title}</option>;
            })}
          </select>
        </div>
        <div className="div_explanation_input">
          <p className="subtext_explanation_input">{explanation}</p>
        </div>
        <button type='button' id={field + 'Form'} className="add_duration_btn" onClick={() => handleAttractionAdd()}>Adicionar</button>
        <div className="div_align_cabecalho">
          <strong className="alignPonto">Ponto</strong>
          <strong className="alignDuracao">Duração</strong>
        </div>
          {selectedPontosApoio.map((ponto, index) => (
          <div key={index} className="div_align_input_duration">
            <strong className="align_title_input">
              {ponto.Title}
            </strong>
            <input name="pontoRota" className="align_input" type="text" id="pontosRotaDuracaoForm" value={durationList[index]} onChange={(e) => handleDurationChange(e, index)}/>
            <button type="button" className="remove_duration_btn" onClick={() => handleAttractionRemove(index)}>
              Remover
            </button>
          </div>
          ))  
          }
      </div>
    </div>
  );
}
