import { ListAltTwoTone } from '@material-ui/icons';
import firebase from '../config/Firebase';

import { handleAttractionScores, handleCityScore, handleComturScore, handleDemandScore } from './rankingManager';

export async function handleSetDoc(pendency, message, avaliation, setNumPendency, setEditPopup, type, finalIndex) {
  const action = pendency.data().action;

  if (avaliation) {
    const ref = pendency.data().type || pendency.data().tipo;
    // Unset buttons
    if (type !== 'Lote') {
      document.getElementById('btn_send_pendency').disabled = true;
      document.getElementById('btn_back_pendency').disabled = true;
    }
    
    let item = pendency.data().currDoc;
    let id = pendency.data().docId;

    if (ref === 'Attractions' && item.imagefile && item.imagefile.length > 0) {
      const vectorUrl = [];
      for (let index = 0; index < item.imagefile.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        const url = await firebase.storage().ref(`cities/${item.City}/${item.imagefile[index]}`).getDownloadURL();
        vectorUrl.push(url);
      }
      item = { ...item, fotoCapa: vectorUrl[0], imagefileURL: vectorUrl };
    } else if (ref === 'Events' && item.imagefile && item.imagefile.length > 0) {
      const vectorUrl = [];
      for (let index = 0; index < item.imagefile.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        const url = await firebase.storage().ref(`cities/${item.City}/events/${item.imagefile[index]}`).getDownloadURL();
        vectorUrl.push(url);
      }

      item = { ...item, imagefileURL: vectorUrl, fotoCapa: vectorUrl[0] || null };
    }

    if (action === 'Criar') {
      if (ref === 'RoteirosTrechos') {
        try {
          const docRef = await firebase.firestore().collection(ref).add(pendency.data().currDoc);
          
          const roteirosDoc = await firebase.firestore().collection('Roteiros').doc(pendency.data().currDoc.idRota).get();
        
          await firebase.firestore().runTransaction(async (transaction) => {
            const roteirosData = await transaction.get(roteirosDoc.ref);
            const roteirosArray = roteirosData.data().Roteiros || [];
            roteirosArray.push(docRef.id);
            transaction.update(roteirosDoc.ref, { Roteiros: roteirosArray });
          });
        
          const pontosRota = pendency.data().currDoc.pontosRota || [];
          const pontosApoio = pendency.data().currDoc.pontosApoio || [];
          const guiasAgencias = pendency.data().currDoc.guiasAgencias || [];
        
          const attractionsDocs = await firebase.firestore().collection('Attractions').where('__name__', 'in', pontosRota.concat(pontosApoio, guiasAgencias)).get();
        
          attractionsDocs.forEach(async (attractionDoc) => {
            await firebase.firestore().runTransaction(async (transaction) => {
              const attractionData = await transaction.get(attractionDoc.ref);
              const attractionArray = attractionData.data().Roteiros || [];
              if (attractionArray.indexOf(pendency.data().currDoc.idRota) === -1)
                attractionArray.push(pendency.data().currDoc.idRota);
              transaction.update(attractionDoc.ref, { Roteiros: attractionArray });
            });
          });
        } catch (error) {
          console.error('Error adding new element:', error);
        }
      } else {
        await firebase.firestore().collection(ref).add(item).then((doc) => id = doc.id);
      }
    } else if (action === 'Editar') {
      await firebase.firestore().collection(ref).doc(pendency.data().docId).set(item);
      // await handleDeleteFiles(item, pendency.data().lastDoc, pendency);
    } else {
      await firebase.firestore().collection(ref).doc(pendency.data().docId).update({
        Active: false,
      });
    }

    // Checking and Deleting the images from pendency edit
    // await deleteImagesPendency(pendency.data().deleteFiles, item.City, ref);

    // Setting field 'cidadeAtiva' in all attractions, case ref is 'Cities'
    await handleSetStatusCity(pendency, ref, action);
   
    // Copyign data for AttractionsApp
    await handleSetAttractionsAPP(pendency, ref, action, item, id);

    // Log Action
    await handleLogAction(pendency.data());

    // Updating Ranking
    if (type !== 'Lote' || finalIndex)
      await handleSetRank(pendency, ref);

    // Updating Version
    if (['Cities', 'Attractions', 'Roteiros'].includes(ref)) {
      await firebase.firestore().collection('AppLastVersion').doc('GtoWWUFhout3GqA41CPj').update({
        [ref]: new Date(),
      });
    }

    // Deleting Pendency
    await handleDeleteDoc(pendency);

    setNumPendency((num) => num - 1);
    setEditPopup(false);
  } else if (message !== '') {
    // Unset buttons
    if (type !== 'Lote') {
      document.getElementById('btn_send_pendency').disabled = true;
      document.getElementById('btn_back_pendency').disabled = true;
    }
    // Deleting added files
    if (action === 'Criar' || action === 'Editar')
      await handleDeleteFiles(pendency.data().lastDoc, pendency.data().currDoc, pendency);

    // Sending Email
    await sendEmail(pendency, message);

    // Deleting Pendency
    await handleDeleteDoc(pendency);

    setNumPendency((num) => num - 1);
    setEditPopup(false);
  } else {
    document.getElementById('input_message').focus();
  }
}

async function handleDeleteFiles(lastItem, newItem, pendency) {
  const type = pendency.data().type;

  if (type === 'Attractions') {
    if (!newItem.imagefile) return;
    
    newItem.imagefile.map(async (nameImage) => {
      if (objIsEmpty(lastItem) || !lastItem.imagefile.includes(nameImage)) {
        await firebase.storage().ref(`cities/${newItem.City}/${nameImage}`).delete();
      }
    });
  } else if (type === 'Events') {
    if (!newItem.imagefile) return;

    newItem.imagefile.map(async (nameImage) => {
      if (objIsEmpty(lastItem) || !lastItem.imagefile.includes(nameImage))
        await firebase.storage().ref(`cities/${newItem.City}/events/${nameImage}`).delete();
    });
  } else if (type === 'Cities') {
    // Delete Files
    if (newItem.defaultImage && (!lastItem || lastItem.defaultImage !== newItem.defaultImage)) {
      const refCityImg = firebase.storage().refFromURL(newItem.defaultImage);
      refCityImg.delete();
    }
    if (newItem.defaultEventImage && (!lastItem || lastItem.defaultEventImage !== newItem.defaultEventImage)) {
      const refEventImg = firebase.storage().refFromURL(newItem.defaultEventImage);
      refEventImg.delete();
    }
    if (newItem.KMLFile && (!lastItem || lastItem.KMLFile !== newItem.KMLFile)) {
      const refKmlFile = firebase.storage().refFromURL(newItem.KMLFile);
      refKmlFile.delete();
    }
    if (newItem.ImagemPin && (!lastItem || lastItem.ImagemPin !== newItem.ImagemPin)) {
      const refPinImg = firebase.storage().refFromURL(newItem.ImagemPin);
      refPinImg.delete();
    }
  } else if (type === 'Roteiros') {
    if (!newItem.Fotos) return;

    newItem.Fotos.map(async (nameImage) => {
      if (objIsEmpty(lastItem) || !lastItem.Fotos.includes(nameImage))
        await firebase.storage().ref(`cities/${newItem.City}/routes/${nameImage}`).delete();
    });
  }
}

async function sendEmail(pendency, message) {
  // Send email
  const avaliatorData = await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get();
  const name = avaliatorData.data().name;

  try {
    await fetch('https://us-central1-portaldev-effa3.cloudfunctions.net/statistics/sendMail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: pendency.data().emailRequester,
        message: message,
      }),
    });
  } catch (error) {
    console.log(error);
  }
}

async function handleDeleteDoc(pendency) {
  await firebase.firestore().collection('Pendencies').doc(pendency.id).delete();
}

function objIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

async function handleLogAction(data) {
  const actions = { Criar: 'criar', Editar: 'editar', Excluir: 'excluir' };
  const date = new Date();
  const dataString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

  const types = { Attractions: 'Inventário', Events: 'Evento', Cities: 'Cidade', Roteiros: 'Rota', RoteirosTrechos: 'Roteiro' };
  const userData = await firebase.firestore().collection('users').where('email', '==', data.emailRequester).get();

  let docType;

  if (data.action !== 'Excluir')
    docType = data.currDoc;
  else
    docType = data.lastDoc;

  const log = {
    cidade: data.city,
    data: date,
    dataString: dataString,
    email: data.emailRequester,
    evento: actions[data.action],
    idRegistro: data.docId,
    nome: data.requestor,
    role: userData.docs[0].data().role,
    tipo: types[data.type],
    titulo: docType.Title || docType.name || docType.titulo,
    userId: userData.docs[0].id,
  };

  await firebase.firestore().collection('LogEdicaoRegistros').add(log);
}

async function handleSetStatusCity(pendency, ref, action) {
  if (ref !== 'Cities' || action === 'Criar') return;

  const dataCity = pendency.data().currDoc || pendency.data().lastDoc;

  const docsAttr = await firebase.firestore().collection('Attractions').where('City', '==', dataCity.name).get();

  if (dataCity.Active !== pendency.data().lastDoc.Active) {
    docsAttr.docs.map(async (attr) => {
      await firebase.firestore().collection('Attractions').doc(attr.id).update({
        cidadeAtiva: dataCity.Active,
      });
    });
  }

  const docsAttrApp = await firebase.firestore().collection('AttractionsApp').where('City', '==', dataCity.name).get();

  if (dataCity.Active !== pendency.data().lastDoc.Active) {
    docsAttrApp.docs.map(async (attr) => {
      await firebase.firestore().collection('AttractionsApp').doc(attr.id).update({
        cidadeAtiva: dataCity.Active,
      });
    });
  }

  const docsPend = await firebase.firestore().collection('Pendencies').where('city', '==', dataCity.name).get();

  docsPend.docs.map(async (pend) => {
    if (pend.data().type === 'Attractions' && pend.data().currDoc && pend.data().currDoc.cidadeAtiva !== dataCity.Active) {
      const newDoc = { ...pend.data().currDoc, cidadeAtiva: dataCity.Active };
      await firebase.firestore().collection('Pendencies').doc(pend.id).update({
        currDoc: newDoc,
      });
    }
  });
}

async function handleSetAttractionsAPP(pendency, ref, action, doc, id) {
  if (ref !== 'Attractions') return;

  let item;

  if (action !== 'Excluir')
    item = doc;
  else
    item = pendency.data().lastDoc;
  
  const attrApp = {
    Active: !!item.Active,
    City: item.City,
    Title: item.Title || null,
    Type: item.Type || null,
    Subtype: item.Subtype || null,
    Especialidade: item.Especialidade || null,
    Visible: !!item.Visible,
    cidadeAtiva: item.cidadeAtiva || false,
    fotoCapa: item.fotoCapa || null,
    classificadoAtrativo: item.classificadoAtrativo || false,
    justifiqueAtrativo: item.justifiqueAtrativo || null,
    bomParaCrianca: item.bomParaCrianca || false,
    Imperdivel: item.Imperdivel || false,
    destaqueLocal: item.destaqueLocal || null,
    'Activities List': item['Activities List'] || null,
    TipoPublico: item.TipoPublico || null,
    'Short Description': item['Short Description'] || null,
    location: item.location || null,
    'Aceita Animais': item['Aceita Animais'] || null,
    Acessibilidade: item.Acessibilidade || null,
    nomeRegiao: item.nomeRegiao || null,
  };

  await firebase.firestore().collection('AttractionsApp').doc(id).set(attrApp);
}

async function handleSetRank(pendency, ref) {
  if (ref !== 'Attractions' && ref !== 'Cities') return;

  const doc = (!objIsEmpty(pendency.data().currDoc)) ? pendency.data().currDoc : pendency.data().lastDoc;
  const cityName = doc.City || doc.name;

  const docCity = await firebase.firestore().collection('Cities').where('name', '==', cityName).get();
  const dataCity = docCity.docs[0].data();
  const idCity = docCity.docs[0].id;

  const attrData = await handleAttractionScores(dataCity.name);
  const cityData = await handleCityScore(dataCity.name);
  const demandData = await handleDemandScore(dataCity.name);
  const comturData = await handleComturScore(dataCity.name);

  let sum = 0;
  const dataSum = {
    attr: {
      curr: 0,
      max: 0,
    },
    city: {
      curr: 0,
      max: 0,
    },
    demand: {
      curr: 0,
      max: 0,
    },
    comtur: {
      curr: 0,
      max: 0,
    },
  };

  attrData.map((attr) => {
    sum += attr.currScore;
    dataSum['attr'].curr += attr.currScore;
    dataSum['attr'].max += attr.maxScore;
  });

  cityData.map((attr) => {
    sum += attr.currScore;
    dataSum['city'].curr += attr.currScore;
    dataSum['city'].max += attr.maxScore;
  });

  demandData.map((attr) => {
    sum += attr.currScore;
    dataSum['demand'].curr += attr.currScore;
    dataSum['demand'].max += attr.maxScore;
  });

  comturData.map((attr) => {
    sum += attr.currScore;
    dataSum['comtur'].curr += attr.currScore;
    dataSum['comtur'].max += attr.maxScore;
  });

  await firebase.firestore().collection('Cities').doc(idCity).update({
    pontosInventario: dataSum['attr'].curr,
    pontosCidade: dataSum['city'].curr,
    pontosEstudoDemanda: dataSum['demand'].curr,
    pontosComtur: dataSum['comtur'].curr,
    pontosTotal: sum,
    isCalc: true,
  });
}

