/* eslint-disable no-await-in-loop */
import firebase from '../config/Firebase';

export const createUserAndAgency = async (userInfos, agencyInfos, next) => {
  let userId = null;
  const newUserInfos = userInfos;

  const user = await firebase.firestore().collection('users').where('email', '==', userInfos.email).get();

  if (user.docs[0])
    editCurrentUser(userInfos, agencyInfos, user.docs[0], next);
  else {
    await firebase.auth().createUserWithEmailAndPassword(userInfos.email, userInfos.password)
      .then((userCredential) => {
        userId = userCredential.user.uid;
  
        delete newUserInfos.password;
        firebase.firestore().collection('users').doc(userId).set({
          ...newUserInfos,
          image: '',
          role: 'cae',
        });
  
        firebase.firestore().collection('Companies').doc(userId).set({ ...agencyInfos, experiences: [] });
  
        next(newUserInfos, userId);
      })
      .catch((error) => {
        return error.message;
      });
  }
};

export const loginCae = async (email, password, next) => {
  const userDoc = await firebase.firestore().collection('users').where('email', '==', email).get();
  const user = userDoc.docs[0];

  if (!user) return 'Email inválido!';

  const role = userDoc.docs[0].data().role;

  if (!['cae', 'caeAdmin'].includes(role))
    return 'Usuário sem permissão para login no Caê';

  return firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
    next(userDoc.docs[0].data(), user.user.uid);
  }).catch((error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'Email incorreto!';
      case 'auth/wrong-password':
        return 'Senha incorreta!';
      case 'auth/user-not-found':
        return 'Usuario não encontrado!';
      default:
        return 'Algo deu errado no login. Tente novamente.';
    }
  });
};

export const handleGetCompany = async (uid) => {
  const companiesDocs = await firebase.firestore().collection('Companies').doc(uid).get();

  return companiesDocs.data();
};

export const getAllExperiences = async () => {
  const companiesDocs = await firebase.firestore().collection('Companies').get();

  let allExperiences = [];
  companiesDocs.docs.map((doc) => {
    const experiences = doc.data().experiences;
    if (experiences.length > 0) {
      allExperiences = allExperiences.concat(experiences.map((exp) => {
        return { ...exp, company: doc.data().name, city: doc.data().city };
      }));
    }
  });

  return allExperiences;
};

export const createExperience = async (company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup) => {
  const urlFotos = [];
  if (experience.urlFotos) {
    for (let indexImg = 0; indexImg < experience.urlFotos.length; indexImg++) {
      const img = experience.urlFotos[indexImg];
      if (img.file !== '') {
        const ref = firebase.storage().ref(`cae/${uid}/${img.name}`);
        await ref.put(img.file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            urlFotos.push({
              name: img.name,
              lastModifiedDate: img.lastModifiedDate,
              size: img.size,
              src: url,
              file: '',
            });
          });
        });
      } else
        urlFotos.push(img);
    }
  }

  const newExperiences = company.experiences;
  newExperiences.push({ ...experience, urlFotos: urlFotos });

  await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });

  setCompanyInfos({ ...company, experiences: newExperiences });
  finishProcess(false);
  setExpPopup(true);
};

export const editExperience = async (company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup) => {
  const urlFotos = [];
  if (experience.urlFotos) {
    for (let indexImg = 0; indexImg < experience.urlFotos.length; indexImg++) {
      const img = experience.urlFotos[indexImg];
      if (img.file !== '') {
        const ref = firebase.storage().ref(`cae/${uid}/${img.name}`);
        await ref.put(img.file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            urlFotos.push({
              name: img.name,
              lastModifiedDate: img.lastModifiedDate,
              size: img.size,
              src: url,
              file: '',
            });
          });
        });
      } else
        urlFotos.push(img);
    }
  }

  const newExperiences = company.experiences;
  newExperiences[index] = { ...experience, urlFotos: urlFotos };

  await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });

  setCompanyInfos({ ...company, experiences: newExperiences });
  finishProcess(false);
  setExpPopup(true);
};

export const saveContact = async (userInfos) => {
  await firebase.firestore().collection('Contacts').add(userInfos);
};

const editCurrentUser = (userInfos, agencyInfos, user, next) => {
  firebase.firestore().collection('users').doc(user.id).set({ ...user.data(), ...userInfos, role: 'cae' })
    .then(() => {
      firebase.firestore().collection('Companies').doc(user.id).set({ ...agencyInfos, experiences: [] });
  
      next({ ...user.data(), ...userInfos, role: 'cae' }, user.id);
    })
    .catch((error) => {
      return error.message;
    });
};

export const getAllCompanies = async () => {
  const companiesDocs = await firebase.firestore().collection('Companies').get();

  return companiesDocs.docs;
};

export const resetPassword = async (email) => {
  try {
    if (!email.includes('@'))
      throw new Error('Email inválido!');

    const docsUsers = await firebase.firestore().collection('users').where('email', '==', email).get();

    if (docsUsers.docs.length === 0)
      throw new Error('Email inválido!');
    
    await firebase.auth().sendPasswordResetEmail(email);

    return '';
  } catch (error) {
    return error.message;
  }
};