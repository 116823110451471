import React, { useEffect, useState } from 'react';
import firebase from '../config/Firebase';
import Loading from './Loading';
import VerifyPopup from './VerifyPopup';
import '../css/ListItinerary.css';
import Itinerary from './Itinerary';
import NoResultsComponent from '../component/NoResultsComponent';

export default function ListItinerary({ setEditPopup, doc, action, setAction, role, cityAdmin, userCity, setNumPendency, handleEditItinerary, handleDeleteItinerary, verifyPopup, setVerifyPopup, mode, handleDeleteIt, selectedItem, setRouteID, search }) {
    const [popup, setPopup] = useState(false);
    const [item, setItem] = useState();
    const [itineraryList, setItineraryList] = useState([]);
    useEffect(() => {
        if (doc && doc.data()) setItem(doc.data());
    }, []);

    useEffect(() => {
        if (item) {
            item['Roteiros'].forEach((id) => {
                getItinerary(id);
            })
        }
    }, [item]);


    const getItinerary = async (id) => {
        const newItinerary = await firebase.firestore().collection('RoteirosTrechos').doc(id).get();
        setItineraryList(prevItineraryList => [...prevItineraryList, newItinerary]);
    };

    if (!userCity || !item) return <Loading />;

    return (
        <div className="ListItinerary">
            <div className="title-wrapper">
                <h4 className="pretitle">Trechos da Rota/Ramal</h4>
                <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup('Itinerary'); setRouteID(doc.id); }}>Criar Novo Trecho/Ramal</p>
            </div>
            { itineraryList && itineraryList.length > 0 ? (
            <div className="div_table_inventory_adm">
                <table className="table_inventory_adm">
                    <tr className="row_table_inventory th_table_admin">
                        <td className='column_title_table_inventory th_table_admin'>Trecho</td>
                        <td className='column_desc_table_inventory th_table_admin'>Descrição</td>
                        <td className='column_desc_table_inventory th_table_admin'>Status</td>
                        <td className='column_desc_table_inventory th_table_admin'></td>
                    </tr>
                    {itineraryList.map((itinerary) => {
                        return <Itinerary doc={itinerary} handleEditItinerary={handleEditItinerary} handleDeleteItinerary={handleDeleteItinerary}/>;
                    })
                    }
                </table>
            </div>
            ) :
            <NoResultsComponent title={'Trechos/Ramal'} search={search} />
            }
            { (verifyPopup && mode == 'Itinerary') && 
                <VerifyPopup title={'Excluir Roteiro'} message={'Tem certeza que gostaria de excluir esse roteiro?'} setVerifyPopup={setVerifyPopup}  handleAction={handleDeleteIt} type={'RoteirosTrecho'} doc={selectedItem} />
            }
        </div>
        
    );
}