import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';
import Map from './pages/Map';
import SignUp from './pages/SignUp';
import SignUpWithSocialMedia from './pages/SignUpWithSocialMedia';
import LogIn from './pages/LogIn';
import LoginAdmin from './pages/LogInAdmin';
import CaeLogin from './pages/CaeLogin';
import anonimous from './authentication/anonimous';
import withAuthentication from './authentication/withAuthentication';
import withAuthenticationCompleteSignUp from './authentication/withAuthenticationCompleteSignUp';
import adminAlreadyAuthenticated from './authentication/adminAlreadyAuthenticated';
import Profile from './pages/Profile';
import Report from './pages/Report';
import IndexAdmin from './pages/admin/IndexAdmin';
import ErrorPage from './pages/ErrorPage';
import CaeRegister from './pages/CaeRegister';
import Cae from './pages/cae/Cae';
import CaeForgotPassword from './pages/CaeForgotPassword';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignUp} />
          <Route path="/adm" component={LoginAdmin} />
          <Route path="/cae-login" component={CaeLogin} />
          <Route path="/cae-registro" component={CaeRegister} />
          <Route path="/cae-forgot-password" component={CaeForgotPassword} />
          <Route path="/forgotpassword" component={ForgotPassword}/>
          <Route path="/map/:collection/:document" component={Map} />
          <Route path="/map" component={anonimous(Map)} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signup-with-social-media" component={withAuthenticationCompleteSignUp(SignUpWithSocialMedia)} />
          <Route path="/login" component={LogIn} />
          <Route path='/profile' component={withAuthentication(Profile)} />
          <Route path='/report' component={withAuthentication(Report)}/>
          <Route path='/admin' component={adminAlreadyAuthenticated(IndexAdmin)}/>
          <Route path='/cae' component={adminAlreadyAuthenticated(Cae)}/>
          <Route component={() => <ErrorPage message='Endereço não encontrado. Verifique a URL e tente novamente'/>}/>
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
