/* eslint-disable no-nested-ternary */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FaUser } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import Logo from '../assets/imgs/logo_horizontal.png';
import { FiMoreVertical } from 'react-icons/fi';
import firebase from '../config/Firebase';
import IosSync from 'react-ionicons/lib/IosSync';
import SearchBar from './SearchBar';
import { Hidden } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerStatus: false,
      dataFromFilter: null,
      witdh: window.innerWidth,
      userLoggedIn: false,
      name: '',
      city: '',
      image: undefined,
      loading: true,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.retrieveData();
    this.updateDimensions();
  }

  retrieveData() {
    const userLoggedIn = firebase.auth().currentUser !== null;
    if (userLoggedIn) {
      const userDoc = firebase.auth().currentUser.uid;
      firebase.firestore().collection('users').doc(userDoc).onSnapshot((doc) => {
        this.setState({
          userLoggedIn: true,
          name: doc.data().name,
          city: doc.data().city,
          image: doc.data().image,
          loading: false,
        });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
  }

  renderUserMenu = () => {
    const { name, city, image, userLoggedIn } = this.state;
    const { isMobile, uploadingImage } = this.props;

    if (userLoggedIn) {
      return (
      <div className={css(styles.user)}>
        {
          !isMobile &&
          <div className={css(styles.text)}>
            <p style={{ fontSize: 16, marginBottom: 0, fontWeight: 'bold' }}>{name}</p>
            <p style={{ fontSize: 13, marginBottom: 0 }}>{city}</p>
          </div>
        }
        {
          uploadingImage ?
            <IosSync color='white' rotate className={`icon m-3 ${css(styles.image)} `}/>
            :
            (image === undefined || image === '') ?
              <IconContext.Provider value={{ color: 'white', size: '30px' }}>
                <FaUser style={{ size: '30px' }} />
              </IconContext.Provider>
              :
              <img alt='' className={css(styles.image)} src={image}/>
          
        }
        <div style={{ marginLeft: '0px' }}>
          <IconContext.Provider value={{ color: 'white', size: '30px' }}>
            <FiMoreVertical style={{ marginLeft: '0px !important', marginRight: '0px !important' }} className={`icon m-3 ${css(styles.menu, styles.noLateralMargin)}`} onClick={this.props.toggleUserMenu}/>
          </IconContext.Provider>
        </div>
      </div>
      );
    }

    return (
      <div className={css(styles.user)} onClick={() => this.setState({ goToLogin: true })}>
        <IconContext.Provider value={{ color: 'white', size: '30px' }}>
          <FaUser className={'icon m-3 '}/>
        </IconContext.Provider>
      </div>
    );
  }

  render() {
    const { loading, goToLogin } = this.state;

    if (loading) return <div></div>;

    if (goToLogin) return <Redirect to='/login'/>;

    return (
      <div id='drawer-container' className={css(styles.background)}>
          <IconContext.Provider><img alt='' src={Logo} height='55'/></IconContext.Provider>
          <Hidden smDown><SearchBar/></Hidden>
          {this.renderUserMenu()}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  noLateralMargin: {
    marginRight: '0px',
    marginLeft: '0px',
  },
  background: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to right, #67B26F, #4ca2cd)',
  },
  menu: {
    cursor: 'pointer',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  image: {
    width: '38px',
    height: '38px',
    borderRadius: '50%',
  },
  middle: {
    flex: 1, 
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  firstItem: {
    flex: 1, 
    display: 'flex',
    alignItens: 'center',
    justifyItens: 'center',
    marginTop: 8,
  },
  lastItem: {
    marginLeft: 'auto',
    flex: 1, 
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    color: 'white',
    marginRight: '3px',
    textAlign: 'right',
  },
  user: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

export default Header;
