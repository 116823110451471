import Firebase from 'firebase';
import 'firebase/auth';

// Required for side-effects
require('firebase/firestore');

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDYpamzx8TcReVoSC0ylDcIENLUW1HVQgo',
  authDomain: 'portaldev-effa3.firebaseapp.com',
  databaseURL: 'https://portaldev-effa3.firebaseio.com',
  projectId: 'portaldev-effa3',
  storageBucket: 'portaldev-effa3.appspot.com',
  messagingSenderId: '993283542506',
  appId: '1:993283542506:web:d1ce4f8cb82cd283',
};
const firebase = Firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });
export default firebase;
