import React, { useState } from 'react';
import '../css/VerifyPopup.css';

import SpinButton from "./SpinButton";

export default function VerifyPopup({ setVerifyPopup, title, handleAction, doc, message }) {
  const [isLoading, setIsLoading] = useState(false);

  function unsetButtons() {
    setIsLoading(true);
    document.getElementById('btn-cancel-verify-popup').disabled = true;
    document.getElementById('btn-confirm-verify-popup').disabled = true;
    
    setTimeout(handleAction, 2000, doc);
  }
  
  return (
    <div className="VerifyPopup">
    <div className="div-align-inner-popup">
      <div className="div-title-verify-popup">
        <span>
          { title }
        </span>
      </div>
      <div className="div-message-verify-popup">
        <span>
          { message }
        </span>
      </div>
      <div className="div-buttons-verify-popup">
        <button type="button" className="btn-cancel-verify-popup" id="btn-cancel-verify-popup" onClick={() => setVerifyPopup(false)}>Cancelar</button>
        <button type="button" className="btn-confirm-verify-popup" id="btn-confirm-verify-popup" onClick={() => unsetButtons()}>
          {
            isLoading ? <SpinButton /> : 'Confirmar'
          }
        </button>
      </div>
    </div>
  </div>
  );
}
