import React, { useEffect, useState } from "react";

import '../../assets/css/EditComponents/editExperiences.css';

import { Country, State, City } from 'country-state-city';

import { CheckboxField } from '../Fields/CheckboxField';
import { TextField } from '../Fields/TextField';
import { TextareaField } from '../Fields/TextareaField';
import { RadioField } from '../Fields/RadioField';
import { ImageField } from '../Fields/ImageField';
import { MultipleRadioField } from '../Fields/MultipleRadioField';
import { SelectField } from '../Fields/SelectField';
import SpinButton from '../SpinButton';

import { editExperience, createExperience } from '../../apis/cae.endpoint';
import { checkFields } from '../../utils/cae/checkFields';

export default function EditExperiences({ uid, company, experience, setEditExperiences, index, setCompanyInfos, setExpPopup, viewStyle }) {
  const [value, setValue] = useState(experience || { country: 'Brazil (BR)' });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const requiredFields = ['NomeExperiencia', 'country', 'state', 'city', 'SegmentosTuristicos', 'ItensTuristicos', 'FrequenciaExperiencia', 'AdequadaLGBT', 'AdequadaAcessibilidade', 'AdequadaIdoso', 'AdequadaCrianca', 'RoteiroPertencente', 'RecebeEstrangeiro', 'Idiomas', 'ContratarAtendimentoIdioma', 'ExemploAtendimentoOutroIdioma', 'TipoExperiencia', 'DesenvolvimentoSustentavel', 'Sustentabilidade', 'ExperienciaInternacional', 'ComercioInternacional', 'AutorizacaoImagem', 'Premiacao'];

  function getPlaces(places) {
    if (!places) return [];

    const filtered = [];
    places.map((plac) => {
      filtered.push(plac.name + ' (' + plac.isoCode + ')');
    });

    return filtered;
  }

  function getCities(cities) {
    const filtered = [];

    cities.map((city) => {
      filtered.push(city.name);
    });

    return filtered;
  };

  useEffect(() => {
    if (value.urlFotos && value.AutorizacaoImagem)
      delete value.urlFotos;
  }, [value.AutorizacaoImagem]);

  return (
    <div className="edit-container">
      <div className="div-path-edit">
        Cadastro de Experiências / Experiências de Lazer
      </div>
      <div className="header-edit">
        <h1>Criar Experiência</h1>
        <span>Abaixo está o formulário que deve ser preenchido. Os campos com (*) são obrigatórios.</span>
      </div>
      <div className="form-edit">
        <TextField
          title={'Agora me diz o nome da sua experiência*'}
          fieldForm={'NomeExperiencia'}
          placeholder={'Preencha com o nome'}
          onChange={(e) => setValue({ ...value, NomeExperiencia: e.target.value })}
          value={value.NomeExperiencia || ''}
          explanation={<span>Alguns exemplos de nome são:  Almoço no Quilombo, Pôr do sol no parreiral, Visita à fazenda de café, Conhecendo os produtes de queijo, Ecotrilha pela mata, Rota do Vinho, Caminho dos Artesãos, Observação de pássaros, Rota Gastronômica, Trilha literária pela floresta, Viva a cultura indígena, Picnic natural no Parque Nacional etc.</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <SelectField
          title={'País*'}
          fieldForm={'country'}
          style={{ width: '340px' }}
          values={getPlaces(Country.getAllCountries())}
          setValue={setValue}
          value={value}
          defaultValue={value.country}
          disabled={viewStyle}
        />
        <SelectField
          title={'Estado*'}
          fieldForm={'state'}
          style={{ width: '380px' }}
          values={value.country ? getPlaces(State.getStatesOfCountry(value.country.slice(value.country.length - 3, value.country.length - 1))) : []}
          setValue={setValue}
          value={value}
          defaultValue={value.state}
          disabled={viewStyle}
        />
        <SelectField
          title={'Cidade*'}
          fieldForm={'city'}
          style={{ width: '380px' }}
          values={value.country && value.state ? getCities(City.getCitiesOfState(value.country.replace('(', ',').replace(')', ',').split(',')[1], value.state.replace('(', ',').replace(')', ',').split(',')[1])) : []}
          setValue={setValue}
          value={value}
          defaultValue={value.city}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Aventura', 'Cultural', 'Ecoturismo', 'Estudos e Intercâmbio', 'Negócios e Eventos', 'Pesca', 'Saúde', 'Sol e Praia', 'Turismo Náutico', 'Turismo Rural', 'Turismo de Esportes']}
          fieldForm={'SegmentosTuristicos'}
          title={'Em quais dos segmentos abaixo a sua experiência tem mais aderência? Pode selecionar mais de um, mas sugiro que escolha até 3*'}
          setValue={setValue}
          styleSub={{ width: '200px' }}
          value={value}
          limit={3}
          explanation={<span>Caso você queira saber a definição dos itens abaixo, acesso nosso <a href="https://docs.google.com/spreadsheets/d/1lWezwTDqhUAp_Fno16oRnixtYUceb34T92voniwW1A0/edit#gid=0" target="_blank">Glossário de Segmentos Turísticos</a></span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Afroturismo', 'Arqueológico', 'Arte / Artesanato', 'Base Comunitária', 'Bem-estar', 'Casamento / Lua de Mel', 'Cinematográfico / Audiovisual', 'Cívico', 'Cicloturismo', 'Enoturismo', 'Ferroviário', 'Festas populares', 'Gastronomia', 'Luxo', 'Místico / Esotérico', 'Parques Naturais', 'Patrimônio / Arquitetura / Design', 'Povos Indígenas', 'Religião / Fé', 'Outro']}
          fieldForm={'ItensTuristicos'}
          title={'Em quais dos itens abaixo a sua experiência tem mais aderência?  Pode selecionar mais de um, mas escolha até 3*'}
          setValue={setValue}
          value={value}
          styleSub={{ width: '220px' }}
          limit={3}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%' }}
          values={['Uma vez ao ano', 'Em temporadas/meses específicas(os)', 'Ano todo', 'Outro']}
          fieldForm={'FrequenciaExperiencia'}
          title={'Com qual frequência ocorre sua experiência?*'}
          setValue={setValue}
          value={value}
          explanation={<span>Descrever se a sua experiência ocorre em alguma data/mês/período específico, ou se ela ocorre durante o ano todo.<br />Exemplo: Festival da Uva - uma vez ao ano; ou Observação de baleias - em temporadas/meses específicas(os); ou Almoço no Quilombo - ano todo</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim, é direcionada', 'Sim, é adequada', 'Não atende']}
          fieldForm={'AdequadaLGBT'}
          title={'Sua experiência é adequada e/ou direcionada ao público LGBTQIA+?*'}
          setValue={setValue}
          value={value}
          explanation={<span>Experiência direcionada = experiência específica para o público LGBTQIA+ (ex: Parada do Orgulho)<br />
            Experiência adequada = experiência para todas as audiências, mas que está preparada para receber o público LGBTQIAP+ (ex: Degustação de vinhos para casais na vinícola)</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Não atende', 'Para cadeirantes ou pessoas com mobilidade reduzida', 'Para pessoas com deficiência física', 'Para pessoas com deficiência visual', 'Para pessoas com deficiência auditiva', 'Para pessoas surdocegas', 'Para pessoas com deficiência intelectual ou mental', 'Para pessoas no espectro autista', 'Outro']}
          fieldForm={'AdequadaAcessibilidade'}
          title={'Sua experiência atende pessoas com deficiência ou mobilidade reduzida?*'}
          setValue={setValue}
          styleSub={{ width: '380px' }}
          value={value}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim, é direcionada', 'Sim, é adequada', 'Não atende']}
          fieldForm={'AdequadaIdoso'}
          title={' Sua experiência é direcionada e/ou adequada ao público idoso (60+)?*'}
          setValue={setValue}
          value={value}
          explanation={<span>Experiência direcionada = experiência específica para idoso (60+)<br />
            Experiência adequada = experiência para todas as audiências, mas que está preparada para receber o público idoso (60+)</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim, é direcionada', 'Sim, é adequada', 'Não atende, pois é para maiores de idade']}
          fieldForm={'AdequadaCrianca'}
          title={'Sua experiência é adequada para crianças/adolescentes ou é apenas para maiores de idade?*'}
          setValue={setValue}
          value={value}
          explanation={<span>Experiência direcionada = experiência específica para crianças e adolescentes<br />
            Experiência adequada = experiência para todas as audiências, mas que está preparada para receber crianças e adolescentes</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Se sua experiência faz parte de alguma Rota, Roteiro ou Circuito Turístico, indique o nome:*'}
          fieldForm={'RoteiroPertencente'}
          placeholder={'Preencha com o roteiro'}
          onChange={(e) => setValue({ ...value, RoteiroPertencente: e.target.value })}
          value={value.RoteiroPertencente || ''}
          explanation={<span>Exemplo: Rota das Emoções<br />
            Caso a experiência não faça parte, preencher com: não faz parte.</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Não recebe estrangeiros', 'Não sei especificar', 'Alemanha', 'Argentina', 'Austrália', 'Bélgica', 'Bolívia', 'Canadá', 'Chile', 'China', 'Colômbia', 'Espanha', 'Estados Unidos', 'França', 'Itália', 'Paraguai', 'Japão', 'México', 'Países Baixos', 'Peru', 'Portugal', 'Reino Unido', 'Suíça', 'Uruguai', 'Outro']}
          fieldForm={'RecebeEstrangeiro'}
          title={'Sua experiência recebe estrangeiros? De onde?*'}
          setValue={setValue}
          value={value}
          explanation={<span>Caso a experiência não receba estrangeiros, selecionar apenas a primeira opção: "Não recebe estrangeiros"<br />
          Caso o país de origem não esteja listado, selecione a opção "Outros"<br />
          Caso não saiba identificar de onde é o estrangeiro, selecione a opção "Não sei especificar"</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <MultipleRadioField
          title={'Sua experiência é oferecida em outros idioma(s) além do Português? Se sim, qual(is)?*'}
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Inglês', 'Espanhol', 'Italiano', 'Alemão', 'Francês']}
          fieldForm={'Idiomas'}
          setValue={setValue}
          value={value}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Se sua experiência é oferecida em algum outro idioma não listado acima, indique qual:'}
          fieldForm={'OutroIdioma'}
          placeholder={'Preencha com os idiomas'}
          style={{ width: '100%' }}
          onChange={(e) => setValue({ ...value, OutroIdioma: e.target.value })}
          value={value.OutroIdioma || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim', 'Não', 'Dependendo da situação']}
          fieldForm={'ContratarAtendimentoIdioma'}
          title={'Se a sua experiência não é oferecida em outro idioma, você tem a disponibilidade de contratar algum profissional especializado para o atendimento (ex: guia de turismo, condutor de turismo de aventura, intérprete, etc.)?*'}
          setValue={setValue}
          value={value}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextareaField
          title={'Apresente links, referências e/ou exemplos de como a experiência é oferecida em outros idiomas (placas, menus, sites, materiais promocionais etc.)*'}
          fieldForm={'ExemploAtendimentoOutroIdioma'}
          placeholder={'Preencha com os links'}
          style={{ width: '100%' }}
          onChange={(e) => setValue({ ...value, ExemploAtendimentoOutroIdioma: e.target.value })}
          value={value.ExemploAtendimentoOutroIdioma || ''}
          explanation={<span>Caso a sua experiência não atenda em outros idiomas, preencher com: não atende</span>}
          item={value}
          setItem={setValue}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim, sustentabilidade ambiental', 'Sim, sustentabilidade social', 'Sim, ambas', 'Não']}
          fieldForm={'TipoExperiencia'}
          title={'Sua experiência está posicionada como “sustentável” e/ou “responsável”?*'}
          setValue={setValue}
          value={value}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={[ 'Não está aderente a nenhum ODS', 'ODS 1 - Erradicação da pobreza', 'ODS 2 - Fome zero e agricultura sustentável', 'ODS 3 - Saúde e bem-estar', 'ODS 4 - Educação de qualidade', 'ODS 5 - Igualdade de gênero', 'ODS 6 - Água limpa e saneamento', 'ODS 7 - Energia limpa e acessível', 'ODS 8 - Trabalho decente e crescimento econômico', 'ODS 9 - Inovação e infraestrutura', 'ODS 10 - Redução das desigualdades', 'ODS 11 - Cidades e comunidades sustentáveis', 'ODS 12 - Consumo e produção responsáveis', 'ODS 13 - Ação contra a mudança global do clima', 'ODS 14 - Vida na água', 'ODS 15 - Vida terrestre', 'ODS 16 - Paz, justiça e instituições eficazes', 'ODS 17 - Parcerias e meios de implementação']}
          fieldForm={'DesenvolvimentoSustentavel'}
          title={'Se sim, com qual(is) Objetivos de Desenvolvimento Sustentável ela se relaciona? Sugiro que escolha até 3 ODS*'}
          styleSub={{ width: '380px' }}
          setValue={setValue}
          value={value}
          limit={3}
          explanation={<span>Quer saber mais sobre os Objetivos e suas metas? Acesse este link da <a href="https://brasil.un.org/pt-br/sdgs" target="_blank">Agenda 2030 da ONU</a></span>}
          disabled={viewStyle}
        />        
        <div className="slice-section-form" />
        <TextareaField
          title={'Apresente links, referências e/ou exemplos de como a sustentabilidade está presente na experiência*'}
          fieldForm={'Sustentabilidade'}
          placeholder={'Preencha com os links'}
          style={{ width: '100%' }}
          onChange={(e) => setValue({ ...value, Sustentabilidade: e.target.value })}
          value={value.Sustentabilidade || ''}
          explanation={<span>Caso a sustentabilidade não esteja posicionada na sua experiência, preencher com: não está posicionada</span>}
          item={value}
          setItem={setValue}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Não é promovida internacionalmente', 'Campanhas de mídia off-line', 'Campanhas de mídia digital', 'Eventos segmentados', 'Feiras multiprodutos', 'Materiais promocionais', 'Roadshows', 'RP (Relações Públicas)', 'Site na internet', 'Outro']}
          fieldForm={'ExperienciaInternacional'}
          title={'A sua experiência já é promovida internacionalmente (em outros idiomas)? Se sim, como?*'}
          styleSub={{ width: '270px' }}
          setValue={setValue}
          value={value}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <CheckboxField
          style={{ width: '100%' }}
          values={['Não possui comercialização internacional', 'Venda direta nos canais próprios da empresa', 'Por intermédio de um operador brasileiro (DMC)', 'Por intermédio de um operador internacional', 'Por meio de plataformas de comercialização/brokers internacionais', 'Outro']}
          fieldForm={'ComercioInternacional'}
          title={'Se a experiência possui uma comercialização internacional ativa, selecione como:*'}
          styleSub={{ width: '380px' }}
          setValue={setValue}
          value={value}
          explanation={<span>Entenda por operador brasileiro (DMC), empresa nacional que trabalha trazendo estrangeiros para o Brasil.<br />Entenda por operador internacional, empresa sediada em outro país que envia turistas estrangeiros para o Brasil.<br />Entenda por brokers internacionais, plataformas digitais de comercialização de serviços turísticos</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <RadioField
          style={{ width: '100%', justifyContent: 'flex-start' }}
          values={['Sim', 'Não']}
          title={'Você gostaria de compartilhar fotos da sua experiência com a Embratur? Se sim, use este espaço opcional para cadastrar até 3 fotos da sua experiência.*'}
          fieldForm={'AutorizacaoImagem'}
          setValue={setValue}
          value={value}
          explanation={<span>Estas fotos são para referência e a Embratur não se obriga a utilizá-las nas ferramentas e canais de promoção internacional. Leia o <a href="https://www.destinosinteligentes.tur.br/termos/termo_uso_fotos_videos.docx" target="_blank">Termo de Autorização de Uso de Fotos/Vídeos</a> e clique em Sim para carregar as imagens.</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        {
          value.AutorizacaoImagem === 'Sim' ? (
            <>
              <ImageField
                title={'Fotos'}
                fieldForm={'urlFotos'}
                setValue={setValue}
                value={value}
                disabled={viewStyle}
              />
              <div className="slice-section-form" />
            </>
          ) : null
        }
        <TextField
          title={'Se sua experiência possui alguma certificação, foi vencedora de alguma premiação ou adota alguma norma (ex: ISO, ABNT etc.), indique o nome:*'}
          fieldForm={'Premiacao'}
          placeholder={'Preencha com a certificação'}
          style={{ width: '100%' }}
          onChange={(e) => setValue({ ...value, Premiacao: e.target.value })}
          value={value.Premiacao || ''}
          explanation={<span>Exemplo: Certificação Bandeira Azul ou Prêmio Nacional do Turismo ou Sistema de Gestão da Segurança do Turismo de Aventura (ABNT NBR ISO 21101) etc.</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Duração'}
          fieldForm={'Duracao'}
          placeholder={'Preencha com a duração da experiência'}
          style={{ width: '100%' }}
          onChange={(e) => setValue({ ...value, Duracao: e.target.value })}
          value={value.Duracao || ''}
          explanation={<span>Por exemplo: 2 horas ou 3 dias.</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextareaField
          title={'Descrição da Experiência'}
          fieldForm={'Descr'}
          placeholder={'Preencha com a descrição da experiência'}
          style={{ width: '100%' }}
          value={value.Descr || ''}
          limit={500}
          explanation={<span>Máximo 500 caracteres</span>}
          item={value}
          setItem={setValue}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        { errorMessage !== '' ? <span className="error-message-form-cae">{errorMessage}</span> : null }
        <div className="div-align-buttons-form">
          <button onClick={() => setEditExperiences(false)}>Cancelar</button>
          { !viewStyle ? (
            <button onClick={() => checkFields(value, requiredFields, setErrorMessage, experience ? editExperience : createExperience, uid, company, index, setEditExperiences, setCompanyInfos, setExpPopup, setIsLoading)} id="handle-set-doc-button">
              {
                isLoading ? <SpinButton /> : 'Gravar'
              }
            </button>
          ) : null}
        </div>
      </div>
    </div>  
  );
}