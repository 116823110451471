import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import '../../css/InventoryAdmin.css';
import EventsList from '../../component/ListComponents/EventsList';
import Loading from '../../component/Loading';
import EditEvents from '../../component/EditComponents/EditEvents';
import VerifyPopup from '../../component/VerifyPopup';
import NoResultsComponent from '../../component/NoResultsComponent';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';
import { RodapeAdmin } from '../../component/RodapeAdmin';

export default function EventsAdmin() {
  const [places, setPlaces] = useState();
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [filter, setFilter] = useState('Title');
  const [search, setSearch] = useState('');
  const [action, setAction] = useState('Editar');
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, cityAdmin, role, setNumPendency } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'Title' : filter;
    setIsLoadingCities(true);

    const docs = firebase.firestore()
      .collection('Events')
      .where('City', '==', city)
      .orderBy(currFilter);
    
    setIsLoadingCities(false);

    return docs;
  };

  const getFirstPageEvents = async () => {
    if (city) {
      const newDocs = await buildQuery().get();
        
      setFilteredPlaces(newDocs);
      setPlaces(newDocs);
    }
  };
  const searchEventText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().Title.toLowerCase().includes(search.toLowerCase());
    const isInDescription = doc.data().Description?.toLowerCase().includes(search.toLowerCase());

    return isInTitle || isInDescription;
  };
  
  useEffect(() => {
    getFirstPageEvents();
  }, [city, editPopup, filter]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPageEvents();
    }

    window.scrollTo(0, 0);
  }, [editPopup]);

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchEventText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  const handleDeleteEvent = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Events',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      getFirstPageEvents();
    } catch (error) {
      setVerifyPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup(true);
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
  };

  if (!places || isLoadingCities || !filteredPlaces || isLoading) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup ? <EditEvents setEditPopup={setEditPopup} doc={selectedItem} action={action} role={role} cityAdmin={cityAdmin} userCity={city} setNumPendency={setNumPendency} /> : (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Eventos</h1>
        </div>
        <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup(true); }}>Criar Novo Evento</p>
        </div>
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('Title')}>Titulo</td>
                <td className='column_desc_table_inventory th_table_admin' onClick={() => setFilter('Description')}>Descrição Curta</td>
                <td className='column_table_inventory th_table_admin'>Local</td>
                <td className='column_table_inventory th_table_admin'>Cidade</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('StartDate')}>Data início</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('EndDate')}>Data fim</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  return <EventsList doc={doc} key={doc.id} handleEdit={handleEdit} handleDelete={handleDelete} setIsLoading={setIsLoading} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Eventos'} search={search} /> }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} action={'Excluir'} handleAction={handleDeleteEvent} type={'Evento'} doc={selectedItem} /> : null}  
      </div>)}
    </div>
  );
}
