/* eslint-disable import/no-duplicates */
import Infrastructure from './pins/pin_infra.png';
import Drugstore from './pins/pin_farmacia.png';
import Lodging from './pins/pin_hospedagens.png';
import Food from './pins/pin_restaurantes.png';
import Services from './pins/pin_servicos.png';
import Attraction from './pins/pin_atrativos.png';
import Bank from './pins/pin_banco.png';
import Commerce from './pins/pin_compras.png';
import Craftwork from './pins/pin_compras.png';
import FuelStation from './pins/pin_gasolina.png';
import Supermarket from './pins/pin_compras.png';
import User from './pins/pin_user.png';

const Pins = {

  Infrastructure,
  Drugstore,
  Lodging,
  Food,
  Services,
  Attraction,
  Bank,
  Commerce,
  Craftwork,
  FuelStation,
  Supermarket,
  User,
};

const Icons = {
  logo: 'nothing',
};

export { Pins, Icons };
