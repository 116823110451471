import React, { useContext, useEffect, useState, useRef } from 'react';
import '../css/AtractionRoutes.css';
import Loading from './Loading';
import { limitStr } from '../utils/limitStr';
import firebase from '../config/Firebase';
import Itinerary from './Itinerary';
// eslint-disable-next-line import/no-cycle
import { MyContext } from './SidebarAdmin';

export default function RoutesList({ doc, handleEdit, handleEditItinerary, handleDelete, setAction, setEditPopup, setRouteID, handleDeleteItinerary, handleListItinerary, handleChange }) {
  const [places, setPlaces] = useState();
  const [filter, setFilter] = useState('Title');
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [filteredPlaces, setFilteredPlaces] = useState();
  const [publish, setPublish] = useState();
  const { city } = useContext(MyContext);

  const [isOpen, setIsOpen] = useState(false);
  const buildQuery = async () => {
    const currFilter = filter === 'searchBar' ? 'Title' : filter;
    setIsLoadingCities(true);
    // const roteiro = doc.data().Title;
    const docs = (firebase.firestore()
      .collection('RoteirosTrechos')
      .where('idRota', '==', doc.id)
      .get());
    /* .orderBy(currFilter).get()) */
    setIsLoadingCities(false);
    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery();

    setFilteredPlaces(newDocs);
    setPlaces(newDocs);
  };

  useEffect(() => {
    if (city) {
      getFirstPage();
    }
  }, [city]);
  
  useEffect(() => {
    handleSetPublish();
  }, []);


  if (!places && isLoadingCities) {
    return <p><Loading /></p>;
  }

  async function handleSetPublish() {
    const docInfo = await firebase.firestore().collection('Roteiros').doc(doc.id).get();

    setPublish(!!docInfo.data().Active);
  }

  async function handlePublish() {
    await firebase.firestore().collection('Roteiros').doc(doc.id).update({
      Active: !publish,
    });

    setPublish(!publish);
    handleChange();
  }
  return (
    <tr className="row_table_inventory">
      <td className="title_table_inventory">{doc.data().Title}</td>
      <td className="desc_table_inventory">{limitStr(doc.data()['Short Descr'])}</td>
      <td className="desc_table_inventory">{limitStr(doc.data().Tipo)}</td>
      <td className="title_table_inventory">{doc.data().Roteiros.length || 0}</td>
      <td className="column_min_table_inventory">{doc.data().City}</td>
      <td className="column_min_table_inventory">{doc.data().Active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc, 'Rotas')}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleListItinerary(doc)}>Ver Trechos Rota/Ramal</button>
          {
            doc.data().Roteiros.length > 0 ?
              <button className='btn_mod_adm' onClick={() => handlePublish()}>{ publish ? 'Despublicar' : 'Publicar' }</button>
              : <></>
          }
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Excluir</button>
        </div>
      </td>
    </tr>
  );
}
