import React from 'react';

import '../../assets/css/buttonForm.css';

export default function ButtonForm({ setEditPopup, handleCheckFields, btnAc, btnRe, role }) {
  return (
    <div className='ButtonForm'>
      <div className='div_out_button_form'>
        <div className='div_align_button_form'>
            <button type='button'onClick={() => setEditPopup(false)} className='btn_edit_inventory btn_cancel_changes' id={btnRe}>Cancelar</button>
            {
              role !== 'contentEditor' ? 
                <button type='button' className='btn_edit_inventory btn_acepy_changes' onClick={() => handleCheckFields()} id={btnAc}>Salvar Mudanças</button>
                : null
            }
        </div>
      </div>
    </div>
  );
}