import React, { useState } from "react";

import '../../assets/css/EditComponents/editExperiences.css';

import { TextField } from '../Fields/TextField';
import { TextareaField } from '../Fields/TextareaField';
import { RadioField } from '../Fields/RadioField';
import { SelectField } from '../Fields/SelectField';
import SpinButton from '../SpinButton';

import { checkFields } from '../../utils/cae/checkFields';

export default function EditCompanies({ uid, company, setEditCompanies, index, setCompanyInfos, viewStyle }) {
  const [value, setValue] = useState(company || {});
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const requiredFields = ['managerName', 'name', 'type', 'state', 'city', 'country', 'cnpj'];

  return (
    <div className="edit-container">
      <div className="div-path-edit">
        Cadastro de Experiências / Experiências de Lazer
      </div>
      <div className="header-edit">
        <h1>Criar Experiência</h1>
        <span>Abaixo está o formulário que deve ser preenchido. Os campos com (*) são obrigatórios.</span>
      </div>
      <div className="form-edit">
        <TextField
          title={'Nome da Empresa*'}
          fieldForm={'name'}
          placeholder={'Preencha com o nome da empresa'}
          onChange={(e) => setValue({ ...value, name: e.target.value })}
          value={value.name || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Nome do Responsável*'}
          fieldForm={'managerName'}
          placeholder={'Preencha com o nome do responsável'}
          onChange={(e) => setValue({ ...value, managerName: e.target.value })}
          value={value.managerName || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Email*'}
          fieldForm={'email'}
          placeholder={'Preencha com o email'}
          onChange={(e) => setValue({ ...value, email: e.target.value })}
          value={value.email || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'WhatsApp'}
          fieldForm={'whatsapp'}
          placeholder={'Preencha com o WhatsApp'}
          onChange={(e) => setValue({ ...value, whatsapp: e.target.value })}
          value={value.whatsapp || ''}
          explanation={<span>Caso você não tenha WhatsApp, pode ser um telefone fixo. Preencher com o DDD, como o exemplo: (61) 99999-9999. Se o seu número for internacional, colocar o DDI, como o exemplo: +55 (XX) XXXXX-XXXX</span>}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <SelectField
          title={'Ramo de atuação?*'}
          fieldForm={'type'}
          style={{ width: '100%' }}
          values={['Agência de turismo receptiva', 'Agência de turismo emissiva', 'Agente independente', 'Artesão', 'Artista', 'Consultoria', 'Entretenimento, lazer e recreação', 'Gastronomia', 'Guia de turismo', 'Líder/Condutor de turismo de aventura', 'Meio de hospedagem (hotel, pousada, lodge, glamping, resort, etc.)', 'Operadora', 'Produtor rural', 'Terceiro setor', 'Transporte turístico (aéreo, terrestre e náutico)', 'Outro']}
          setValue={setValue}
          value={value}
          defaultValue={value.type}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        {
          value.type === 'Outro' ? (
            <>
              <TextField
                title={'Outro ramo de atuação*'}
                fieldForm={'typeOther'}
                placeholder={'Preencha com outros ramos'}
                onChange={(e) => setValue({ ...value, typeOther: e.target.value })}
                value={value.typeOther || ''}
                disabled={viewStyle}
              />
              <div className="slice-section-form" />
            </>
          ) : null
        } 
        <RadioField
          style={{ width: '100%' }}
          values={['Sim', 'Não']}
          fieldForm={'CadasturAtivo'}
          title={'Você possui Cadastur ativo?*'}
          setValue={setValue}
          value={value}
          disabled={viewStyle}
          typeBoolean={true}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Tem site oficial ou principal? Se sim, insira o(s) link(s)'}
          fieldForm={'site'}
          placeholder={'Preencha com o email'}
          onChange={(e) => setValue({ ...value, site: e.target.value })}
          value={value.site || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Insira o link de Instagram'}
          fieldForm={'instagram'}
          placeholder={'Preencha com o email'}
          onChange={(e) => setValue({ ...value, instagram: e.target.value })}
          value={value.instagram || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Insira o link de Facebook'}
          fieldForm={'facebook'}
          placeholder={'Preencha com o facebook'}
          onChange={(e) => setValue({ ...value, facebook: e.target.value })}
          value={value.facebook || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Insira o link de YouTube'}
          fieldForm={'youtube'}
          placeholder={'Preencha com o youtube'}
          onChange={(e) => setValue({ ...value, youtube: e.target.value })}
          value={value.youtube || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextField
          title={'Insira o link de TikTok'}
          fieldForm={'tiktok'}
          placeholder={'Preencha com o tiktok'}
          onChange={(e) => setValue({ ...value, tiktok: e.target.value })}
          value={value.tiktok || ''}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        <TextareaField
          title={'Insira outros links que existirem'}
          fieldForm={'otherLinks'}
          placeholder={'Preencha com os links'}
          style={{ width: '100%' }}
          value={value.otherLinks || ''}
          limit={500}
          item={value}
          setItem={setValue}
          disabled={viewStyle}
        />
        <div className="slice-section-form" />
        { errorMessage !== '' ? <span className="error-message-form-cae">{errorMessage}</span> : null }
        <div className="div-align-buttons-form">
          <button onClick={() => setEditCompanies(false)}>Cancelar</button>
          { !viewStyle ? (
            <button onClick={() => checkFields(value, requiredFields, setErrorMessage, 'function', uid, company, index, 'setEditCompanies', 'setCompanyInfos', 'setExpPopup', setIsLoading)} id="handle-set-doc-button">
              {
                isLoading ? <SpinButton /> : 'Gravar'
              }
            </button>
          ) : null}
        </div>
      </div>
    </div>  
  );
}