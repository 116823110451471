import {
  OPEN_PREVIEW,
  CLOSE_PREVIEW,
  TOGGLE_PREVIEW,
  UPDATE_PREVIEW_DATA,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  UPDATE_USER_LOCATION,
  TOGGLE_FILTER,
  FILTER_PINS,
  FILTER_CHECKBOX,
  TOGGLE_LOG_OUT_FLAG,
  TOGGLE_SEARCH,
  CHANGE_FLAGS,
  CENTER_MAP,
  HIDE_SEARCH,
  SHOW_SEARCH,
  OPEN_EVENTS,
  CLOSE_EVENTS,
  SET_MAP_ZOOM,
  SET_LOADING,
  SAVE_EVENTS,
  SAVE_CITY_DEFAULT_IMAGE,
  OPEN_EVENT_DRAWER,
  CLOSE_EVENT_DRAWER,
  SAVE_EVENTS_DEFAULT_IMAGE,
  SAVE_FILTER_MASK,
  SAVE_ACTIVITIES,
} from './actionTypes';

export function openPreview() {
  return {
    type: OPEN_PREVIEW,
  };
}

export function closePreview() {
  return {
    type: CLOSE_PREVIEW,
  };
}

export function togglePreview(previewStatus) {
  return {
    type: TOGGLE_PREVIEW,
    previewStatus,
  };
}

export function updatePreviewData(attractive) {
  return {
    type: UPDATE_PREVIEW_DATA,
    attractive,
  };
}

export function openDrawer(attractive) {
  return {
    type: OPEN_DRAWER,
    attractive,
  };
}

export function closeDrawer() {
  return {
    type: CLOSE_DRAWER,
  };
}

export function updateUserLocation(lat, long) {
  return {
    type: UPDATE_USER_LOCATION,
    lat,
    long,
  };
}

export function toggleFilter() {
  return {
    type: TOGGLE_FILTER,
  };
}

export function filterPins(pins) {
  return {
    type: FILTER_PINS,
    pins,
  };
}

export function filterCheckbox(options) {
  return {
    type: FILTER_CHECKBOX,
    options,
  };
}

export function openEvents() {
  return {
    type: OPEN_EVENTS,
  };
}

export function closeEvents() {
  return {
    type: CLOSE_EVENTS,
  };
}

export function toggleLogOutFlag() {
  return {
    type: TOGGLE_LOG_OUT_FLAG,
  };
}

export function toggleSearch(open, results, value) {
  return {
    type: TOGGLE_SEARCH,
    results,
    open,
    value,
  };
}

export function hideSearch() {
  return {
    type: HIDE_SEARCH,
  };
}

export function showSearch() {
  return {
    type: SHOW_SEARCH,
  };
}

export function changeFlags(flags) {
  return {
    type: CHANGE_FLAGS,
    flags,
  };
}

export function centerMap(location) {
  return {
    type: CENTER_MAP,
    location,
  };
}

export function setMapZoom(zoom) {
  return {
    type: SET_MAP_ZOOM,
    zoom,
  };
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    loading,
  };
}

export function saveEvents(events) {
  return {
    type: SAVE_EVENTS,
    events,
  };
}

export function saveCityDefautImages(city, urls) {
  return {
    type: SAVE_CITY_DEFAULT_IMAGE,
    city,
    urls,
  };
}

export function saveEventsDefaultImage(city, urls) {
  return {
    type: SAVE_EVENTS_DEFAULT_IMAGE,
    city,
    urls,
  };
}

export function openEventDrawer(attractive) {
  return {
    type: OPEN_EVENT_DRAWER,
    attractive,
  };
}

export function closeEventDrawer() {
  return {
    type: CLOSE_EVENT_DRAWER,
  };
}

export function saveFilterMask(mask) {
  return {
    type: SAVE_FILTER_MASK,
    mask,
  };
}

export function saveActivies(activities) {
  return {
    type: SAVE_ACTIVITIES,
    activities,
  };
}
