export const fieldsEditRoutes = [
    {    
      title: 'Titulo*',
      field: 'Title',
      type: 'text',
      boolType: false,
      component: 'text',
      placeholder: '',
      explanation: 'Coloque aqui o nome da rota, com letras maiúsculas e minúsculas.',
    },
    {    
      title: 'Tipo*',
      field: 'Tipo',
      boolType: false,
      component: 'select',
    },
    {    
      title: 'Cidade',
      field: 'City',
      boolType: false,
      component: 'select',
      disable: true,
    },
    {    
      title: 'Cidades',
      field: 'Cities',
      explanation: 'Selecione as cidades por onde a rota passa',
      component: 'selectMultiple',
    },
    {    
      title: 'Geolocalização',
      field: 'location',
      boolType: false,
      component: 'location',
      placeholder: '[ Latitude, Longitude ]',
      explanation: 'Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444',
    },
    {    
      title: 'Lista de Imagens',
      field: 'Fotos',
      type: 'file',
      boolType: false,
      component: 'image',
      explanation: 'Tamanho máximo da imagem: 1MB (MegaBytes).',
    },
    {    
      title: 'Descrição Curta*',
      field: 'Short Descr',
      type: 'textarea',
      boolType: false,
      component: 'textarea',
      placeholder: '',
      explanation: 'Uma breve descrição sobre a rota.',
    },
    {    
      title: 'Descrição Longa*',
      field: 'Long Descr',
      type: 'textarea',
      boolType: false,
      component: 'textarea',
      placeholder: '',
      explanation: 'Uma descrição mais detalhada sobre a rota.',
    },
  ];