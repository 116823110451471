import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Input } from 'reactstrap';
import SingleSelect from './CitiesSelector';

const renderData = (props) => {
  if (!props.editing) {
    // Verifica se o campo tem um dado a ser mostrado
    if (props.data === undefined || props.data === '')
      return (
        <p className={css(styles.emptyField)}>{props.fieldName}</p>
      );
    return <p className={css(styles.nonEmptyField)}>{props.data}</p>;
  }
  if (props.name !== 'city') {
    return (
      <Input
        onClick={props.onClick}
        className={css(styles.input)}
        type='text'
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    );
  }
  if (!props.isAdmin) {
    return (
      <SingleSelect
        className={css(styles.input)}
        value={props.data}
        placeholder={props.data}
        onChange={props.onChange}
      />
    );
  } 
  return <p style={{ marginBottom: '0px', paddingRight: '1vw' }}>{props.data}</p>;
};

const ProfileField = (props) => {
  if (!props.isMobile) {
    const size = props.data.length * 9.5; 

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.name)}>
          <p style={{ height: '45px', marginBottom: '0px', paddingRight: '1vw' }}>{props.fieldName}</p>
        </div>
        <div className={css(styles.data)}>
          {renderData(props)}
          <div className={css(styles.bottomLine)} style={{ width: size }}/>
        </div>
      </div>
    );
  }
  return (
    <div className={css(styles.containerMobile)}>
      <p style={{ marginBottom: '0px', fontSize: 'small' }}>{props.fieldName}</p>
      <div style={{ height: '45px' }}>
        {renderData(props)}
        <div className={css(styles.bottomLine)}/>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  bottomLine: {
    borderBottomColor: 'rgba(128, 128, 128, 0.7)',
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    marginBottom: '40px',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  container: {
    // textAlign: 'right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
    maxWidth: '100vw',
    width: '80%',
  },
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  data: {
    width: '50%',
    height: '45px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  emptyField: {
    marginBottom: '0px',
    paddingRight: '1vw',
    color: 'grey',
    fontStyle: 'italic',
  },
  input: {
    color: 'grey',
    marginBottom: '0px',
    height: '45px',
    width: '60%',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  name: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  nonEmptyField: {
    marginBottom: '0px',
    paddingRight: '2vw',
    color: 'grey',
  },
});

export default ProfileField;