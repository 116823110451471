import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';

// Css import
import '../../assets/css/EditComponents/EditInventory.css';

// Components Imports
import VerifyPopup from '../VerifyPopup';
import Loading from '../Loading';
import { handleStringFormat } from '../../utils/limitStr';
import HeaderForm from '../FormComponents/HeaderForm';
import ButtonForm from '../FormComponents/ButtonForm';
import TitleEditor from '../FormComponents/TitleEditor';
import { fieldsEditCities } from '../FieldsForm/cities.fields';
import { inputsComponents } from '../../utils/formManager';

export default function EditCities({ setEditPopup, doc, action, pendency, objectPend, setObjectPend, setNumPendency, role }) {
  const [popup, setPopup] = useState(false);
  const [file, setFile] = useState();
  const [cityImage, setCityImage] = useState({ url: '', image: '', name: '' });
  const [eventImage, setEventImage] = useState({ url: '', image: '', name: '' });
  const [pinImage, setPinImage] = useState({ url: '', image: '', name: '' });
  const [item, setItem] = useState(handleItem());
  const typesImages = {
    cityImage: cityImage,
    eventImage: eventImage,
    pinImage: pinImage,
  };
  const typesSetImages = {
    cityImage: setCityImage,
    eventImage: setEventImage,
    pinImage: setPinImage,
  };
  const rolesControl = ['admin', 'masterAdmin', 'contentManager', 'contentEditor'];

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;
      
    return doc ? doc.data() : {
      name: '',
      shortDescr: '',
      longDescr: '',
      neighborhood: [],
      desktopDefaultZoom: 10,
      mobileDefaultZoom: 11.2,
      location: null,
      Active: true,
      original: {
        shortDescr: '',
        longDescr: '',
      },
    };
  }

  const handleEditCity = async () => {
    try {
      const docData = await handleUploadFiles();
      const pendency = {
        action: 'Editar',
        city: item.name,
        createDate: new Date(),
        currDoc: docData,
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleAddCity = async () => {
    try {
      const docData = await handleUploadFiles();
      const pendency = {
        action: 'Criar',
        city: item.name,
        createDate: new Date(),
        currDoc: docData,
        lastDoc: {},
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: '',
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function saveImage(value, field, aux) {
    const ref = firebase.storage().ref(`cities/${item.name}/${value.name}`);
    await ref.put(value.image);

    const url = await ref.getDownloadURL(); 

    const newItem = { ...aux, [field]: url };
    return newItem;
  }

  async function savePinImage(value, field, aux) {
    const ref = firebase.storage().ref(`cities-pins/${value.name}`);
    await ref.put(value.image);

    const url = await ref.getDownloadURL(); 

    const newItem = { ...aux, [field]: url };
    return newItem;
  }

  async function handleKMLFile(file) {
    const ref = firebase.storage().ref(`cities-kml/${file.name}`);
    await ref.put(file);

    const url = await ref.getDownloadURL();

    return url;
  }

  async function handleUploadFiles() {
    let aux = item;
    if (cityImage.image !== '')
      aux = await saveImage(cityImage, 'defaultImage', aux);
    if (eventImage.image !== '')
      aux = await saveImage(eventImage, 'defaultEventImage', aux);
    if (pinImage.image !== '')
      aux = await savePinImage(pinImage, 'ImagemPin', aux);

    if (file)
      aux = { ...aux, KMLFile: await handleKMLFile(file) };

    return aux;
  }

  async function handleEditPendency() {
    const newPendency = { ...objectPend, currDoc: item };
    setObjectPend(newPendency);
    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  function handleCheckFields() {
    const keys = ['name', 'shortDescr', 'longDescr', 'location'];

    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0 || item[keys[i]] === null)) {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }

    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  useEffect(() => {
    if (item.name)
      setItem({ ...item, nameOrdem: handleStringFormat(item.name) });
  }, [item.name]);

  if (!cityImage) {
    return <Loading />;
  }

  return (
    <div className='EditInventory'>
        <div className='bodyEditInventory'>
          <TitleEditor action={action} type={'Cidade'} />
          <div>
            <form className='form_edit_admin'>
              <HeaderForm />
              {
                fieldsEditCities.map((field) => {
                  if (!['DataExpiracaoContrato', 'Contratada'].includes(field) || rolesControl.includes(role))
                    return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: false, image: typesImages[field.typeImage], setImage: typesSetImages[field.typeImage], styleType: field.styleType, setFile: setFile, objectPend: objectPend, setObjectPend: setObjectPend, rankingPoints: !!field.rankingPoints });
                })
              }
              { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditCity : handleAddCity} title={`Deseja ${action} esta Cidade?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
              <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
            </form>
          </div>
        </div>
    </div>
  );
}
