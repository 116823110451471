import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { IoMdLocate } from 'react-icons/io';

class MapCenterButton extends React.Component {
  render() {
    return (
      <div className={css(styles.background)}>
        <IoMdLocate className={css(styles.icon)}/>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    backgroundColor: 'white',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40,
    borderRadius: 25,
  },
  icon: {
    height: 25,
    width: 25,
    color: '#017AD6',
  },
});

export default MapCenterButton;
