const fieldsForm = {
  NomeExperiencia: 'Nome da Experiência',
  SegmentosTuristicos: 'Segmentos Turísticos',
  ItensTuristicos: 'Itens Turísticos',
  FrequenciaExperiencia: 'Frequência da Experiência',
  AdequadaLGBT: 'Experiência adequada a LGBT',
  AdequadaAcessibilidade: 'Experiência adequada a pessoas com deficiência ou mobilidade reduzida',
  AdequadaIdoso: 'Experîencia adequada a pessoas de idade +60',
  AdequadaCrianca: 'Experiência adequada a crianças e jovens',
  RoteiroPertencente: 'Experiência pertence a algum roteiro',
  RecebeEstrangeiro: 'Experiência recebe estrangeiros',
  Idiomas: 'Idiomas',
  ContratarAtendimentoIdioma: 'Disponibilidade de contratar algum profissional especializado',
  ExemploAtendimentoOutroIdioma: 'Exemplos de como a experiência é oferecida em outros idiomas',
  TipoExperiencia: 'Experiência sustentável ou responsável',
  DesenvolvimentoSustentavel: 'Objetivos de Desenvolvimento Sustentável',
  Sustentabilidade: 'Links/exemplos de sustentabilidade na experiência',
  ExperienciaInternacional: 'Experiência promovida internacionalmente',
  ComercioInternacional: 'Possui uma comercialização internacional',
  AutorizacaoImagem: 'Autorização de uso de imagem',
  Premiacao: 'Certificação da experiência',
  city: 'Cidade',
  country: 'País',
  state: 'Estado',
};

export function checkFields(obj, fields, setErrorMessage, next, uid, company, index, setEditExperiences, setCompanyInfos, setExpPopup, setIsLoading) {
  try {
    // Checking if all required field aren't empty
    fields.forEach((f) => {
      if (!obj[f] || textIsEmpty(obj[f]) || objetcIsEmpty(obj[f]) || languageField(obj[f], f))
        throw new Error(`Preencha todos os campos obrigatórios. Campo: ${fieldsForm[f]}`);
    });
    
    setErrorMessage('');
    document.getElementById('handle-set-doc-button').disabled = true;
    setIsLoading(true);
    next(company, obj, uid, index, setEditExperiences, setCompanyInfos, setExpPopup);
  } catch (error) {
    setErrorMessage(error.message);
  }
}

function objetcIsEmpty(obj) {
  if (typeof obj !== 'object') return false;

  const fields = Object.keys(obj);

  return fields.length === 0;
}

function textIsEmpty(text) {
  if (typeof text !== 'string') return false;

  return text === '';
}

function languageField(obj, field) {
  if (field !== 'Idiomas') return false;

  const fields = Object.keys(obj);

  return fields.length !== 5;
}