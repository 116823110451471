import React from "react";

export function TextareaField({ title, fieldForm, placeholder, explanation, value, item, setItem, disabled, ...props }) {
  function handleChange(text) {
    if (text.length <= 500)
      setItem({ ...item, [fieldForm]: text });
  }

  return (
    <div className="field-textarea-edit" {...props}>
      <label htmlFor={fieldForm}>{title}</label>
      { explanation }
      <textarea className="field-edit" id={fieldForm} placeholder={placeholder} value={value} onChange={(e) => handleChange(e.target.value)} disabled={disabled} />
    </div>
  );
}
