/* eslint-disable no-lonely-if */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';

// React-router-dom import
import { Route, Switch, Link, useHistory } from 'react-router-dom';

// Components imports
import { SidebarData } from './SidebarData';
import InventoryAdmin from '../pages/admin/InventoryAdmin';
import EventsAdmin from '../pages/admin/EventsAdmin';
import CitiesAdmin from '../pages/admin/CitiesAdmin';
import RegionsAdmin from '../pages/admin/RegionsAdmin';
import PendentAdmin from '../pages/admin/PendentAdmin';
import HeaderAdmin from './HeaderAdmin';
import DashboardAdmin from '../pages/admin/DashboardAdmin';
import GuidelineAdmin from '../pages/admin/GuidelineAdmin';
import ProgramAdmin from '../pages/admin/ProgramAdmin';
import ProjectAdmin from '../pages/admin/ProjectAdmin';
import HandleReportAdmin from '../pages/admin/HandleReportAdmin';
import UsersAdmin from '../pages/admin/UsersAdmin';
import ComponentCounter from './ComponentCounter';
import RankingCities from '../pages/admin/RankingCities';
import RankingCalculator from '../pages/admin/RankingCalculator';
import DemandAdmin from '../pages/admin/DemandAdmin';
import Experiences from '../pages/cae/Experiences';
import { RodapeAdmin } from './RodapeAdmin';
import RoutesAdmin from '../pages/admin/RoutesAdmin';
// Firebase import
import firebase from '../config/Firebase';

// Assets import
import '../assets/css/sidebarAdmin.css';
import Logo from '../assets/imgs/default.png';
import ArrowDown from '../assets/imgs/arrow_down.svg';
import CorrectionsAdmin from '../pages/admin/CorrectionsAdmin';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

export const MyContext = React.createContext();

export default function SidebarAdmin() {
  const [name, setName] = useState('');
  const [kitMedia, setKitMedia] = useState('');
  const [city, setCity] = useState();
  const [numPendency, setNumPendency] = useState(0);
  const [numCorretions, setNumCorrections] = useState(0);
  const [neighborhood, setNeighborhood] = useState([]);
  const [role, setRole] = useState();
  const [cityAdmin, setCityAdmin] = useState();
  const [tradeAdmin, setTradeAdmin] = useState();
  const [viewSublinks, setViewSublinks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusNavbar, setStatusNavbar] = useState(true);
  const [tasks, setTasks] = useState(SidebarData);
  const history = useHistory();
  const typeUsers = {
    masterAdmin: [],
    admin: ['USUÁRIOS'],
    tradeAdmin: ['DASHBOARDS', 'CIDADES', 'REGIÕES', 'PLANO DE TURISMO', 'RELATÓRIOS', 'PENDÊNCIAS', 'TAXONOMIA', 'ESTUDO DE DEMANDA', 'ROTEIROS', 'VOUCHER ELETRÔNICO', 'USUÁRIOS', 'CORREÇÕES', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'CALCULADORA DO RANQUEAMENTO', 'EXPERIÊNCIAS'],
    cityAdmin: ['REGIÕES', 'TAXONOMIA', 'PENDÊNCIAS', 'USUÁRIOS', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'EXPERIÊNCIAS'],
    regionAdmin: ['TAXONOMIA', 'PENDÊNCIAS', 'USUÁRIOS', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'CALCULADORA DO RANQUEAMENTO', 'ESTUDO DE DEMANDA', 'EXPERIÊNCIAS'],
    contentManager: ['CONTRATOS', 'EXPERIÊNCIAS'],
    contentEditor: ['USUÁRIOS', 'ROTAS', 'CONTRATOS', 'CALCULADORA DO RANQUEAMENTO', 'ESTUDO DE DEMANDA'],
    dashboardViewer: ['INVENTÁRIO', 'ROTAS', 'EVENTOS', 'CIDADES', 'REGIÕES', 'PENDÊNCIAS', 'TAXONOMIA', 'ESTUDO DE DEMANDA', 'PLANO DE TURISMO', 'ROTAS', 'VOUCHER ELETRÔNICO', 'RELATÓRIOS', 'USUÁRIOS', 'CORREÇÕES', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'CALCULADORA DO RANQUEAMENTO', 'EXPERIÊNCIAS'],
    rotaAdmin: ['DASHBOARDS', 'ROTAS', 'INVENTÁRIO', 'EVENTOS', 'CIDADES', 'REGIÕES', 'PENDÊNCIAS', 'TAXONOMIA', 'ESTUDO DE DEMANDA', 'PLANO DE TURISMO', 'VOUCHER ELETRÔNICO', 'RELATÓRIOS', 'USUÁRIOS', 'CORREÇÕES', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'CALCULADORA DO RANQUEAMENTO', 'EXPERIÊNCIAS'],
    cae: ['DASHBOARDS', 'INVENTÁRIO', 'EVENTOS', 'CIDADES', 'REGIÕES', 'PENDÊNCIAS', 'TAXONOMIA', 'ESTUDO DE DEMANDA', 'PLANO DE TURISMO', 'ROTEIROS', 'VOUCHER ELETRÔNICO', 'RELATÓRIOS', 'USUÁRIOS', 'CORREÇÕES', 'CONTRATOS', 'ENVIAR NOTIFICAÇÕES', 'CALCULADORA DO RANQUEAMENTO'],
  };
  const count = {
    PENDÊNCIAS: numPendency,
    CORREÇÕES: numCorretions,
  };

  const rolesControl = ['masterAdmin', 'admin'];

  async function handleNumCounts(role) {
    if (!typeUsers[role].includes('PENDÊNCIAS')) {
      const docsPend = await firebase.firestore().collection('Pendencies').get();
      setNumPendency(docsPend.docs.length);
    }

    if (!typeUsers[role].includes('CORREÇÕES')) {
      const docsCorr = await firebase.firestore().collection('Feedbacks').get();
      setNumCorrections(docsCorr.docs.length);
    }
  }

  const getUserInfo = async () => {
    try {
      let userID = '';
      if (firebase.auth().currentUser !== null)
        userID = firebase.auth().currentUser.uid;
      else
        throw Error('Usuário não encontrado!');

      const userDoc = await firebase.firestore().collection('users').doc(userID).get();
      setName(userDoc.data().name || undefined);
      setCity(userDoc.data().city || undefined);
      setRole(userDoc.data().role || undefined);
      setCityAdmin(userDoc.data().cityAdmin || undefined);
      setTradeAdmin(userDoc.data().tradeAdmin || undefined);

      handleNumCounts(userDoc.data().role, userDoc.data().city);
    } catch (e) {
      history.push('/adm');
    }
  };

  function handleSubLinks(obj) {
    if (!obj.subLinks) return;

    // const widthNavbar = document.getElementById('div_align_sidebar').clientWidth;

    if (!viewSublinks) {
      const subRows = document.getElementsByClassName('rowSidebarSubItem');

      const heightRow = obj.subLinks.length * subRows[0].clientHeight;
      document.getElementById(obj.title + 'SubList').style = `height: ${heightRow}px;`;
      document.getElementById(obj.title + 'ArrowImg').style = 'transform: rotate(0);';
    } else {
      document.getElementById(obj.title + 'SubList').style = 'height: 0;';
      document.getElementById(obj.title + 'ArrowImg').style = 'transform: rotate(-90deg);';
    }

    setViewSublinks(!viewSublinks);
  }

  function handlePath() {
    if (role === 'cae')
      return;
    
    if (role !== 'tradeAdmin')
      history.push('/admin/dashboards');
    else
      history.push('/admin/inventario');
  }

  async function handleGetKitMedia() {
    const docs = await firebase.firestore().collection('Cities').where('name', '==', city).get();

    setKitMedia(docs.docs[0].data().KitMedia || '');
  }

  useEffect(() => {
    setIsLoading(true);
    let newTasks = tasks.filter((task) => task.title !== 'KIT MEDIA');
  
    if (kitMedia !== '') {
      newTasks = [...newTasks, {
        title: 'KIT MEDIA',
        icon: <InsertLinkIcon />,
        link: 'media',
        linkMedia: kitMedia,
      }];
    }

    setTasks(newTasks);
    setIsLoading(false);
  }, [kitMedia]);

  useEffect(() => {
    if (city)
      handleGetKitMedia();
  }, [city]);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (viewSublinks) { 
      const sublists = document.getElementsByClassName('SidebarSubList');
      const arrows = document.getElementsByClassName('arrow_down_image');

      for (let i = 0; i < sublists.length; i++)
        sublists[i].style = 'height: 0;';
      
      for (let j = 0; j < arrows.length; j++)
        arrows[j].style = 'transform: rotate(-90deg);';

      setViewSublinks(false);
    }
  }, [statusNavbar]);

  useEffect(() => {
    if (role)
      handlePath();
  }, [role, cityAdmin, tradeAdmin]);

  if (!role || numPendency === undefined || isLoading) {
    return <></>;
  }

  return (
    <div className='SidebarContainer' id='sidebarContainer'>
      <div className='div_align_sidebar' id='div_align_sidebar'>
        <div className='Sidebar' id='sideBar'>
          <div className='SidebarHeader' id='sidebarHeader'>
            <div className='SidebarImage' id='sidebarImage'>
              <img src={Logo} alt='Logo' className='logoHeader' id='logoHeader'></img>
            </div>
            <div className='SidebarName' id='sidebarName'>
              <div className='SidebarUserCity'>{city}</div>
              <div className='SidebarUserName'>{name}</div>
            </div>
          </div>
          <ul className='SidebarList'>
            {tasks.map((val, index) => {
              if (!typeUsers[role].includes(val.title)) {
                return (
                  <div id={val.title + 'divRowSidebar'} key={'divRowSidebar' + index}>
                    {
                      val.linkMedia ? (
                        <a href={val.linkMedia} target="_blank" rel="noopener noreferrer">
                          <li
                          className='rowSidebarItem'
                          id={window.location.pathname === val.link ? 'active' : ''}
                          onClick={() => handleSubLinks(val)}
                          >
                            <div id='icon' className='iconSidebar'>{val.icon}</div> 
                            <div id='title' className='titleSidebar'>{(Object.keys(count).includes(val.title) && count[val.title] > 0) ? <ComponentCounter numCounter={count[val.title]} title={val.title} /> : val.title}</div>
                          {
                            val.subLinks ? <div id='img_arrow' className='titleSidebar'><img src={ArrowDown} className='arrow_down_image' id={val.title + 'ArrowImg'} /></div> : null
                          }
                          </li>
                        </a>
                      ) : (
                        <>
                          <Link to={val.link} style={{ textDecoration: 'none', pointerEvents: !val.type ? '' : 'none' }} key={index + 'Link'} >
                            <li
                              className='rowSidebarItem'
                              id={window.location.pathname === val.link ? 'active' : ''}
                              onClick={() => handleSubLinks(val)}
                            >
                              <div id='icon' className='iconSidebar'>{val.icon}</div> 
                              <div id='title' className='titleSidebar'>{(Object.keys(count).includes(val.title) && count[val.title] > 0) ? <ComponentCounter numCounter={count[val.title]} title={val.title} /> : val.title}</div>
                              {
                                val.subLinks ? <div id='img_arrow' className='titleSidebar'><img src={ArrowDown} className='arrow_down_image' id={val.title + 'ArrowImg'} /></div> : null
                              }
                            </li>
                          </Link>
                          <ul id={val.title + 'SubList'} className='SidebarSubList'>
                            {
                              val.subLinks ? val.subLinks.map((obj, index) => {
                                if (obj.title !== 'Ranking Geral' || rolesControl.includes(role)) {
                                  return (
                                    <Link to={obj.link} style={{ textDecoration: 'none', pointerEvents: !obj.type ? '' : 'none' }} key={index + obj.link} >
                                      <li
                                        disable={'true'}
                                        className='rowSidebarSubItem'
                                        id={window.location.pathname === val.link ? 'active' : ''}
                                      >
                                        <div id='title' className='titleSidebarSubItem'>{obj.title}</div>
                                      </li>
                                    </Link>
                                  );
                                }
                              }) : null
                            }
                          </ul>
                        </>
                      )
                    }
                  </div>
                );
              }
            })}
          </ul>
        </div>
      </div>
      <div className='SideBarBody' id='SideBarBody'>
        <div className='HeaderAdminPage' id='HeaderAdminPage'>
          <MyContext.Provider value={{ city, setCity, role, cityAdmin, setNeighborhood }}>
            <HeaderAdmin statusNavbar={statusNavbar} setStatusNavbar={setStatusNavbar} />
          </MyContext.Provider>
        </div>
        <div className='SidebarPages'>
          <Switch>
            <MyContext.Provider value={{ city, role, cityAdmin, tradeAdmin, neighborhood, setNumPendency, setNumCorrections, numCorretions }}>
              <Route path='/admin/dashboards' component={(DashboardAdmin)} />
              <Route path='/admin/inventario' component={(InventoryAdmin)} />
              <Route path='/admin/eventos' component={(EventsAdmin)} />
              <Route path='/admin/roteiros' component={(RoutesAdmin)} />
              <Route path='/admin/cidades' component={(CitiesAdmin)} />
              <Route path='/admin/regioes' component={(RegionsAdmin)} />
              <Route path='/admin/planoDiretor/diretrizes' component={(GuidelineAdmin)} />
              <Route path='/admin/planoDiretor/programas' component={(ProgramAdmin)} />
              <Route path='/admin/planoDiretor/projetos' component={(ProjectAdmin)} />
              <Route path='/admin/relatorios/:id' component={HandleReportAdmin} />
              <Route path='/admin/pendencias' component={(PendentAdmin)} />
              <Route path='/admin/usuarios' component={UsersAdmin}/>
              <Route path='/admin/correcoes' component={CorrectionsAdmin}/>
              <Route path='/admin/calculadora/ranking' component={RankingCities}/>
              <Route path='/admin/calculadora/avaliacao' component={RankingCalculator}/>
              <Route path='/admin/estudodemanda' component={DemandAdmin}/>
            </MyContext.Provider>
          </Switch>
          <RodapeAdmin />
        </div>
      </div>
    </div>
  );
}