import React, { useState, useEffect } from 'react';

// CSS Import
import '../../assets/css/InputComponents/textInput.css';

import CalculatorIcon from '../../assets/imgs/pins/ranking_icon.png';

// Firebase import
import firebase from '../../config/Firebase';

export default function TextInput({ title, field, type, value, setValue, placeholderText, link, editType, explanation, rankingPoints }) {
  const [error, setError] = useState('');

  function handleCheckLink(fieldLink) {
    if (!value[fieldLink]) return;

    if (!(value[fieldLink].includes('http://') || value[fieldLink].includes('https://'))) {
      const newLink = 'https://' + value[fieldLink];
      setValue({ ...value, [fieldLink]: newLink });
    }
  }

  async function handleCheckTitle() {
    if (editType) {
      const docs = await firebase.firestore().collection('Attractions')
        .where('City', '==', value.City)
        .where('Title', '==', value.Title)
        .get();
        
      if (docs.docs.length > 0) {
        if (error === '') {
          document.getElementById('TitleForm').focus();
          setError('Risco de duplicidade: Já existe um registro com esse nome.');
        }
      } else {
        setError('');
      }
    }
  }

  useEffect(() => {
    if (field === 'Title')
      handleCheckTitle();
    
    if (field !== 'Title' && value[field] === '')
      delete value[field];
  }, [value[field]]);

  return (
    <div className="TextInput">
      <div className='div_label_input_text'>
        {
          rankingPoints ? (
            <div className="div_align_img_ranking_points">
              <span>Este campo contribui para a Calculadora de Ranqueamento.</span>
              <img src={CalculatorIcon} alt="Ícone com um C, que representa que o campo contabiliza para a contagem na calculadora" />
            </div>
          ) : null
        }
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input'>
        <div className="div_out_input_text">
          <input type={type} id={field + 'Form'} onChange={(e) => setValue({ ...value, [field]: type === 'number' ? Number(e.target.value) : e.target.value })} placeholder={placeholderText || ''} value={value[field]} onBlur={link ? () => handleCheckLink(field) : null} />
        </div>
        <div className='div_explanation_input'>
          {
            error !== '' ? <p className='subtext_explanation_input error_message_text_input'>{error}</p>
              : <p className='subtext_explanation_input'>{explanation}</p>
          }
        </div>
      </div>
    </div>
  );
}