import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import '../../css/InventoryAdmin.css';

// Components Imports
import AtractionList from '../../component/ListComponents/AtractionList';
import EditInventory from '../../component/EditComponents/EditInventory';
import Loading from '../../component/Loading';
import VerifyPopup from '../../component/VerifyPopup';
import NoResultsComponent from '../../component/NoResultsComponent';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

export default function InventoryAdmin() {
  const [places, setPlaces] = useState();
  const [filter, setFilter] = useState('Title');
  const [editPopup, setEditPopup] = useState(false);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [search, setSearch] = useState('');
  const [action, setAction] = useState('Editar');
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, role, setNumPendency, tradeAdmin } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'Title' : filter;

    let docs;

    if (role !== 'tradeAdmin') {
      docs = firebase.firestore()
        .collection('Attractions')
        .orderBy(currFilter)
        .where('City', '==', city);
    } else {
      docs = firebase.firestore()
        .collection('Attractions')
        .orderBy(currFilter);
    }
    
    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery().get();

    let docs = newDocs;

    if (role === 'tradeAdmin') {
      if (tradeAdmin) {
        const filteredReq = newDocs.docs.filter((doc) => tradeAdmin.includes(doc.id));
        docs = { ...newDocs, docs: filteredReq };
      } else
        return;
    }

    setPlaces(docs);
    setFilteredPlaces(docs);
  };

  useEffect(() => {
    if (city)
      getFirstPage();
  }, [city, tradeAdmin, filter]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPage();
    }

    window.scrollTo(0, 0);
  }, [editPopup]);

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup(true);
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
  };

  const handleDeleteInventory = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Attractions',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      setNumPendency((num) => num + 1);
      getFirstPage();
    } catch (error) {
      setVerifyPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const searchInventoryText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().Title.toLowerCase().includes(search.toLowerCase());
    const isInShortDescription = doc.data()['Short Description']?.toLowerCase().includes(search.toLowerCase());
    return isInTitle || isInShortDescription;
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchInventoryText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  if (!places || !city) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin' id='InventarioAdmin'>
      { editPopup ? <EditInventory setEditPopup={setEditPopup} doc={selectedItem} action={action} userCity={city} setNumPendency={setNumPendency} role={role} /> : 
        (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Inventário</h1>
        </div>
        { !['tradeAdmin'].includes(role) ? <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup(true); }}>Criar Novo Registro</p>
        </div> : null }
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm' id='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('Title')}>Titulo</td>
                <td className='column_desc_table_inventory th_table_admin' onClick={() => setFilter('Short Description')} >Descrição Curta</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('City')} >Cidade</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Type')} >Tipo</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Subtype')} >Subtipo</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Visible')}>Visível</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Active')} >Status</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  return <AtractionList key={doc.id} doc={doc} handleEdit={handleEdit} handleDelete={handleDelete} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Registros'} search={search} /> }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} action={'Excluir'} handleAction={handleDeleteInventory} type={'Inventário'} doc={selectedItem} title={'Deseja excluir este Registro?'} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null} 
      </div>)}
    </div>
  );
}