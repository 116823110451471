import React, { useEffect } from 'react';
import firebase from '../config/Firebase';
import { css, StyleSheet } from 'aphrodite';
import printer from '../assets/imgs/printer.png';
import csv from '../assets/imgs/csv.png';
import IosSync from 'react-ionicons/lib/IosSync';
import { PageView } from '../config/TrackingGA';
import { CSVLink } from 'react-csv';

export default class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      pins: [],
      types: [],
      subTypes: [],
      loading: true,
      headers: [
        { label: 'Título', key: 'title' },
        { label: 'Descrição', key: 'shortDescription' },
        { label: 'Endereço', key: 'endereço' },
        { label: 'Cidade', key: 'city' },
        { label: 'Telefone', key: 'telefone' },
        { label: 'Cadastur', key: 'cadastur' },
        { label: 'Email', key: 'email' },
        { label: 'Número de funcionarios fixos', key: 'numerofuncionariosFixos' },
        { label: 'Número de funcionários temporários', key: 'numerofuncionariosTemp' },
        { label: 'UHs', key: 'uhs' },
        { label: 'Capacidade individual', key: 'capacidadeIndividual' },
        { label: 'Tipo', key: 'type' },
        { label: 'Subtipo', key: 'subType' },
      ],
      allPoints: [],
    };
  }

  getFilterMasks = () => {
    return new Promise((resolve) => {
      firebase.firestore().collection('config').doc('filtros')
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          }
        });
    });
  }

  retrieveData = (filters, userCity) => {
    return new Promise((resolve) => {
      const pinsData = [];
      firebase
        .firestore()
        .collection('Attractions')
        .where('City', '==', userCity)
        .where('Active', '==', true)
        .where('Visible', '==', true)
        .get()
        .then((docs) => {
          docs.forEach(async (data) => {
            await pinsData.push({
              title: data.data().Title,
              shortDescription: data.data()['Short Description'],
              endereço: data.data().Endereço,
              cidade: data.data().City,
              telefone: data.data().Telefone,
              email: data.data().Email,
              cadastur: data.data().Cadastur,
              numeroFuncionariosFixos: data.data()['Número de Funcionários Fixos'],
              numeroFuncionariosTemp: data.data()['Número de Funcionários Temporários'],
              uhs: data.data().UHS || data.data().UHs,
              capacidadeIndividual: data.data()['Capacidade Individual'],
              website: data.data().Website,
              type: data.data().Type,
              subType: data.data().Subtype,
            });
          });
          this.setState({
            allPoints: pinsData,
          });
          this.getFilterMasks()
            .then((filterMask) => {
              let mask = [];
              if (filters.alimentacao)
                mask = mask.concat(filterMask.alimentacao);
              if (filters.hospedagem)
                mask = mask.concat(filterMask.hospedagem);
              if (filters.atrativos)
                mask = mask.concat(filterMask.atrativos);
              if (filters.compras)
                mask = mask.concat(filterMask.compras);
              if (filters.infraEstrutura)
                mask = mask.concat(filterMask.infraEstrutura);
              if (filters.servicos)
                mask = mask.concat(filterMask.servicos);

              resolve(pinsData.filter((e) => mask.includes(e.type)));
            });
        });
    });
  }

  preparePins = (userCity) => {
    const filters = JSON.parse(this.props.location.search.substring(1).replace(/%22/g, '"'));

    this.retrieveData(filters, userCity).then((pins) => {
      const types = [...new Set(pins.map((pin) => pin.type))].sort(); // Todos os distintos types
      const subTypes = [];
      types.forEach((type) => {
        subTypes.push([...new Set(pins.filter((pin) => pin.type === type).map((pin) => pin.subType))].sort());
      });

      this.setState({
        pins: pins.sort((a, b) => (a.title > b.title ? 1 : -1)), // ordena os pins por ordem alfabética
        types: types,
        subTypes: subTypes,
        loading: false,
      }); 
    });
  }

  componentDidMount() {
    const id = firebase.auth().currentUser.uid;
    firebase.firestore().collection('users').doc(id).get()
      .then((doc) => {
        if (['admin', 'masterAdmin', 'cityAdmin', 'contentManager'].includes(doc.data().role)) {
          if (this.props.location.city)
            this.preparePins(this.props.location.city);
          else
            this.preparePins(doc.data().city);
        } else {
          alert('Apenas adminstradores têm acesso a essa página');
          this.props.history.push('/map');
        }
      });
    PageView();
  }

  renderAttraction = (data, k) => {
    const { title, shortDescription, endereço, cidade, website, telefone, cadastur, email, capacidadeIndividual, numeroFuncionariosFixos, numeroFuncionariosTemp, uhs } = data;
    const href = website && 'http://www.' + website.substr(website.indexOf('www.') + 4);
    return (
      <div className={css(styles.attraction)} key={k}>
        <p className={css(styles.title)}>
          {title}
        </p>
        <p className={css(styles.shortDescription)}>
          {shortDescription}
        </p>
        <p className={css(styles.field)}>
          Endereço: <span className={css(styles.value)}> {endereço}</span>
        </p>
        <p className={css(styles.field)}>
          Cidade: <span className={css(styles.value)}> {cidade}</span>
        </p>
        <p className={css(styles.field)}>
          Telefone: <span className={css(styles.value)}> {telefone}</span>
        </p>
        <p className={css(styles.field)}>
          Email: <span className={css(styles.value)}> {email}</span>
        </p>
        <p className={css(styles.field)}>
          Cadastur: <span className={css(styles.value)}> {cadastur}</span>
        </p>
        <p className={css(styles.field)}>
          Número de Funcionários Fixos: <span className={css(styles.value)}> {numeroFuncionariosFixos}</span>
        </p>
        <p className={css(styles.field)}>
          Número de Funcionários Temporários: <span className={css(styles.value)}> {numeroFuncionariosTemp}</span>
        </p>
        <p className={css(styles.field)}>
          UHs: <span className={css(styles.value)}> {uhs}</span>
        </p>
        <p className={css(styles.field)}>
          Capacidade Individual: <span className={css(styles.value)}> {capacidadeIndividual}</span>
        </p>
        <p className={css(styles.field, styles.noMarginBottom)}>
          Website: {
            website &&
              <a className={css(styles.value)} target="_blank" href={ href } rel="noopener noreferrer" >
                {website}
              </a>
          }
        </p>
      </div>
    );
  }

  // Função que altera o estado do menu do usuário
  toggleUserMenu = () => {
    this.setState({
      userMenuStatus: !this.state.userMenuStatus,
    });
  }

  handlePrint = () => {
    // Retirando os elementos para o print na tela de Relatórios de Admins
    if (this.props.location.admin) {
      document.getElementById('div_align_sidebar').style = 'display: none; width: 0';
      document.getElementById('SideBarBody').style = 'width: 100%;';
      document.getElementById('HeaderAdminPage').style = 'display: none; heigth: 0';
      document.getElementById('div_title_report_adm').style = 'display: none; heigth: 0';
      document.getElementById('RodapeAdmin').style = 'display: none; heigth: 0';
    }

    window.print();

    // Adicionando os elementos novamente
    if (this.props.location.admin) {
      document.getElementById('div_align_sidebar').style = 'display: flex;';
      document.getElementById('SideBarBody').style = 'width: 82%;';
      document.getElementById('HeaderAdminPage').style = 'display: flex;';
      document.getElementById('div_title_report_adm').style = 'display: flex;';
      document.getElementById('RodapeAdmin').style = 'display: flex;';
    }
  }

  render() {
    const { pins, types, subTypes, loading } = this.state;
    const typesTranslate = {
      Attraction: 'Atrativo',
      Lodging: 'Meio de Hospedagem',
      Food: 'Meio de Alimentação',
      Commerce: 'Comércio',
      Services: 'Serviço',
      Infrastructure: 'Infraestrutura',
    };

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.content)}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={css(styles.pageTitle)}>
              <span>Destinos Inteligentes</span>
              <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Relatório de Registros</p>
            </div>
            <div style={{ float: 'right' }}>
              <img alt='' onClick={this.handlePrint} className={css(styles.image)} src={printer} />
              <CSVLink data={pins} headers={this.state.headers} filename={'DI_Data.csv'}>
                <img alt='' className={css(styles.image)} style={{ marginLeft: '2vw', marginTop: '1vh' }} src={csv} />
              </CSVLink>
            </div>
          </div>
          {
            loading ?
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IosSync color='black' rotate className={`icon m-3 ${css(styles.loadingImage)} `} />
              </div>
              :
              <div style={{ wordBreak: 'break-all' }} >
                {
                  types.map((type, i) => (
                    <div className={css(i > 0 && styles.pageBreakOnPrint)} key={i}>
                      <p className={css(styles.type)}>{`${typesTranslate[type] || type} (${pins.filter((pin) => pin.type === type).length})`}</p>
                      {
                        subTypes[i].map((subType, j) => (
                          <div key={types.length + j}>
                            <p className={css(styles.subType)}>{`${subType} (${pins.filter((pin) => pin.subType === subType).length})`}</p>
                            {
                              pins.filter((pin) => pin.subType === subType).map((pin, k) => (
                                this.renderAttraction(pin, types.length + subTypes.length + k)
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  attraction: {
    marginLeft: '35px',
    borderBottom: 'grey 1px solid',
    marginBottom: '1rem',
  },
  container: {
    height: 'auto',
  },
  content: {
    marginTop: '1rem',
    width: '80%',
    margin: 'auto',
  },
  field: {
    fontWeight: 'bold',
    marginBottom: '0px',
  },
  image: {
    cursor: 'pointer',
    width: '15vh',
    '@media print': {
      display: 'none',
    },
  },
  loadingImage: {
    width: '15vh',
    height: '15vh',
    borderRadius: '50%',
  },
  noMarginBottom: {
    marginBottom: '1rem',
  },
  pageBreakOnPrint: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  pageTitle: {
    textAlign: 'left',
    marginBottom: 'auto',
    marginTop: 'auto',
    fontSize: '1.4rem',
    '@media print': {
      margin: '10px auto auto auto',
    },
  },
  subType: {
    paddingLeft: '20px',
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '0px',
  },
  type: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  value: {
    fontWeight: 'normal',
    color: 'grey',
    textDecoration: 'none',
  },
});
