import React, { useContext, useEffect, useState } from 'react';

// Firebase Import
import firebase from '../../config/Firebase';

// Assets imports
import HandleSize from '../../assets/imgs/handle_size_icon.JPG';
import GoBackIcon from '../../assets/imgs/goback_icon.svg';
import CardScoreCalculator from '../../component/CardScoreCalculator';
import CardReportCalculator from '../../component/CardReportCalculator';
import { RodapeAdmin } from '../../component/RodapeAdmin';
import AwardIcon from '../../assets/imgs/award_icon.JPG';
import MaxPontuationIcon from '../../assets/imgs/max_pontuation_icon.svg';
import SeturPontuationIcon from '../../assets/imgs/setur_pontuation_icon.svg';
import { handleAttractionScores, handleCityScore, handleComturScore, handleDemandScore } from '../../utils/rankingManager';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// CSS import
import '../../assets/css/RankingComponents/rankingCalculator.css';

// Components imports
import Loading from '../../component/Loading';
import TitleEditor from '../../component/FormComponents/TitleEditor';
import SpinButton from '../../component/SpinButton';

export default function RankingCalculator({ cityManager, setRankingPopup }) {
  const { city } = useContext(MyContext);
  const [dataRankSum, setDataSum] = useState({
    attr: {
      curr: undefined,
      max: undefined,
    },
    city: {
      curr: undefined,
      max: undefined,
    },
    demand: {
      curr: undefined,
      max: undefined,
    },
    comtur: {
      curr: undefined,
      max: undefined,
    },
  });
  const [attracScore, setAttracScore] = useState();
  const [cityScore, setCityScore] = useState();
  const [demandScore, setDemandScore] = useState();
  const [comturScore, setComturScore] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [pointSetur, setPointSetur] = useState();
  const [reportData, setReportData] = useState();
  const [deleteCards, setDeleteCards] = useState([]);
  const [showDeleteCards, setShowDeleteCards] = useState(false);
  const [citySelected, setCitySelected] = useState(cityManager || city);

  async function handleInfo(dataCity) {
    const attrData = await handleAttractionScores(dataCity.name);
    const cityData = await handleCityScore(dataCity.name);
    const demandData = await handleDemandScore(dataCity.name);
    const comturData = await handleComturScore(dataCity.name);

    let sum = 0;
    let max = 0;
    const dataSum = {
      attr: {
        curr: 0,
        max: 0,
      },
      city: {
        curr: 0,
        max: 0,
      },
      demand: {
        curr: 0,
        max: 0,
      },
      comtur: {
        curr: 0,
        max: 0,
      },
    };
    const reports = [];

    attrData.map((attr) => {
      max += attr.maxScore;
      sum += attr.currScore;

      dataSum['attr'].curr += attr.currScore;
      dataSum['attr'].max += attr.maxScore;

      if (attr.currScore === 0 || attr.currScore < (attr.maxScore / 2)) {
        reports.push({
          type: 'Inventário',
          topic: attr.title,
          score: attr.score,
          body: handleBodyReport(attr.scoreType, attr.pointType, attr.maxScore),
        });
      }
    });

    dataRankSum.attr.curr = dataSum['attr'].curr;
    dataRankSum.attr.max = dataSum['attr'].max;
    setAttracScore(attrData);

    cityData.map((attr) => {
      max += attr.maxScore;
      sum += attr.currScore;
      dataSum['city'].curr += attr.currScore;
      dataSum['city'].max += attr.maxScore;

      if (attr.currScore === 0 || attr.currScore < (attr.maxScore / 2)) {
        reports.push({
          type: 'Cidade',
          topic: attr.title,
          score: attr.score,
          body: handleBodyReport(attr.scoreType, attr.pointType, attr.maxScore),
        });
      }
    });

    dataRankSum.attr.curr = dataSum['city'].curr;
    dataRankSum.attr.max = dataSum['city'].max;
    setCityScore(cityData);

    demandData.map((attr) => {
      sum += attr.currScore;
      max += attr.maxScore;
      dataSum['demand'].curr += attr.currScore;
      dataSum['demand'].max += attr.maxScore;

      if (attr.currScore === 0 || attr.currScore < (attr.maxScore / 2)) {
        reports.push({
          type: 'Estudo de Demanda',
          topic: attr.title,
          score: attr.score,
          body: handleBodyReport(attr.scoreType, attr.pointType, attr.maxScore),
        });
      }
    });

    dataRankSum.attr.curr = dataSum['demand'].curr;
    dataRankSum.attr.max = dataSum['demand'].max;
    setDemandScore(demandData);

    comturData.map((attr) => {
      sum += attr.currScore;
      max += attr.maxScore;
      dataSum['comtur'].curr += attr.currScore;
      dataSum['comtur'].max += attr.maxScore;

      if (attr.currScore === 0 || attr.currScore < (attr.maxScore / 2)) {
        reports.push({
          type: 'COMTUR',
          topic: attr.title,
          score: attr.score,
          body: handleBodyReport(attr.scoreType, attr.pointType, attr.maxScore),
        });
      }
    });

    dataRankSum.attr.curr = dataSum['comtur'].curr;
    dataRankSum.attr.max = dataSum['comtur'].max;
    setComturScore(comturData);

    // City's Points
    setDataSum(dataSum);
    setTotalScore(sum);
    setReportData(reports);
    setMaxScore(max);
  }

  function handleBodyReport(scoreType, pointType, score) {
    const text = [];

    if (scoreType) {
      for (let index = 1; index < scoreType.length; index++) {
        if (pointType[index] === pointType[index + 1]) {
          text.push(`De ${scoreType[index]} a ${scoreType[index + 1]} - ${pointType[index]} ${pointType[index] === 1 ? 'ponto' : 'pontos'}.`);
          index++;
        } else {
          text.push(`Exatamente ${scoreType[index]} - ${pointType[index]} ${pointType[index] === 1 ? 'ponto' : 'pontos'}.`);
        }
      }
      text.push(`Acima de ${scoreType[scoreType.length - 1]} - ${pointType[pointType.length - 1]} ${pointType[pointType.length - 1] === 1 ? 'ponto' : 'pontos'}.`);
    } else {
      text.push(`Possuir pelo menos 1 - ${score}  ${score === 1 ? 'ponto' : 'pontos'}.`);
    }

    return text;
  }

  function handleSizeCardReport() {
    const isOpen = document.getElementById('list_cards_reports').clientHeight !== 0;
    const heigthUl = document.getElementById('ul_cards_reports').clientHeight;

    if (isOpen)
      document.getElementById('list_cards_reports').style = 'height: 0px;';
    else
      document.getElementById('list_cards_reports').style = `height: ${heigthUl}px;`;
  }

  async function handleDeleteCards() {
    const doc = await firebase.firestore().collection('DeleteCards').where('city', '==', citySelected).get();

    if (doc.docs && doc.docs.length > 0) {
      setDeleteCards(doc.docs[0].data().cardsDelete);
    } else {
      await firebase.firestore().collection('DeleteCards').add({
        city: citySelected,
        cardsDelete: [],
      });
      setDeleteCards([]);
    }
  }

  async function handlePontuationSetur() {
    const docs = await firebase.firestore().collection('Cities').where('name', '==', citySelected).get();

    setPointSetur(docs.docs[0].data().PontosSetur);
  }

  async function handleCheckCityRank() {
    const docCity = await firebase.firestore().collection('Cities').where('name', '==', citySelected).get();

    const dataCity = docCity.docs[0].data();
    const idCity = docCity.docs[0].id;

    await handleInfo(dataCity, idCity);
  }

  function handleInitStats() {
    setAttracScore();
    setCityScore();
    setDemandScore();
    setComturScore();
    setAttracScore();
    setTotalScore();
    setReportData();
    setMaxScore();
    setPointSetur();
    setDeleteCards([]);
    setDataSum({
      attr: {
        curr: undefined,
        max: undefined,
      },
      city: {
        curr: undefined,
        max: undefined,
      },
      demand: {
        curr: undefined,
        max: undefined,
      },
      comtur: {
        curr: undefined,
        max: undefined,
      },
    });

    handleCheckCityRank();
  }

  useEffect(() => {
    if (!cityManager)
      setCitySelected(city);
  }, [city]);

  useEffect(() => {
    if (citySelected)
      handleInitStats();
    handleDeleteCards();
    handlePontuationSetur();
  }, [citySelected]);

  if (!citySelected)
    return <Loading />;

  return (
    <div className='RankingCalculator'>
      {
        cityManager ? (
          <div>
            <button onClick={() => setRankingPopup(false)} className='btn_goback_ranking_cities'>
              <img src={GoBackIcon} alt='Icone indicando para voltar a pagina anterior' className='img_btn_goback_ranking_cities'/>
              <span>Voltar</span>
            </button>
          </div>
        ) : null
      }
      <TitleEditor type={'Calculadora do Ranqueamento - ' + citySelected} subTitle={'Análise de diagnóstico da competitividade turistica do seu município'} />
      <div className='div_cards_pontuation'>
        <div className="div_card_curr_pontuation div_card_max_pontuation">
          <div className="div_align_card_max">
            <div className="div_img_card_max">
              <img src={AwardIcon} />
            </div>
            <div className="div_info_card_max">
              <span>PONTUAÇÃO TOTAL</span>
              <h2 className='title_score_calculator'>{totalScore === undefined ? <SpinButton /> : totalScore }</h2>
            </div>
          </div>
        </div>
        {
          pointSetur ? (
            <div className="div_card_setur_pontuation div_card_max_pontuation">
              <div className="div_align_card_max">
                <div className="div_img_card_max">
                  <img src={SeturPontuationIcon} />
                </div>
                <div className="div_info_card_max">
                  <span>PONTUAÇÃO SETUR</span>
                  <h2 className='title_score_calculator'>{pointSetur || <SpinButton />}</h2>
                </div>
              </div>
            </div>
          ) : null
        }
        <div className="div_card_max_pontuation">
          <div className="div_align_card_max">
            <div className="div_img_card_max">
              <img src={MaxPontuationIcon} />
            </div>
            <div className="div_info_card_max">
              <span>PONTUAÇÃO MÁXIMA</span>
              <h2 className='title_score_calculator'>{maxScore || <SpinButton />}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className='div_align_cards_score'>
        <CardScoreCalculator 
          title={'Pontuação Inventário'}
          data={attracScore}
          dataRankSum={dataRankSum['attr']}
        />
        <CardScoreCalculator
          title={'Pontuação Cidade'}
          data={cityScore}
          dataRankSum={dataRankSum['city']}
        />
        <CardScoreCalculator
          title={'Estudo de Demanda'}
          data={demandScore}
          dataRankSum={dataRankSum['demand']}
        />
        <CardScoreCalculator
          title={'COMTUR'}
          data={comturScore}
          dataRankSum={dataRankSum['comtur']}
        /> 
      </div>
      <div className='div_align_cards_reports'>
        <div className="div_title_cards_reports">                    
          <h5>Oportunidades de Melhorias</h5>
          <div className="div_handle_size_cards_reports">
            <button type='button' className='btn_handle_size_calculator' onClick={() => handleSizeCardReport()}><img src={HandleSize} alt='Icone de uma seta para baixo' className='img_handle_size_calculator'/></button>
          </div>
        </div>
        <div id='list_cards_reports'>
          <div className='div_align_show_delete_cards'>
            <label className='label_check_pendencies label_show_delete_cards'>
              <input id='input_show_delete_cards' type='checkbox' onClick={() => setShowDeleteCards(!showDeleteCards)}/>
              <span className='checkmark_pendy checkmark_cards_delete'></span>
            </label>
            <label className='label_text_delete_cards' htmlFor='input_show_delete_cards'>Exibir desabilitados</label>
          </div>
          <ul className='ul_cards_reports' id='ul_cards_reports'>
            {
              reportData && reportData.length > 0 ? reportData.map((report, index) => {
                if (!deleteCards.includes(report.topic) || showDeleteCards)
                  return <CardReportCalculator type={report.type} styleType={index % 2 ? 'cardReportLeft' : 'cardReportRight'} topic={report.topic} body={report.body} score={report.score} deleteCards={deleteCards} setDeleteCards={setDeleteCards} city={citySelected} isDeleted={deleteCards.includes(report.topic)} key={index} />;
              }) : <p>Nenhuma sugestão encontrada.</p>
            }
          </ul>
        </div>
      </div>
    </div>
  );
}