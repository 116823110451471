import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  Button, Row,
} from 'reactstrap';
import { 
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { Modal } from '@material-ui/core';

class ShareModal extends React.Component {
  render() {
    const { title } = this.props;
    const link = window.location.host + '/map/' + this.props.collection + '/' + this.props.document;
    const message = title + ' - Esse destino é a sua cara!';
    return (
      <Modal open={this.props.open}>
        <div className={css(styles.container)}>
          <div className={css(styles.modalBody)}>
            <h5>Compartilhar com:</h5>
            <Row>
              <EmailShareButton className={css(styles.shareButtonsModal)} url={link} body={`${message} ${link}`}>
                <EmailIcon size={48} />
              </EmailShareButton>
              <FacebookShareButton className={css(styles.shareButtonsModal)} url={link} quote={message}>
                <FacebookIcon size={48} />
              </FacebookShareButton>
              <TwitterShareButton className={css(styles.shareButtonsModal)} url={link} title={`${message} ${link}`}>
                <TwitterIcon size={48} />
              </TwitterShareButton>
              <WhatsappShareButton className={css(styles.shareButtonsModal)} url={link} title={message} separator=" ">
                <WhatsappIcon size={48} />
              </WhatsappShareButton>
            </Row>
            <Button className={css(styles.cancelButton)} onClick={this.props.closeModal}>Cancelar</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButton: {
    marginTop: '10px',
  },
  modalBody: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    width: '300px',
  },
  shareButtonsModal: {
    marginLeft: 4,
    marginRight: 4,
    cursor: 'pointer',
  },
});

export default ShareModal;
